import * as React from "react"
import styles from "./UserQrModalContent.module.scss"

import { QRCode } from "antd"
import { useTranslation } from "react-i18next"
import { AppNotification } from "@others/AppNotification.ts"

import AppText from "@components/general/AppText/AppText.tsx"
import userService from "@services/userService/userService.ts"

const initialQrCode = "initialQrCodeForLoadingState"

interface IProps {
	className?: string
}

const UserQrModalContent: React.FC<IProps> = (props) => {
	const [userQr, setUserQr] = React.useState(initialQrCode)
	const { t } = useTranslation()

	React.useEffect(() => {
		userService
			.getUserQrCode()
			.then(({ qr_code }) => {
				if (qr_code) setUserQr(qr_code)
			})
			.catch(() => {
				AppNotification.error({ message: t("something_went_wrong") })
			})
	}, [])

	return (
		<div
			className={`${styles.user_qr_modal_content} ${props.className || ""}`}
		>
			<QRCode
				className={`${styles.qr_code} ${userQr === initialQrCode ? styles.loading : ""}`}
				value={userQr}
				size={200}
			/>

			<AppText
				className={styles.text}
				textKey={
					userQr === initialQrCode ? "generating" : "access_code"
				}
			/>
		</div>
	)
}

export default React.memo(UserQrModalContent)
