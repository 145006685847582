import * as React from "react"
import * as Antd from "antd"
import styles from "./DvAppDropdown.module.scss"

export interface IDvAppDropdownProps extends Antd.DropdownProps {}

const DvAppDropdown: React.FC<IDvAppDropdownProps> = ({
	overlayClassName,
	menu,
	...props
}) => {
	const items = menu?.items?.map((item: any) => {
		return {
			...item,
			className: `${styles.drop_down_item} ${item?.className || ""} ${item.active ? styles.active : ""}`,
		}
	})

	return (
		<Antd.Dropdown
			className={`${styles.dv_app_dropdown} ${props.className || ""}`}
			overlayClassName={`${overlayClassName} ${styles.overlay}`}
			trigger={["click"]}
			align={{
				points: ["tr", "br"],
			}}
			menu={{
				items,
			}}
			{...props}
		>
			{props.children}
		</Antd.Dropdown>
	)
}

export default React.memo(DvAppDropdown)
