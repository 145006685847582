import { IApiResponse } from "@interfaces/generic/IApiResponse.ts"
import { IGetSpaceAvailableDaysResponseApi } from "@interfaces/IGetSpaceAvailableDaysResponseApi.ts"
import { IGetSpaceAvailableDaysResponseCli } from "@interfaces/IGetSpaceAvailableDaysResponseCli.ts"

export const GetSpaceAvailableDaysAdapter = ({
	data,
}: IApiResponse<IGetSpaceAvailableDaysResponseApi>): IGetSpaceAvailableDaysResponseCli => {
	return {
		placeId: data.place_id,
		availableDays: data.available_days,
	}
}
