import * as React from "react"
import * as Antd from "antd"
import styles from "./BookingsItem.module.scss"

import { useTranslation } from "react-i18next"
import { InfoCircleOutlined } from "@ant-design/icons"

import AppText from "@components/general/AppText/AppText.tsx"
import DsButton from "@components/general/DsButton/DsButton.tsx"

interface IProps {
	className?: string
	textKey: string
	tooltipTextKey?: string
	buttonTextKey?: string
	onClick?: () => void
	hasBottomDivider?: boolean
	buttonDisabled?: boolean
	comingSoon?: boolean
}

const BookingsItem: React.FC<IProps> = (props) => {
	const { t } = useTranslation()

	return (
		<>
			<div className={`${styles.bookings_item} ${props.className || ""}`}>
				<AppText
					className={`${styles.text} ${props.comingSoon ? styles.coming_soon : ""}`}
					textKey={props.textKey}
					ellipsis
				/>

				{!props.comingSoon ? (
					<div className={styles.actions}>
						<DsButton
							className={styles.button}
							disabled={props.buttonDisabled}
							type="secondary"
							onClick={props.onClick}
						>
							{t(props.buttonTextKey || "make_a_reservation")}
						</DsButton>

						{props.tooltipTextKey && (
							<Antd.Tooltip
								placement="bottomRight"
								title={t(props?.tooltipTextKey || "")}
								open={!props.buttonDisabled ? false : undefined}
							>
								<InfoCircleOutlined className={styles.icon} />
							</Antd.Tooltip>
						)}
					</div>
				) : (
					<p className={styles.coming_soon_text}>
						{t("coming_soon")}
					</p>
				)}
			</div>

			{props.hasBottomDivider && <div className={styles.line} />}
		</>
	)
}

export default React.memo(BookingsItem)
