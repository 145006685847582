import dayjs from "dayjs"

import { AppFunctions } from "@others/AppFunctions.ts"
import { IApiResponse } from "@interfaces/generic/IApiResponse.ts"
import { IGetMyBookingsResponseApi } from "@interfaces/IGetMyBookingsResponseApi.ts"
import { IGetMyBookingsResponseCli } from "@interfaces/IGetMyBookingsResponseCli.ts"

export const getMyBookingsAdapter = (
	response: IApiResponse<IGetMyBookingsResponseApi>
): IGetMyBookingsResponseCli => {
	const adaptedBookings = response?.data?.map<IGetMyBookingsResponseCli[0]>(
		(booking) => ({
			...booking,
			endDate: booking.end_date,
			startDate: booking.start_date,
			qrCode: booking.qr_code,
			category: AppFunctions.getBookingCategoriesCli(booking?.category),
			status: AppFunctions.getBookingStatusCli(booking?.status),
		})
	)

	return adaptedBookings.sort((a, b) => {
		if (dayjs(a.startDate).isBefore(dayjs(b.startDate))) {
			return -1
		}
		return 1
	})
}
