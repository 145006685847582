import { generateAppEntity } from "@others/generateAppEntity.ts"

export const AppLocalStorageKeys = {
	TOKEN: generateAppEntity("token"),
	REFRESH_TOKEN: generateAppEntity("refreshToken"),
	REMEMBER_ME: generateAppEntity("rememberMe"),
	TOKEN_EXPIRATION_TIME: generateAppEntity("tokenExpirationTime"),
	PAYMENT_REF: generateAppEntity("paymentRef"),
	PAYMENT_GATEWAY: generateAppEntity("paymentGateway"),
	CHECKOUT: generateAppEntity("checkout"),
	PAY_PENDING_MESSAGE_SHOWN: generateAppEntity("payPendingMessageShown"),
	CHANGED_BOOKING: generateAppEntity("changedBooking"),

	EXTERNAL_MEMBERSHIP_APPLIES_FLOW_IN_PROGRESS: generateAppEntity(
		"externalMembershipAppliesFlowInProgress"
	),
}
