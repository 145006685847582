import * as React from "react"
import * as Antd from "antd"
import { InputNumberProps, InputProps } from "antd"
import styles from "./DvInput.module.scss"
import { PasswordProps, TextAreaProps } from "antd/es/input"
import { TextAreaRef } from "antd/es/input/TextArea"

const AntdInputTextArea = React.forwardRef<TextAreaRef, TextAreaProps>(
	(props, ref) => {
		return (
			<Antd.Input.TextArea
				{...props}
				className={`${styles.dv_input} ${props.className}`}
				ref={ref}
			/>
		)
	}
)

const AntdInputPassword = React.forwardRef<Antd.InputRef, PasswordProps>(
	(props, ref) => {
		return (
			<Antd.Input.Password
				{...props}
				className={`${styles.dv_input} ${props.className}`}
				ref={ref}
			/>
		)
	}
)

const AntdInputNumber = React.forwardRef<HTMLInputElement, InputNumberProps>(
	(props, ref) => {
		return (
			<Antd.InputNumber
				{...props}
				className={`${styles.dv_input} ${styles.dv_number} ${props.className}`}
				ref={ref}
			/>
		)
	}
)

const AntdInput = React.forwardRef<Antd.InputRef, InputProps>((props, ref) => {
	return (
		<Antd.Input
			{...props}
			className={`${styles.dv_input} ${props.className}`}
			ref={ref}
		/>
	)
})

interface IDvInput
	extends React.ForwardRefExoticComponent<
		Antd.InputProps & React.RefAttributes<Antd.InputRef>
	> {
	TextArea: React.ForwardRefExoticComponent<
		TextAreaProps & React.RefAttributes<TextAreaRef>
	>
	Password: React.ForwardRefExoticComponent<
		PasswordProps & React.RefAttributes<Antd.InputRef>
	>
	InputNumber: React.ForwardRefExoticComponent<
		InputNumberProps & React.RefAttributes<HTMLInputElement>
	>
}

const DvInput = AntdInput as unknown as IDvInput

DvInput.TextArea = AntdInputTextArea
DvInput.Password = AntdInputPassword
DvInput.InputNumber = AntdInputNumber

export default DvInput
