import * as React from "react"
import styles from "./MembershipLayout.module.scss"

import { Outlet, useLocation, useNavigate } from "react-router-dom"
import { AppRoutes } from "@constants/AppRoutes.ts"
import { useTranslation } from "react-i18next"
import { useMembershipApplyForm } from "@hooks/useMembershipApplyForm.tsx"
import { AppCorporateSubscriptions } from "@constants/AppCorporateSubscriptions.ts"
import { MemberShipLayoutConstants } from "@components/layout/MembershipLayout/MembershipLayout.constants.ts"

import DsButton from "@components/general/DsButton/DsButton.tsx"
import useResponsiveToolCtx from "@contexts/hooks/useResponsiveToolCtx.tsx"
import useMembershipLayoutCtx from "@contexts/hooks/useMembershipLayoutCtx.tsx"

const RoutesWithOutFooter = [AppRoutes.membershipPlans]

const RoutesWithOutBackButton = [
	AppRoutes.membershipSuccess,
	AppRoutes.membershipStartApplying,
	AppRoutes.membershipPayment,
]

const RoutesWithOutPadding = [AppRoutes.membershipPlans]

interface IProps {
	className?: string
}

const MembershipLayout: React.FC<IProps> = (props) => {
	const navigate = useNavigate()
	const location = useLocation()

	const { t } = useTranslation()
	const { nextButtonLoading } = useMembershipLayoutCtx()
	const { isMobile } = useResponsiveToolCtx()
	const { states } = useMembershipApplyForm(undefined)

	const isACorporateSubscription = React.useMemo(
		() => AppCorporateSubscriptions.includes(states?.subscription || ""),
		[states]
	)

	const currentPath = location.pathname

	const handlePrevious = () => {
		let previousPath = currentPath

		switch (currentPath) {
			case AppRoutes.membershipFormStep1_details:
				previousPath = AppRoutes.membershipStartApplying
				break
			case AppRoutes.membershipFormStep1_address:
				previousPath = AppRoutes.membershipFormStep1_details
				break
			case AppRoutes.membershipFormStep1_kind_of_membership:
				previousPath = AppRoutes.membershipFormStep1_address
				break
			case AppRoutes.membershipFormStep2_personalDetails:
				previousPath = AppRoutes.membershipFormStep1_kind_of_membership
				break
			case AppRoutes.membershipFormStep2_job:
				previousPath = AppRoutes.membershipFormStep2_personalDetails
				break
			case AppRoutes.membershipFormStep3_presentation:
				previousPath = AppRoutes.membershipFormStep2_job
				break
			case AppRoutes.membershipFormStep3_digital_profiles:
				previousPath = AppRoutes.membershipFormStep3_presentation
				break
			case AppRoutes.membershipFormStep3_references:
				previousPath = AppRoutes.membershipFormStep3_digital_profiles
				break
			case AppRoutes.membershipFormStep3_your_group:
				previousPath = AppRoutes.membershipFormStep3_references
				break
			case AppRoutes.membershipFormStep4_globoRules:
				previousPath = isACorporateSubscription
					? AppRoutes.membershipFormStep3_your_group
					: AppRoutes.membershipFormStep3_references
				break
			case AppRoutes.membershipFormStep5_sendMembership:
				previousPath = AppRoutes.membershipFormStep4_globoRules
				break
		}

		navigate(previousPath, { replace: true })
	}

	const handleNext = () => {
		let nextPath

		// Submit the form
		document
			.getElementById(MemberShipLayoutConstants.SUBMIT_FORM_BUTTON_ID)
			?.click()

		switch (currentPath) {
			case AppRoutes.membershipStartApplying:
				nextPath = AppRoutes.membershipFormStep1_details
				break
		}

		if (nextPath) navigate(nextPath, { replace: true })
	}

	let nextButtonText

	switch (currentPath) {
		case AppRoutes.membershipFormStep5_sendMembership:
			nextButtonText = t("send")
			break
		case AppRoutes.membershipSuccess:
			nextButtonText = t("back_to_site")
			break
		case AppRoutes.membershipStartApplying:
			nextButtonText = t("start_applying")
			break
		case AppRoutes.membershipPayment:
			nextButtonText = t("go_to_pay")
			break
		default:
			nextButtonText = t("continue")
	}

	const scrollableContainerPadding = RoutesWithOutPadding.includes(
		location.pathname
	)
		? 0
		: undefined

	return (
		<div className={`${styles.membership_layout} ${props.className || ""}`}>
			<div
				className={styles.scrollable_container}
				style={{ padding: scrollableContainerPadding }}
			>
				<Outlet />
			</div>

			{!RoutesWithOutFooter.includes(location.pathname) && isMobile && (
				<footer className={styles.footer}>
					{!RoutesWithOutBackButton.includes(location.pathname) && (
						<DsButton type="secondary" onClick={handlePrevious}>
							{t("back")}
						</DsButton>
					)}

					<DsButton onClick={handleNext} loading={nextButtonLoading}>
						{nextButtonText}
					</DsButton>
				</footer>
			)}
		</div>
	)
}

export default React.memo(MembershipLayout)
