import * as React from "react"
import * as Antd from "antd"
import styles from "./DvBookingTable.module.scss"

import { useAppDateTime } from "@hooks/useAppDateTime.ts"
import { useTranslation } from "react-i18next"
import { IGetMyBookingsResponseCli } from "@interfaces/IGetMyBookingsResponseCli.ts"

import dayjs from "dayjs"
import AppText from "@components/general/AppText/AppText.tsx"
import DvDateInfoTooltip from "@components/dataDisplay/DvDateInfoTooltip/DvDateInfoTooltip.tsx"

export interface IDvBookingTable {
	className?: string
	data: IGetMyBookingsResponseCli
}

const DvBookingTable: React.FC<IDvBookingTable> = (props) => {
	const { t } = useTranslation()
	const { checkIfIsToday, formatDate } = useAppDateTime()

	return (
		<div className={`${styles.dv_booking_table} ${props.className || ""}`}>
			{props?.data?.map(({ guests, endDate, startDate, title }, idx) => {
				const isToday = startDate
					? checkIfIsToday({ date: startDate })
					: false

				const diffInHours = dayjs(endDate).diff(
					dayjs(startDate),
					"hours"
				)

				const diffInMinutes =
					diffInHours === 0
						? dayjs(endDate).diff(dayjs(startDate), "minutes")
						: 0

				return (
					<Antd.Badge.Ribbon
						rootClassName={styles.booking_item}
						text={isToday ? t("today") : undefined}
						key={idx}
						style={{ display: !isToday ? "none" : undefined }}
					>
						<div className={styles.content}>
							<div className={styles.place}>
								<h3>
									{guests
										? `${guests} ${guests === 1 ? t("people") : t("peoples")}`
										: " "}
								</h3>

								<AppText className={styles.text} ellipsis>
									{title}
								</AppText>
							</div>

							<div className={styles.details}>
								<div className={styles.date_and_time}>
									<h1>
										{formatDate({
											date: startDate,
											format: "DD MMM",
										})}
									</h1>
									<h3>{dayjs(startDate).format("YYYY")}</h3>
								</div>

								<div className={styles.date_and_time}>
									<div className={styles.time_container}>
										<h1>
											{formatDate({
												date: startDate,
												format: "HH:mm",
											})}
										</h1>

										<DvDateInfoTooltip
											startDate={startDate}
										/>
									</div>

									<h3>
										(
										{diffInHours
											? `${diffInHours} ${diffInHours > 1 ? t("hours") : t("hour")}`
											: `${diffInMinutes} ${diffInMinutes > 1 ? t("minutes") : t("minute")}`}
										)
									</h3>
								</div>
							</div>
						</div>
					</Antd.Badge.Ribbon>
				)
			})}
		</div>
	)
}

export default React.memo(DvBookingTable)
