import { IApiResponse } from "@interfaces/generic/IApiResponse.ts"
import { IPostPayMembershipStatusResponseApi } from "@interfaces/IPostPayMembershipStatusResponseApi.ts"
import { IPostPayMembershipStatusResponseCli } from "@interfaces/IPostPayMembershipStatusResponseCli.ts"

export const GetPayMembershipStatusAdapter = (
	response: IApiResponse<IPostPayMembershipStatusResponseApi>
): IPostPayMembershipStatusResponseCli => {
	const { payment_status, payment_ref } = response.data

	return {
		paymentRef: payment_ref,
		paymentStatus: payment_status,
	}
}
