import { IApiResponse } from "@interfaces/generic/IApiResponse.ts"
import { IPostLoginResponseApi } from "@interfaces/IPostLoginResponseApi.ts"
import { IPostLoginResponseCli } from "@interfaces/IPostLoginResponseCli.ts"
import { AppFunctions } from "@others/AppFunctions.ts"

export const PostLoginAdapter = (
	response: IApiResponse<IPostLoginResponseApi>
): IPostLoginResponseCli => {
	return {
		...response.data,
		user: {
			...response.data.user,
			membershipStatus: AppFunctions.getMembershipStatusCli(
				response?.data?.user?.membershipStatus
			),
		},
	}
}
