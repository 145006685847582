import * as React from "react"
import * as Antd from "antd"
import styles from "./DsSwitch.module.scss"

export interface IDvSwitchProps
	extends Omit<Antd.RadioGroupProps, "optionType" | "onChange"> {
	className?: string
	onChange?: (value: string) => void
}

const DsSwitch: React.FC<IDvSwitchProps> = ({
	className,
	onChange,
	...defaultProps
}) => {
	return (
		<Antd.Radio.Group
			className={`${styles.ds_switch} ${className || ""}`}
			{...defaultProps}
			optionType="button"
			onChange={(e) => onChange?.(e.target.value)}
		/>
	)
}

export default React.memo(DsSwitch)
