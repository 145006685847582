import * as React from "react"
import bgImage from "@assets/svg/bg-1.svg"
import styles from "./DvBackground.module.scss"

interface IProps {
	className?: string
}

const DvBackground: React.FC<IProps> = (props) => {
	return (
		<div className={`${styles.dv_background} ${props.className || ""}`}>
			<img src={bgImage} alt={"bg-1"} className={styles.image} />
		</div>
	)
}

export default React.memo(DvBackground)
