import * as React from "react"
import * as Antd from "antd"
import styles from "./DsPlanInfoCard.module.scss"

interface IProps {
	className?: string
	selected?: boolean
	onRadioChange?: () => void
	onCardClick?: () => void
	name: string
	type?: string
	info: string
}

const DsPlanInfoCard: React.FC<IProps> = (props) => {
	const selectedClass = props.selected ? styles.selected : ""

	return (
		<div
			className={`${styles.ds_plan_info_card} ${props.className || ""} ${selectedClass}`}
			onClick={props.onCardClick}
		>
			<div className={styles.title_radio_button}>
				<Antd.Radio
					onChange={props.onRadioChange}
					checked={props.selected}
				/>

				<div className={styles.title}>
					<h1>{props.name}</h1>
					<span>{props?.type}</span>
				</div>
			</div>

			<div className={styles.info}>{props.info}</div>
		</div>
	)
}

export default React.memo(DsPlanInfoCard)
