import * as React from "react"
import styles from "./NoBookingsMessage.module.scss"

import { AppRoutes } from "@constants/AppRoutes.ts"
import { useNavigate } from "react-router-dom"
import { MinusCircleOutlined } from "@ant-design/icons"

import AppText from "@components/general/AppText/AppText.tsx"
import DsButton from "@components/general/DsButton/DsButton.tsx"

interface IProps {
	className?: string
}

const NoBookingsMessage: React.FC<IProps> = (props) => {
	const navigate = useNavigate()

	return (
		<div
			className={`${styles.no_bookings_message} ${props.className || ""}`}
		>
			<div className={styles.error_message}>
				<MinusCircleOutlined className={styles.minus_icon} />

				<div className={styles.content}>
					<div className={styles.message}>
						<AppText
							className={styles.title}
							textKey="no_bookings_to_show"
						/>

						<AppText
							className={styles.body}
							textKey="message_create_bookings_from_your_profile"
						/>
					</div>

					<div className={styles.footer}>
						<DsButton
							type="secondary"
							textKey="go_to_profile"
							onClick={() => navigate(AppRoutes.userProfile)}
						/>
					</div>
				</div>
			</div>
		</div>
	)
}

export default React.memo(NoBookingsMessage)
