import * as React from "react"
import styles from "./NotFound.module.scss"

import { AppRoutes } from "@constants/AppRoutes.ts"
import { useNavigate } from "react-router-dom"
import { ReactComponent as Text404Mobile } from "@assets/svg/404-211x123.svg"
import { ReactComponent as Text404Desktop } from "@assets/svg/404-364x212.svg"

import AppText from "@components/general/AppText/AppText.tsx"
import DsButton from "@components/general/DsButton/DsButton.tsx"
import DvBackground from "@components/layout/DvBackground/DvBackground.tsx"
import useResponsiveToolCtx from "@contexts/hooks/useResponsiveToolCtx.tsx"

interface INotFoundProps {
	className?: string
}

const NotFound: React.FC<INotFoundProps> = (props) => {
	const navigate = useNavigate()
	const { isDesktop } = useResponsiveToolCtx()

	const goBack = () => {
		const hasPreviousPageToGo = window.history.length > 2

		if (hasPreviousPageToGo) {
			navigate(-1)
		} else {
			navigate(AppRoutes.home)
		}
	}

	const goToProfile = () => {
		navigate(AppRoutes.userProfile)
	}

	return (
		<div className={`${styles.not_found} ${props.className || ""}`}>
			<DvBackground />

			<div className={styles.dv_error_message_container}>
				<div className={styles.body}>
					{isDesktop ? <Text404Desktop /> : <Text404Mobile />}

					<div className={styles.content}>
						<div className={styles.message}>
							<AppText
								className={styles.title}
								textKey="page_not_found"
								tag="h1"
							/>
							<main className={styles.error_body}>
								<AppText
									className={styles.error_details}
									textKey="message_page_not_found"
									tag="p"
								/>
								<AppText
									className={styles.instructions}
									textKey="message_do_click_in_the_buttons_below"
									tag="p"
								/>
							</main>
						</div>

						<div className={styles.footer}>
							<DsButton
								type="secondary"
								textKey="back"
								onClick={goBack}
							/>
							<DsButton
								type="primary"
								textKey="go_to_profile"
								onClick={goToProfile}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default React.memo(NotFound)
