import * as React from "react"
import * as Antd from "antd"
import styles from "./MembershipPayment.module.scss"

import { AppRoutes } from "@constants/AppRoutes.ts"
import { usePlanData } from "@hooks/usePlanData.tsx"
import { useNavigate } from "react-router-dom"
import { AppFunctions } from "@others/AppFunctions.ts"
import { useTranslation } from "react-i18next"
import { AppNotification } from "@others/AppNotification.ts"
import { useLoggedUserData } from "@hooks/useLoggedUserData.ts"
import { useMembershipPayment } from "@hooks/useMembershipPayment.tsx"
import { AppCorporateSubscriptions } from "@constants/AppCorporateSubscriptions.ts"
import { usePayMembershipSupportedCountriesData } from "@hooks/usePayMembershipSupportedCountriesData.tsx"

import AppText from "@components/general/AppText/AppText.tsx"
import DvSelect from "@components/dataEntry/DvSelect/DvSelect.tsx"
import DvAntdForm from "@components/others/DvAntdForm/DvAntdForm.tsx"
import DvPlanChose from "@pages/MembershipPayment/DvPlanChose/DvPlanChose.tsx"
import DvAntdFormItem from "@components/others/DvAntdFormItem/DvAntdFormItem.tsx"
import PlanDetailItem from "@components/dataEntry/MembershipKindOfForm/PlanDetailItem/PlanDetailItem.tsx"
import validationRules from "@others/validationRules.ts"
import DvConfirmationModal from "@components/feedback/DvConfirmationModal/DvConfirmationModal.tsx"
import MembershipFormFooter from "@components/layout/MembershipFormFooter/MembershipFormFooter.tsx"
import RedirectConfirmationMessage from "@pages/MembershipPayment/RedirectConfirmationMessage/RedirectConfirmationMessage.tsx"

interface IMembershipPaymentFormFieldType {
	countryCode?: string
}

interface IProps {
	className?: string
}

const MembershipPayment: React.FC<IProps> = (props) => {
	const navigate = useNavigate()
	const membershipPayment = useMembershipPayment()
	const countryCodeRef = React.useRef<string | undefined>()

	const { t } = useTranslation()
	const [form] = Antd.Form.useForm<IMembershipPaymentFormFieldType>()
	const { loggedUser } = useLoggedUserData({ fetchOnMount: true })

	const { planData, loading: planDataLoading } = usePlanData({
		fetchOnMount: true,
	})

	const { supportedCountries, supportedCountriesLoading } =
		usePayMembershipSupportedCountriesData()

	const [
		externalPaymentConfirmationModalOpen,
		setExternalPaymentConfirmationModalOpen,
	] = React.useState(false)

	const initialValues: IMembershipPaymentFormFieldType = React.useMemo(
		() => ({
			countryCode: supportedCountries?.find(
				({ value }) => loggedUser?.countryCode === value
			)?.value,
		}),
		[loggedUser, supportedCountries]
	)

	const onFinish = async ({
		countryCode,
	}: IMembershipPaymentFormFieldType) => {
		countryCodeRef.current = countryCode
		setExternalPaymentConfirmationModalOpen(true)
	}

	const selectedPlan = React.useMemo(
		() => planData?.plans.find(({ id }) => id === loggedUser?.plan),
		[planData, loggedUser]
	)

	const selectedPlanSubscription = React.useMemo(
		() =>
			selectedPlan?.subscriptions.find(
				({ id }) => id === loggedUser?.subscription
			),
		[planData, loggedUser]
	)

	const selectedPlanBillingCycle = React.useMemo(
		() =>
			selectedPlanSubscription?.billingCycle.find(
				({ id }) => id === loggedUser?.paymentFrequency
			),
		[planData, loggedUser]
	)

	const isACorporateSubscription = React.useMemo(
		() =>
			AppCorporateSubscriptions.includes(loggedUser?.subscription || ""),
		[loggedUser]
	)

	const amountOfMembers = React.useMemo(
		() =>
			isACorporateSubscription ? loggedUser?.members?.length : undefined,
		[loggedUser]
	)

	React.useLayoutEffect(() => {
		if (loggedUser?.membershipStatus === "PAID") {
			navigate(AppRoutes.notFound, { replace: true })
		}

		if (loggedUser && !planDataLoading && !supportedCountriesLoading) {
			let dataError: boolean = false

			if (!selectedPlan) {
				dataError = true
				console.error(
					`We couldn't find the the selectedPlan of the plan=${loggedUser?.plan} in planData`
				)
			}

			if (!selectedPlanSubscription) {
				dataError = true
				console.error(
					`We couldn't find the the selectedPlanSubscription of the subscription=${loggedUser?.subscription} in planData`
				)
			}

			if (!selectedPlanBillingCycle) {
				dataError = true
				console.error(
					`We couldn't find the the selectedPlanBillingCycle of the planBillingCycle=${loggedUser?.paymentFrequency} in planData`
				)
			}

			if (!supportedCountries?.length) {
				dataError = true
				console.error(
					`We couldn't find the supportedCountries=${supportedCountries}`
				)
			}

			if (dataError) {
				AppNotification.error({
					message: t("something_went_wrong"),
				})

				navigate(AppRoutes.userProfile, { replace: true })
			}
		}
	}, [
		planData,
		planDataLoading,
		loggedUser,
		supportedCountries,
		supportedCountriesLoading,
	])

	React.useEffect(() => {
		if (!membershipPayment.paymentLoading) {
			setExternalPaymentConfirmationModalOpen(false)
		}
	}, [membershipPayment.paymentLoading])

	React.useEffect(() => {
		const isReturningFromCheckout =
			AppFunctions.getUrlParam("checkout") === "back"

		if (isReturningFromCheckout) {
			AppFunctions.clearPaymentDataFromLocalStorage()
		}
	}, [])

	return (
		<>
			<div
				className={`${styles.membership_payment} ${props.className || ""}`}
			>
				<h1 className={styles.title}>{t("membership_payment")}</h1>

				<div className={styles.form}>
					{!planDataLoading && !supportedCountriesLoading ? (
						<>
							<div className={styles.top}>
								<AppText
									className={styles.header}
									tag="h3"
									textKey="the_plan_you_chose"
									suffix=":"
								/>

								<DvPlanChose
									className={styles.plan_chose}
									planBillingCycleCurrency={
										selectedPlanBillingCycle?.currency
									}
									planBillingCycleName={
										selectedPlanBillingCycle?.name
									}
									planSubscriptionDescription={
										selectedPlanSubscription?.description
									}
									planSubscriptionPromotion={
										selectedPlanSubscription?.promotion
									}
									planBillingCyclePrice={
										selectedPlanBillingCycle?.price
									}
									planBillingCyclePriceDescription={
										selectedPlanBillingCycle?.priceDescription
									}
									planSubscriptionName={
										selectedPlanSubscription?.name
									}
								/>
							</div>

							{selectedPlanBillingCycle && (
								<div className={styles.payment_table}>
									<AppText
										className={styles.header}
										tag="h1"
										suffix=":"
									>
										{t(
											isACorporateSubscription
												? "summary_of_your_purchase_for_X_members"
												: "summary_of_your_purchase"
										).replace(
											"(XXXX)",
											`${amountOfMembers}`
										)}
									</AppText>

									<div className={styles.payment_details}>
										{selectedPlanBillingCycle.firstPayment && (
											<PlanDetailItem
												title={t("payment_1")}
												subtitle={t(
													"monthly_plus_access_fee"
												)}
												currency="US$"
												value={
													isACorporateSubscription
														? selectedPlanBillingCycle.firstPayment *
															amountOfMembers!
														: selectedPlanBillingCycle.firstPayment
												}
											/>
										)}

										{selectedPlanBillingCycle.remainingPayments && (
											<PlanDetailItem
												title={t("payment_2_12")}
												currency="US$"
												value={
													isACorporateSubscription
														? selectedPlanBillingCycle.remainingPayments *
															amountOfMembers!
														: selectedPlanBillingCycle.remainingPayments
												}
												unit={t("ea")}
												strongBorder
											/>
										)}

										{selectedPlanBillingCycle.annuity && (
											<PlanDetailItem
												title={t("annuity")}
												currency="US$"
												value={
													isACorporateSubscription
														? selectedPlanBillingCycle.annuity *
															amountOfMembers!
														: selectedPlanBillingCycle.annuity
												}
											/>
										)}

										{selectedPlanBillingCycle.accessFee && (
											<PlanDetailItem
												title={t("access_fee")}
												currency="US$"
												value={
													isACorporateSubscription
														? selectedPlanBillingCycle.accessFee *
															amountOfMembers!
														: selectedPlanBillingCycle.accessFee
												}
												strongBorder
											/>
										)}

										{selectedPlanBillingCycle.total && (
											<PlanDetailItem
												title={t("current_payment")}
												currency="US$"
												value={
													(selectedPlanBillingCycle.firstPayment ||
														selectedPlanBillingCycle.total) *
													(isACorporateSubscription
														? amountOfMembers!
														: 1)
												}
												strongText
											/>
										)}
									</div>
								</div>
							)}
							<div className={styles.country_selection}>
								<h1 className={styles.text}>
									{t(
										"message_select_country_of_payment_method"
									)}
									:
								</h1>

								<DvAntdForm<IMembershipPaymentFormFieldType>
									form={form}
									onFinish={onFinish}
									initialValues={initialValues}
									className={styles.country_form}
								>
									<DvAntdFormItem<IMembershipPaymentFormFieldType>
										label={t("country")}
										name="countryCode"
										rules={[
											validationRules.requiredFieldRule(
												t(
													"please_enter_your_country_region"
												)
											),
										]}
									>
										<DvSelect
											options={supportedCountries}
										/>
									</DvAntdFormItem>
								</DvAntdForm>
							</div>
							<MembershipFormFooter
								backButtonHidden
								nextButtonTest={t("go_to_pay")}
								onNextClick={() => form.submit()}
							/>
						</>
					) : (
						<Antd.Spin />
					)}
				</div>
			</div>

			<DvConfirmationModal
				open={externalPaymentConfirmationModalOpen}
				width={342}
				loading={membershipPayment.paymentLoading}
				onCancel={() => {
					setExternalPaymentConfirmationModalOpen(false)
				}}
				onOk={() => {
					membershipPayment.pay({
						country_code: countryCodeRef.current!,
					})
				}}
			>
				<RedirectConfirmationMessage />
			</DvConfirmationModal>
		</>
	)
}

export default React.memo(MembershipPayment)
