import * as React from "react"
import styles from "./UserSettings.module.scss"

import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { AppRoutes } from "@constants/AppRoutes.ts"

import UserInfo from "@pages/UserProfile/UserInfo/UserInfo.tsx"
import DsButton from "@components/general/DsButton/DsButton.tsx"
import MenuItem from "@pages/UserSettings/MenuItem/MenuItem.tsx"
import useAuthCtx from "@contexts/hooks/useAuthCtx.tsx"
import bgImage from "@assets/svg/group-12.svg"

interface IProps {
	className?: string
}

const UserSettings: React.FC<IProps> = (props) => {
	const navigate = useNavigate()

	const { t } = useTranslation()
	const { loggedUser } = useAuthCtx()

	return (
		<div
			className={`${styles.user_settings} ${props.className || ""}`}
			style={{
				backgroundImage: `url(${bgImage})`,
				backgroundRepeat: "no-repeat",
				backgroundPosition: "right bottom",
				backgroundSize: "cover",
			}}
		>
			<div className={styles.content}>
				<UserInfo className={styles.user_info} titleCentered />

				<div className={styles.menu}>
					<MenuItem
						label={t("personal_data")}
						onClick={() => {
							navigate(AppRoutes.userPersonalDataForm)
						}}
					/>

					{loggedUser?.membershipStatus === "NO_MEMBERSHIP" && (
						<MenuItem
							label="Membresía"
							icon={
								<DsButton
									className={styles.button}
									type="secondary"
									onClick={() =>
										navigate(AppRoutes.membershipPlans)
									}
								>
									{t("apply_to_a_membership")}
								</DsButton>
							}
						/>
					)}
				</div>
			</div>
		</div>
	)
}

export default React.memo(UserSettings)
