import { IApiResponse } from "@interfaces/generic/IApiResponse.ts"
import { IPostBookSpaceResponseApi } from "@interfaces/IPostBookSpaceResponseApi.ts"
import { IPostBookSpaceResponseCli } from "@interfaces/IPostBookSpaceResponseCli.ts"

export const PostBookSpaceAdapter = (
	response: IApiResponse<IPostBookSpaceResponseApi>
): IPostBookSpaceResponseCli => {
	const booking = response?.data?.booking
	return {
		booking: {
			...booking,
			qrCode: booking?.qr_code,
			endDate: booking?.end_date,
			startDate: booking?.start_date,
		},
	}
}
