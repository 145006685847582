import * as React from "react"
import * as AntdIcons from "@ant-design/icons"
import styles from "./DsNavbar.module.scss"

import { useTranslation } from "react-i18next"
import { useLocation, useNavigate } from "react-router-dom"
import { AppRoutes } from "@constants/AppRoutes.ts"

import useResponsiveToolCtx from "@contexts/hooks/useResponsiveToolCtx.tsx"
import useAuthCtx from "@contexts/hooks/useAuthCtx.tsx"
import DsButton from "@components/general/DsButton/DsButton.tsx"
import DvHamburgerButton from "@components/general/DvHamburgerButton/DvHamburgerButton.tsx"
import DvNavMenu from "@components/navigation/DvNavMenu/DvNavMenu.tsx"
import DsProfileDropDown from "@components/navigation/DsProfileDropDown/DsProfileDropDown.tsx"
import DvUserAvatar from "@components/dataDisplay/DvUserAvatar/DvUserAvatar.tsx"
import DsMenuDropDown from "@components/navigation/DsMenuDropDown/DsMenuDropDown.tsx"
import DsLogoBlancoElGlobo from "@components/others/DsLogoBlancoElGlobo/DsLogoBlancoElGlobo.tsx"

interface IProps {
	className?: string
}

const DsNavbar: React.FC<IProps> = (props) => {
	const navigate = useNavigate()
	const location = useLocation()
	const { t } = useTranslation()
	const { isDesktop, isMobile } = useResponsiveToolCtx()
	const { isLogged } = useAuthCtx()
	const [isNavMenuOpen, setIsNavMenuOpen] = React.useState(false)

	const isLoginPage = location.pathname === AppRoutes.login

	return (
		<header className={`${styles.ds_navbar} ${props.className || ""} `}>
			<DsLogoBlancoElGlobo
				type="horizontal"
				height={isMobile ? 34 : undefined}
				width={isMobile ? 138 : undefined}
			/>

			<div className={styles.action_container}>
				{isDesktop && <DvNavMenu />}

				{!isLogged && !isLoginPage && (
					<DsButton
						className={styles.login_button}
						icon={<AntdIcons.UserOutlined />}
						onClick={() =>
							navigate(AppRoutes.login, { replace: true })
						}
					>
						{t("login")}
					</DsButton>
				)}

				{isMobile && (
					<DsMenuDropDown onOpenChange={setIsNavMenuOpen}>
						<DvHamburgerButton isOpened={isNavMenuOpen} />
					</DsMenuDropDown>
				)}

				{isLogged && (
					<AntdIcons.BellOutlined
						className={styles.notification_icon}
					/>
				)}

				{isLogged && (
					<DsProfileDropDown>
						<DvUserAvatar className={styles.user_avatar} />
					</DsProfileDropDown>
				)}
			</div>
		</header>
	)
}

export default React.memo(DsNavbar)
