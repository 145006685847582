import * as React from "react"
import styles from "./BookLayout.module.scss"

import { ArrowLeftOutlined } from "@ant-design/icons"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { AppRoutes } from "@constants/AppRoutes.ts"

import DsButton from "@components/general/DsButton/DsButton.tsx"
import useResponsiveToolCtx from "@contexts/hooks/useResponsiveToolCtx.tsx"

interface IProps {
	className?: string
	children?: React.ReactNode
}

const BookLayout: React.FC<IProps> = (props) => {
	const navigate = useNavigate()

	const { t } = useTranslation()
	const { isDesktop } = useResponsiveToolCtx()

	return (
		<div className={`${styles.book_layout} ${props.className || ""}`}>
			<main
				className={styles.main}
				style={{ borderRadius: isDesktop ? 4 : 0 }}
			>
				<div className={styles.book}>
					<header className={styles.header}>
						<DsButton
							className={styles.back_button}
							type="text"
							icon={<ArrowLeftOutlined className={styles.icon} />}
							onClick={() => navigate(AppRoutes.userProfile)}
						/>

						<p className={styles.title}>{t("book_space")}</p>
					</header>

					{props.children}
				</div>
			</main>
		</div>
	)
}

export default React.memo(BookLayout)
