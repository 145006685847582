import * as React from "react"

import { AppFunctions } from "@others/AppFunctions.ts"
import { AppRoutes } from "@constants/AppRoutes.ts"
import { useTranslation } from "react-i18next"
import { AppNotification } from "@others/AppNotification.ts"
import { AppLocalStorageKeys } from "@constants/AppLocalStorageKeys.ts"
import { useLoggedUserData } from "@hooks/useLoggedUserData.ts"

import membershipService from "@services/membershipService.ts"

interface IOutput {
	pay: ({ country_code }: { country_code: string }) => void
	getPaymentStatus: () => void
	paymentLoading: boolean
}

export const useMembershipPayment = (): IOutput => {
	const message1OpenRef = React.useRef(false)

	const { t } = useTranslation()

	const { setPaidMembership, setInProgressPaymentMembership } =
		useLoggedUserData()

	const [paymentLoading, setPaymentLoading] = React.useState(false)

	const pay: IOutput["pay"] = ({ country_code }) => {
		setPaymentLoading(true)

		const back_url =
			AppFunctions.getCurrentDomain() +
			AppRoutes.membershipPayment +
			"?checkout=back"

		const success_url =
			AppFunctions.getCurrentDomain() +
			AppRoutes.userProfile +
			"?checkout=completed"

		membershipService
			.pay({
				back_url,
				success_url,
				country_code,
			})
			.then(({ checkoutUrl, paymentRef, paymentGateway }) => {
				let paymentError: boolean = false

				if (!checkoutUrl) {
					paymentError = true
					console.error(
						`The checkoutUrl=${checkoutUrl} is not a valid url`
					)
				}

				if (!paymentRef) {
					paymentError = true
					console.error(
						`The paymentRef=${paymentRef} is not a valid value`
					)
				}

				if (!paymentGateway) {
					paymentError = true
					console.error(
						`The paymentGateway=${paymentGateway} is not a valid value`
					)
				}

				if (paymentError) {
					AppNotification.error({
						message: t("something_went_wrong"),
					})
				} else {
					// Stored to get payment status the next time the user go to the profile page
					localStorage.setItem(
						AppLocalStorageKeys.PAYMENT_REF,
						paymentRef!
					)

					// Stored to get payment status the next time the user go to the profile page
					localStorage.setItem(
						AppLocalStorageKeys.PAYMENT_GATEWAY,
						paymentGateway!
					)

					window.location.replace(checkoutUrl!)
				}
			})
			.catch(({ message }) => {
				AppNotification.error({
					message: message || t("something_went_wrong"),
				})
			})
			.finally(() => {
				setPaymentLoading(false)
			})
	}

	const getPaymentStatus = () => {
		const isReturningFromACheckoutCompleted =
			AppFunctions.getUrlParam("checkout") === "completed"

		if (isReturningFromACheckoutCompleted) {
			localStorage.setItem(AppLocalStorageKeys.CHECKOUT, "completed")
		}

		const hasCheckoutCompleted =
			localStorage.getItem(AppLocalStorageKeys.CHECKOUT) === "completed"

		const payment_ref = localStorage.getItem(
			AppLocalStorageKeys.PAYMENT_REF
		)

		const payment_gateway = localStorage.getItem(
			AppLocalStorageKeys.PAYMENT_GATEWAY
		)

		if (hasCheckoutCompleted && payment_ref && payment_gateway) {
			membershipService
				.payStatus({ payment_ref, payment_gateway })
				.then(({ paymentStatus }) => {
					switch (paymentStatus) {
						case "PAID":
							if (!message1OpenRef.current) {
								message1OpenRef.current = true

								AppFunctions.clearPaymentDataFromLocalStorage()

								setPaidMembership()

								AppNotification.success({
									message: t(
										"message_successful_membership_payment"
									),
									onClose: () => {
										message1OpenRef.current = false
									},
								})
							}
							break

						case "PENDING":
							if (
								!message1OpenRef.current &&
								!(
									localStorage.getItem(
										AppLocalStorageKeys.PAY_PENDING_MESSAGE_SHOWN
									) === "true"
								)
							) {
								message1OpenRef.current = true

								setInProgressPaymentMembership()

								localStorage.setItem(
									AppLocalStorageKeys.PAY_PENDING_MESSAGE_SHOWN,
									"true"
								)

								AppNotification.warning({
									message: t(
										"message_in_progress_membership_payment"
									),
									onClose: () => {
										message1OpenRef.current = false
									},
								})
							}
							break

						case "FAILED":
							if (!message1OpenRef.current) {
								message1OpenRef.current = true

								AppNotification.error({
									message: t(
										"message_error_membership_payment"
									),
									duration: 3600,
									onClose: () => {
										message1OpenRef.current = false
										AppFunctions.clearPaymentDataFromLocalStorage()
									},
								})
							}
							break
						default:
							console.error(
								`Unknown payment status received. paymentStatus=${paymentStatus} and allowed values are "PAID" | "PENDING" | "FAILED"`
							)
							break
					}
				})
		}
	}

	return { pay, getPaymentStatus, paymentLoading }
}
