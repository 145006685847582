import * as React from "react"
import * as Antd from "antd"
import styles from "./AppText.module.scss"

import { AppConfig } from "@constants/AppConfig.ts"
import { IAppHtmlTags } from "@interfaces/generic/IAppHtmlTags.ts"
import { AppFunctions } from "@others/AppFunctions.ts"
import { useTranslation } from "react-i18next"

export interface IAppTextProps {
	className?: string
	tag?: IAppHtmlTags
	textKey?: string
	children?: string
	ellipsis?: boolean
	capitalize?: boolean
	capitalizeFirstChar?: boolean
	suffix?: string
}

const AppText: React.FC<IAppTextProps> = (props) => {
	let text: string | undefined = props.children
	const textRef = React.useRef<any>(null)

	const { t } = useTranslation()
	const [isOverflowing, setIsOverflowing] = React.useState(false)

	React.useEffect(() => {
		if (!textRef.current || !props.ellipsis) return

		const resizeObserver = new ResizeObserver(() => {
			setIsOverflowing(
				textRef.current!.scrollWidth > textRef.current!.clientWidth
			)
		})

		resizeObserver.observe(textRef.current!)

		return () => resizeObserver.disconnect()
	}, [])

	const Wrapper = props.tag || "p"

	if (props.textKey) {
		text = t(props.textKey)
	}

	if (props.capitalize) {
		text = text?.toUpperCase()
	}

	if (props.capitalizeFirstChar && text) {
		text = AppFunctions.capitalizeFirstChar(text)
	}

	return (
		<Antd.Tooltip
			title={isOverflowing ? props.children : undefined}
			placement={"topRight"}
		>
			<Wrapper
				ref={textRef}
				className={`${styles.app_text} ${props.ellipsis ? styles.ellipsis : ""} ${props.className || ""}`}
			>
				{`${text || AppConfig.APP_NOT_VALUE}${props?.suffix ? props.suffix : ""}`}
			</Wrapper>
		</Antd.Tooltip>
	)
}

export default React.memo(AppText)
