import * as React from "react"
import styles from "./DsProfileDropDown.module.scss"

import { useTranslation } from "react-i18next"
import { AppRoutes } from "@constants/AppRoutes.ts"
import { AppFunctions } from "@others/AppFunctions.ts"
import { useLocation, useNavigate } from "react-router-dom"

import useAuthCtx from "@contexts/hooks/useAuthCtx.tsx"
import DvAppDropdown from "@components/navigation/DvAppDropdown/DvAppDropdown.tsx"
import DropDownHeader from "@components/navigation/DsProfileDropDown/DropDownHeader/DropDownHeader.tsx"

interface IProps {
	className?: string
	children?: React.ReactNode
}

const DsProfileDropDown: React.FC<IProps> = (props) => {
	const { t } = useTranslation()
	const { setIsLogged } = useAuthCtx()

	const navigate = useNavigate()
	const location = useLocation()

	const profileItemActive = React.useMemo(
		() => location.pathname === AppRoutes.userProfile,
		[location.pathname]
	)

	const settingsItemActive = React.useMemo(
		() => location.pathname === AppRoutes.userSettings,
		[location.pathname]
	)

	const myBookingsItemActive = React.useMemo(
		() => location.pathname === AppRoutes.userMyBookings,
		[location.pathname]
	)

	const items = [
		{
			key: "user-data",
			disabled: true,
			className: styles.drop_down_header_container,
			label: <DropDownHeader />,
		},
		{
			key: "profile",
			label: t("profile"),
			active: profileItemActive,
			onClick: () => {
				navigate(AppRoutes.userProfile)
			},
		},
		{
			key: "my-bookings",
			label: t("my_Bookings"),
			active: myBookingsItemActive,
			onClick: () => {
				navigate(AppRoutes.userMyBookings)
			},
		},
		{
			key: "setting",
			label: t("settings"),
			active: settingsItemActive,
			onClick: () => {
				navigate(AppRoutes.userSettings)
			},
		},
		{
			key: "logout",
			label: t("close_session"),
			onClick: () => {
				setIsLogged(false)
				AppFunctions.clearLoginDataFromLocalStorage()
				navigate(AppRoutes.login, { replace: true })
			},
		},
	]

	return (
		<DvAppDropdown
			className={`${styles.profile_drop_down} ${props.className || ""}`}
			overlayClassName={styles.overlay}
			menu={{ items }}
		>
			{props.children}
		</DvAppDropdown>
	)
}

export default React.memo(DsProfileDropDown)
