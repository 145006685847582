import * as React from "react"
import * as Antd from "antd"
import styles from "./DvStartEndDateTime.module.scss"

import { Tag } from "antd"
import { useAppDateTime } from "@hooks/useAppDateTime.ts"
import { useTranslation } from "react-i18next"

import AppText from "@components/general/AppText/AppText.tsx"
import DvDateInfoTooltip from "@components/dataDisplay/DvDateInfoTooltip/DvDateInfoTooltip.tsx"

interface IProps {
	className?: string
	startDate?: string
	endDate?: string
}

const DvStartEndDateTime: React.FC<IProps> = (props) => {
	const { t } = useTranslation()
	const { formatDate, checkIfIsToday } = useAppDateTime()

	const formattedDate = React.useMemo(
		() =>
			formatDate({
				date: props?.startDate,
				format: "DD MMM YYYY",
			}),
		[formatDate, props?.startDate]
	)

	const formattedStartTime = React.useMemo(
		() =>
			formatDate({
				date: props?.startDate,
				format: "HH:mm",
			}),
		[formatDate, props?.startDate]
	)

	const formattedEndTime = React.useMemo(
		() =>
			formatDate({
				date: props?.endDate,
				format: "HH:mm",
			}),
		[formatDate, props?.endDate]
	)

	const isToday = React.useMemo(
		() =>
			props?.startDate
				? checkIfIsToday({ date: props?.startDate })
				: false,
		[checkIfIsToday, props?.startDate]
	)

	return (
		<div
			className={`${styles.dv_start_end_date_time} ${props.className || ""}`}
		>
			{isToday ? (
				<Tag color="magenta" className={styles.today_tag}>
					{t("today").toUpperCase()}
				</Tag>
			) : (
				<AppText className={styles.date}>
					{props.startDate && formattedDate}
				</AppText>
			)}

			<Antd.Divider type="vertical" className={styles.divider} />

			<AppText className={styles.date}>
				{props?.startDate &&
					props?.endDate &&
					`${formattedStartTime} - ${formattedEndTime}`}
			</AppText>

			<DvDateInfoTooltip
				className={styles.tooltip_info}
				startDate={props.startDate}
				endDate={props.endDate}
			/>
		</div>
	)
}

export default React.memo(DvStartEndDateTime)
