import * as React from "react"

import { AuthCtxProvider } from "@contexts/AuthCtx.tsx"
import { BookingsCtxProvider } from "@contexts/BookingsCtx.tsx"
import { PlanDataCtxProvider } from "@contexts/PlanDataCtx.tsx"
import { SpaceAllPlacesCtxProvider } from "@contexts/SpaceAllPlacesCtx.tsx"
import { ResponsiveToolCtxProvider } from "@contexts/ResponsiveToolCtx.tsx"
import { MembershipLayoutCtxProvider } from "@contexts/MembershipLayoutCtx.tsx"

export const AppCtxProvider: React.FC<{
	children: React.ReactNode
}> = ({ children }) => (
	<ResponsiveToolCtxProvider>
		<AuthCtxProvider>
			<MembershipLayoutCtxProvider>
				<SpaceAllPlacesCtxProvider>
					<PlanDataCtxProvider>
						<BookingsCtxProvider>{children}</BookingsCtxProvider>
					</PlanDataCtxProvider>
				</SpaceAllPlacesCtxProvider>
			</MembershipLayoutCtxProvider>
		</AuthCtxProvider>
	</ResponsiveToolCtxProvider>
)
