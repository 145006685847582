import * as React from "react"
import styles from "./Bookings.module.scss"

import { useTranslation } from "react-i18next"
import { useLoggedUserData } from "@hooks/useLoggedUserData.ts"
import { useNavigate } from "react-router-dom"
import { AppRoutes } from "@constants/AppRoutes.ts"

import BookingsItem from "@pages/UserProfile/Bookings/BookingsItem/BookingsItem.tsx"
import DvConfirmationModal from "@components/feedback/DvConfirmationModal/DvConfirmationModal.tsx"
import ApplyMembershipMessage from "@pages/UserProfile/Bookings/ApplyMembershipMessage/ApplyMembershipMessage.tsx"
import PayMembershipMessage from "@pages/UserProfile/Bookings/PayMembershipMessage/PayMembershipMessage.tsx"

const modalDefState = {
	open: false,
	okTextKey: "",
	content: <></>,
	onOk: () => {},
}

interface IProps {
	className?: string
}

const Bookings: React.FC<IProps> = (props) => {
	const navigate = useNavigate()

	const { t } = useTranslation()
	const { loggedUser } = useLoggedUserData()

	const [modal, setModal] = React.useState(modalDefState)

	const actionsDisabled = React.useMemo(() => {
		return (
			loggedUser?.membershipStatus === "PENDING_TO_APPROVE" ||
			loggedUser?.membershipStatus === "PAYMENT_IN_PROGRESS"
		)
	}, [loggedUser?.membershipStatus])

	const getActionsInfoTextKey = React.useCallback(() => {
		let textKey = ""

		switch (loggedUser?.membershipStatus) {
			case "PENDING_TO_APPROVE":
				textKey =
					"message_membership_already_applied_waiting_for_approval"
				break
			case "PAYMENT_IN_PROGRESS":
				textKey =
					"message_membership_payment_in_progress_waiting_for_approval"
				break
		}

		return textKey
	}, [loggedUser?.membershipStatus])

	const handleBookingsItemClick = React.useCallback(
		(type: "restaurants" | "spaces" | "events") => {
			if (loggedUser?.membershipStatus === "NO_MEMBERSHIP") {
				setModal({
					open: true,
					okTextKey: "apply_for_membership",
					content: <ApplyMembershipMessage />,
					onOk: () => navigate(AppRoutes.membershipPlans),
				})
			} else if (loggedUser?.membershipStatus === "APPROVED") {
				// fix the text for this case
				setModal({
					open: true,
					okTextKey: "make_payment",
					content: <PayMembershipMessage />,
					onOk: () => navigate(AppRoutes.membershipPayment),
				})
			} else {
				switch (type) {
					case "restaurants":
						console.log("go to restaurants")
						break
					case "spaces":
						navigate(AppRoutes.userProfileBookSpace_place)
						break
					case "events":
						console.log("go to events")
						break
				}
			}
		},
		[loggedUser?.membershipStatus]
	)

	return (
		<>
			<div className={`${styles.bookings} ${props.className || ""}`}>
				<div className={styles.header}>
					<h1 className={styles.title}>{t("bookings")}</h1>

					<h3 className={styles.subtitle}>
						{t("message_choose_the_perfect_space")}
					</h3>
				</div>

				<div className={styles.make_booking}>
					<div className={styles.make_booking_content}>
						<BookingsItem
							textKey="restaurants"
							hasBottomDivider
							comingSoon
							tooltipTextKey={getActionsInfoTextKey()}
							buttonDisabled={actionsDisabled}
							onClick={() =>
								handleBookingsItemClick("restaurants")
							}
						/>

						<BookingsItem
							textKey="spaces"
							hasBottomDivider
							tooltipTextKey={getActionsInfoTextKey()}
							buttonDisabled={actionsDisabled}
							onClick={() => handleBookingsItemClick("spaces")}
						/>

						<BookingsItem
							textKey="events"
							hasBottomDivider
							comingSoon
							tooltipTextKey={getActionsInfoTextKey()}
							buttonDisabled={actionsDisabled}
							onClick={() => handleBookingsItemClick("events")}
						/>
					</div>
				</div>
			</div>

			<DvConfirmationModal
				open={modal.open}
				width={366}
				okTextKey={modal.okTextKey}
				onOk={modal.onOk}
				onCancel={() => setModal(modalDefState)}
			>
				{modal.content}
			</DvConfirmationModal>
		</>
	)
}

export default React.memo(Bookings)
