import * as React from "react"
import styles from "./RedirectConfirmationMessage.module.scss"

import { useTranslation } from "react-i18next"

interface IProps {
	className?: string
}

const RedirectConfirmationMessage: React.FC<IProps> = (props) => {
	const { t } = useTranslation()

	return (
		<div
			className={`${styles.redirect_confirmation_message} ${props.className || ""}`}
		>
			<h1 className={styles.title}>
				{t("you_are_about_to_leave_the_website")}
			</h1>

			<main className={styles.body}>
				<p>{t("message_you_will_be_redirected_to_complete_payment")}</p>

				<p>{t("do_you_want_to_continue")}</p>
			</main>
		</div>
	)
}

export default React.memo(RedirectConfirmationMessage)
