import * as React from "react"
import styles from "./UserProfileInfo.module.scss"

import Bookings from "@pages/UserProfile/Bookings/Bookings.tsx"
import DownloadApp from "@pages/UserProfile/DownloadApp/DownloadApp.tsx"

interface IProps {
	className?: string
}

const UserProfileInfo: React.FC<IProps> = (props) => {
	return (
		<main
			className={`${styles.user_profile_info} ${props.className || ""}`}
		>
			<div className={styles.row}>
				<Bookings className={styles.bookings} />

				<DownloadApp className={styles.download_app} />
			</div>
		</main>
	)
}

export default React.memo(UserProfileInfo)
