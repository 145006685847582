import * as React from "react"
import styles from "./MembershipKindOfForm.module.scss"

import { AppRoutes } from "@constants/AppRoutes.ts"
import { useNavigate } from "react-router-dom"
import { usePlanData } from "@hooks/usePlanData.tsx"
import { useTranslation } from "react-i18next"
import { useMembershipApplyForm } from "@hooks/useMembershipApplyForm.tsx"
import { AppCorporateSubscriptions } from "@constants/AppCorporateSubscriptions.ts"

import AppText from "@components/general/AppText/AppText.tsx"
import Switches from "@components/dataDisplay/DsPlanTable/Switches/Switches.tsx"
import DsPlanInfoCard from "@components/dataDisplay/DsPlanInfoCard/DsPlanInfoCard.tsx"
import PlanDetailItem from "@components/dataEntry/MembershipKindOfForm/PlanDetailItem/PlanDetailItem.tsx"
import MembershipFormFooter from "@components/layout/MembershipFormFooter/MembershipFormFooter.tsx"
import PlanSubscriptionDetails from "@components/dataDisplay/PlanSubscriptionDetails/PlanSubscriptionDetails.tsx"

export interface IMembershipKindOfFormFieldType {
	plan?: string
	paymentFrequency?: string
}

interface IProps {
	className?: string
}

const MembershipKindOfForm: React.FC<IProps> = (props) => {
	const navigate = useNavigate()

	const { t } = useTranslation()
	const { planData } = usePlanData({ fetchOnMount: true })

	const initialValues: IMembershipKindOfFormFieldType = React.useMemo(
		() => ({
			planId: planData?.defaultPlan,
			paymentFrequency: planData?.defaultBillingCycle,
		}),
		[planData]
	)

	const { states, onChange } = useMembershipApplyForm(
		undefined,
		initialValues
	)

	const selectedPlan = React.useMemo(
		() => planData?.plans.find(({ id }) => id === states.plan),
		[planData, states]
	)

	const selectedPlanSubscription = React.useMemo(
		() =>
			selectedPlan?.subscriptions.find(
				({ id }) => id === states.subscription
			),
		[planData, states]
	)

	const selectedPlanBillingCycle = React.useMemo(
		() =>
			selectedPlanSubscription?.billingCycle.find(
				({ id }) => id === states.paymentFrequency
			),
		[planData, states]
	)

	const isACorporateSubscription = React.useMemo(
		() => AppCorporateSubscriptions.includes(states?.subscription || ""),
		[states]
	)

	const onFinish = async () => {
		await onChange("plan", states.plan)
		await onChange("paymentFrequency", states.paymentFrequency)

		navigate(AppRoutes.membershipFormStep2_personalDetails, {
			replace: true,
		})
	}

	return (
		<div
			className={`${styles.membership_kind_of_form} ${props.className || ""}`}
		>
			<div className={styles.payment_details_container}>
				<div className={styles.frequency_payment_actions}>
					<label className={styles.actions_header}>
						{t("choose_your_payment_method")}
					</label>

					<Switches
						className={styles.switches}
						key={`${states?.subscription} ${states?.paymentFrequency}`}
						defaultSubscription={states?.subscription}
						defaultBillingCycle={states?.paymentFrequency}
						onBillingCycleChange={(value) => {
							onChange("paymentFrequency", value)
						}}
						onSubscriptionChange={(value) => {
							onChange("subscription", value)
						}}
					/>
				</div>

				<PlanSubscriptionDetails
					className={styles.plan_subscription_details}
					subscriptionName={selectedPlanSubscription?.name}
					billingCycleName={selectedPlanBillingCycle?.name}
					subscriptionDescription={
						selectedPlanSubscription?.description
					}
				/>

				{selectedPlanBillingCycle && (
					<div className={styles.payment_details}>
						<AppText
							className={styles.payment_title}
							tag="h1"
							textKey={
								isACorporateSubscription
									? "membership_value_per_person"
									: "membership_value"
							}
							suffix=":"
						/>

						{selectedPlanBillingCycle.firstPayment && (
							<PlanDetailItem
								title={t("payment_1")}
								subtitle={t("monthly_plus_access_fee")}
								currency="US$"
								value={selectedPlanBillingCycle.firstPayment}
							/>
						)}

						{selectedPlanBillingCycle.remainingPayments && (
							<PlanDetailItem
								title={t("payment_2_12")}
								currency="US$"
								value={
									selectedPlanBillingCycle.remainingPayments
								}
								unit={t("ea")}
								strongBorder
							/>
						)}

						{selectedPlanBillingCycle.annuity && (
							<PlanDetailItem
								title={t("annuity")}
								currency="US$"
								value={selectedPlanBillingCycle.annuity}
							/>
						)}

						{selectedPlanBillingCycle.accessFee && (
							<PlanDetailItem
								title={t("access_fee")}
								currency="US$"
								value={selectedPlanBillingCycle.accessFee}
								strongBorder
							/>
						)}

						{selectedPlanBillingCycle.total && (
							<PlanDetailItem
								title={t("total")}
								currency="US$"
								value={selectedPlanBillingCycle.total}
								strongText
							/>
						)}
					</div>
				)}
			</div>

			<div className={styles.plan_cards}>
				{planData?.plans.map(({ id, name, info }) => {
					return (
						<DsPlanInfoCard
							key={id}
							name={name}
							info={info}
							selected={id === states.plan}
							onCardClick={() => onChange("plan", id)}
						/>
					)
				})}

				<MembershipFormFooter
					onBackClick={() =>
						navigate(AppRoutes.membershipFormStep1_address)
					}
					onNextClick={onFinish}
				/>
			</div>
		</div>
	)
}

export default React.memo(MembershipKindOfForm)
