import * as React from "react"
import * as Antd from "antd"
import styles from "./MembershipPersonalDetailsForm.module.scss"

import { useTranslation } from "react-i18next"
import { AppFunctions } from "@others/AppFunctions.ts"
import { useNavigate } from "react-router-dom"
import { useListOfAllCountries } from "@hooks/useListOfAllCountries.tsx"
import { useListOfGenders } from "@hooks/useListOfGenders.tsx"
import { AppRoutes } from "@constants/AppRoutes.ts"
import { useMembershipApplyForm } from "@hooks/useMembershipApplyForm.tsx"

import useAuthCtx from "@contexts/hooks/useAuthCtx.tsx"
import DvPhotoUpload from "@components/dataEntry/DvPhotoUpload/DvPhotoUpload.tsx"
import DvSelect from "@components/dataEntry/DvSelect/DvSelect.tsx"
import DvCheckbox from "@components/dataEntry/DvCheckbox/DvCheckbox.tsx"
import DvInput from "@components/dataEntry/DvInput/DvInput.tsx"
import PhotoUploadFormItem from "@components/layout/PhotoUploadFormItem/PhotoUploadFormItem.tsx"
import DvAntdForm from "@components/others/DvAntdForm/DvAntdForm.tsx"
import DvAntdFormItem from "@components/others/DvAntdFormItem/DvAntdFormItem.tsx"
import MembershipFormFooter from "@components/layout/MembershipFormFooter/MembershipFormFooter.tsx"
import DvInfoModal from "@components/feedback/DvInfoModal/DvInfoModal.tsx"
import WhatsAppPrivacyPolicy from "@components/dataEntry/SignUpForm/WhatsAppPrivacyPolicy/WhatsAppPrivacyPolicy.tsx"
import PrivacyPolicy from "@components/dataEntry/SignUpForm/PrivacyPolicy/PrivacyPolicy.tsx"
import validationRules from "@others/validationRules.ts"

export interface IMembershipPersonalDetailsFormFieldType {
	profilePhoto?: string
	phone?: string
	nationality?: string
	gender?: string
	nationalId?: string
	receivePromotionsByWhatsapp?: boolean
}

interface IProps {
	className?: string
}

const MembershipPersonalDetailsForm: React.FC<IProps> = (props) => {
	const navigate = useNavigate()

	const [form] = Antd.Form.useForm<IMembershipPersonalDetailsFormFieldType>()
	const { t } = useTranslation()
	const { loggedUser } = useAuthCtx()
	const { countries, loading } = useListOfAllCountries()
	const { genders } = useListOfGenders()

	const [ourPrivacyPolicyModalOpen, setOurPrivacyPolicyModalOpen] =
		React.useState(false)

	const [whatsappPrivacyPolicyModalOpen, setWhatsappPrivacyPolicyModalOpen] =
		React.useState(false)

	const initialValues: IMembershipPersonalDetailsFormFieldType =
		React.useMemo(
			() => ({
				profilePhoto: loggedUser?.profilePhoto,
				nationality: undefined,
				gender: undefined,
				nationalId: undefined,
				phone: loggedUser?.phone,
				receivePromotionsByWhatsapp: true,
			}),
			[loggedUser]
		)

	const { states, onChange } = useMembershipApplyForm(form, initialValues)

	const onFinish = async ({
		receivePromotionsByWhatsapp,
		nationalId,
		phone,
		gender,
		nationality,
	}: IMembershipPersonalDetailsFormFieldType) => {
		await onChange("nationalId", nationalId)
		await onChange("phone", phone)
		await onChange("gender", gender)
		await onChange("nationality", nationality)

		await onChange(
			"receivePromotionsByWhatsapp",
			receivePromotionsByWhatsapp
		)

		navigate(AppRoutes.membershipFormStep2_job, { replace: true })
	}

	return (
		<>
			<DvAntdForm<IMembershipPersonalDetailsFormFieldType>
				form={form}
				className={`${styles.membership_personal_details_form} ${
					props.className || ""
				}`}
				initialValues={initialValues}
				onFinish={onFinish}
			>
				<PhotoUploadFormItem className={styles.photo_upload_form_item}>
					<DvPhotoUpload
						base64Image={states.profilePhoto}
						onSuccess={({ base64Image }) =>
							onChange("profilePhoto", base64Image)
						}
						onRemove={() => onChange("profilePhoto", undefined)}
					/>
				</PhotoUploadFormItem>

				<DvAntdFormItem<IMembershipPersonalDetailsFormFieldType>
					label={t("phone")}
					name="phone"
					validateDebounce={1500}
					rules={[
						validationRules.requiredFieldRule(
							t("please_enter_your_phone")
						),
						validationRules.phoneRule(
							t("message_your_phone_is_not_valid")
						),
					]}
				>
					<DvInput
						placeholder={t("insert_your_phone")}
						onChange={(e) => onChange("phone", e.target.value)}
					/>
				</DvAntdFormItem>

				<DvAntdFormItem<IMembershipPersonalDetailsFormFieldType>
					className={styles.form_item}
					label={t("nationality")}
					name="nationality"
					rules={[
						validationRules.requiredFieldRule(
							t("please_select_your_nationality")
						),
					]}
				>
					<DvSelect
						options={countries}
						loading={loading}
						placeholder={t("select_your_nationality")}
						onSelect={(value) => onChange("nationality", value)}
					/>
				</DvAntdFormItem>

				<DvAntdFormItem<IMembershipPersonalDetailsFormFieldType>
					label={t("message_gender")}
					name="gender"
					rules={[
						validationRules.requiredFieldRule(
							t("please_select_your_nationality")
						),
					]}
				>
					<DvSelect
						options={genders}
						onSelect={(value) => onChange("gender", value)}
					/>
				</DvAntdFormItem>

				<DvAntdFormItem<IMembershipPersonalDetailsFormFieldType>
					label={t("national_identification_number")}
					name="nationalId"
					rules={[
						validationRules.requiredFieldRule(
							t(
								"please_enter_your_national_identification_number"
							)
						),
					]}
				>
					<DvInput
						placeholder={t(
							"insert_your_national_identification_number"
						)}
						onChange={(e) => onChange("nationalId", e.target.value)}
					/>
				</DvAntdFormItem>

				<DvAntdFormItem<IMembershipPersonalDetailsFormFieldType>
					className={`${styles.form_item} ${styles.receive_promotions_item}`}
					name="receivePromotionsByWhatsapp"
					valuePropName="checked"
				>
					<DvCheckbox
						className={styles.checkbox}
						onChange={(e) =>
							onChange(
								"receivePromotionsByWhatsapp",
								e.target.checked
							)
						}
					>
						{t("message_receive_notifications_byWhatsapp")}{" "}
						<strong
							onClick={() =>
								setWhatsappPrivacyPolicyModalOpen(true)
							}
						>
							{t("privacy_policy_whatsapp")}
						</strong>{" "}
						{t("and")} {t("ours")}{" "}
						<strong
							onClick={() => setOurPrivacyPolicyModalOpen(true)}
						>
							{t("privacy_policy")}
						</strong>
						.
					</DvCheckbox>
				</DvAntdFormItem>
			</DvAntdForm>

			<MembershipFormFooter
				onBackClick={() =>
					navigate(AppRoutes.membershipFormStep1_kind_of_membership)
				}
				onNextClick={() => form.submit()}
			/>

			<DvInfoModal
				open={whatsappPrivacyPolicyModalOpen}
				title={AppFunctions.capitalizeFirstChar(
					t("privacy_policy_whatsapp")
				)}
				onClose={() => setWhatsappPrivacyPolicyModalOpen(false)}
			>
				<WhatsAppPrivacyPolicy />
			</DvInfoModal>

			<DvInfoModal
				open={ourPrivacyPolicyModalOpen}
				title={AppFunctions.capitalizeFirstChar(t("privacy_policy"))}
				onClose={() => setOurPrivacyPolicyModalOpen(false)}
			>
				<PrivacyPolicy />
			</DvInfoModal>
		</>
	)
}

export default React.memo(MembershipPersonalDetailsForm)
