import * as React from "react"
import * as Antd from "antd"
import styles from "./BookSpaceSchedule.module.scss"

import { AppRoutes } from "@constants/AppRoutes.ts"
import { useLocation, useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { TeamOutlined } from "@ant-design/icons"
import { AppNotification } from "@others/AppNotification.ts"
import { useBookingsData } from "@hooks/useBookingsData.ts"
import { useSpaceAvailableDaysData } from "@hooks/useSpaceAvailableDays.tsx"
import { useSpaceAvailableDaysHours } from "@hooks/useSpaceAvailableHours.tsx"

import DsStackedCard, {
	IDsStackedCardProps,
} from "@components/dataDisplay/DsStackedCard/DsStackedCard.tsx"

import dayjs from "dayjs"
import DsButton from "@components/general/DsButton/DsButton.tsx"
import DvAntdForm from "@components/others/DvAntdForm/DvAntdForm.tsx"
import validationRules from "@others/validationRules.ts"
import DvAntdFormItem from "@components/others/DvAntdFormItem/DvAntdFormItem.tsx"
import DvSelect from "@components/dataEntry/DvSelect/DvSelect.tsx"
import DvInput from "@components/dataEntry/DvInput/DvInput.tsx"
import bookService from "@services/bookService/bookService.ts"
import ErrorBookingSpaceMessage from "@pages/BookSpaceSchedule/ErrorBookingSpaceMessage/ErrorBookingSpaceMessage.tsx"
import SuccessBookingSpaceMessage from "@pages/BookSpaceSchedule/SuccessBookingSpaceMessage/SuccessBookingSpaceMessage.tsx"

export interface ISpaceScheduleFieldType {
	placeId?: string
	date?: string
	timeBlock?: string
	guests?: number
}

interface IProps {
	className?: string
}

const BookSpaceSchedule: React.FC<IProps> = (props) => {
	const location = useLocation()
	const navigate = useNavigate()
	const selectedDateRef = React.useRef<string | undefined>()
	const spaceData = location.state?.spaceData as IDsStackedCardProps["data"]
	const capacity = location.state?.capacity as number

	const { t, i18n } = useTranslation()
	const { fetchBookings } = useBookingsData()
	const [form] = Antd.Form.useForm<ISpaceScheduleFieldType>()
	const [bookingLoading, setBookingLoading] = React.useState(false)

	const { spaceAvailableDays, fetchSpaceAvailableDays } =
		useSpaceAvailableDaysData()

	const {
		spaceAvailableHours,
		fetchSpaceAvailableHours,
		spaceAvailableHoursLoading,
	} = useSpaceAvailableDaysHours()

	const onFinish = (values: ISpaceScheduleFieldType) => {
		const formattedValues = {
			...values,
			date: selectedDateRef.current,
		}

		setBookingLoading(true)

		bookService
			.bookSpace({
				place_id: spaceData.id!,
				date: formattedValues.date!,
				time_block: formattedValues.timeBlock!,
				guests: formattedValues.guests!,
			})
			.then(() => {
				fetchBookings()

				AppNotification.success({
					message: (
						<SuccessBookingSpaceMessage
							goToMyBookings={() =>
								navigate(AppRoutes.userMyBookings)
							}
						/>
					),
				})

				navigate(AppRoutes.userProfile)
			})
			.catch(({ message }) => {
				if (message) {
					AppNotification.error({
						message,
					})
				} else {
					AppNotification.error({
						message: <ErrorBookingSpaceMessage />,
					})
				}
			})
			.finally(() => {
				setBookingLoading(false)
			})
	}

	React.useEffect(() => {
		if (!spaceData?.id) return
		fetchSpaceAvailableDays(spaceData.id)
	}, [spaceData?.id, i18n.language])

	return (
		<div
			className={`${styles.book_space_schedule} ${props.className || ""}`}
		>
			<div className={styles.main}>
				<DsStackedCard data={spaceData} />

				<DvAntdForm<ISpaceScheduleFieldType>
					form={form}
					className={styles.space_schedule_form}
					onFinish={onFinish}
				>
					<DvAntdFormItem<ISpaceScheduleFieldType>
						label={t("message_enter_reservation_date")}
						name="date"
						rules={[
							validationRules.requiredFieldRule(
								t("message_please_enter_reservation_date")
							),
						]}
					>
						<Antd.DatePicker
							format={t("date_format")}
							style={{ width: "100%" }}
							disabled={!spaceAvailableDays?.length}
							disabledDate={(current) =>
								!spaceAvailableDays.some((date) =>
									dayjs(date).isSame(current, "day")
								)
							}
							defaultPickerValue={
								spaceAvailableDays.length > 0
									? dayjs(spaceAvailableDays[0])
									: undefined
							}
							onChange={(date) => {
								selectedDateRef.current = date
									? dayjs(date).format("YYYY-MM-DD")
									: undefined

								if (spaceData?.id && selectedDateRef.current) {
									fetchSpaceAvailableHours(
										spaceData.id,
										selectedDateRef.current
									)
								} else {
									console.error(
										`placeId or selectedDateRef.current is not available. placeId: ${spaceData?.id}, selectedDateRef.current: ${selectedDateRef.current}`
									)
								}
							}}
						/>
					</DvAntdFormItem>

					<div className={styles.time_block_container}>
						<DvAntdFormItem<ISpaceScheduleFieldType>
							label={t("select_a_time_block")}
							name="timeBlock"
							rules={[
								validationRules.requiredFieldRule(
									t("please_select_a_time_block")
								),
							]}
						>
							<DvSelect
								options={spaceAvailableHours}
								loading={spaceAvailableHoursLoading}
								disabled={
									!spaceAvailableHours?.length ||
									spaceAvailableHoursLoading
								}
							/>
						</DvAntdFormItem>

						<span className={styles.time_block_caption}>
							{t("message_time_caption")}
						</span>
					</div>

					<DvAntdFormItem<ISpaceScheduleFieldType>
						label={t("enter_the_number_of_people")}
						name="guests"
						rules={[
							{
								required: true,
								message: t("please_enter_the_number_of_people"),
							},
						]}
					>
						<DvInput.InputNumber
							suffix={<TeamOutlined style={{ fontSize: 16 }} />}
							placeholder={t("enter_the_amount")}
							min={1}
							max={capacity}
						/>
					</DvAntdFormItem>
				</DvAntdForm>
			</div>

			<div className={styles.footer}>
				<DsButton type="secondary" onClick={() => navigate(-1)}>
					{t("back")}
				</DsButton>

				<DsButton
					onClick={() => form.submit()}
					loading={bookingLoading}
				>
					{t("book")}
				</DsButton>
			</div>
		</div>
	)
}

export default React.memo(BookSpaceSchedule)
