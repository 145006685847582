import * as React from "react"
import * as Antd from "antd"
import * as AntdIcons from "@ant-design/icons"
import styles from "./DvNavMenuDropdown.module.scss"

import { MenuProps } from "antd"

interface IProps {
	className?: string
	items: MenuProps["items"]
	children?: React.ReactNode
}

const DvNavMenuDropdown: React.FC<IProps> = (props) => {
	const items: MenuProps["items"] = props.items?.map((item) => ({
		...item!,
		className: `${styles.item} ${item?.className || ""}`,
	}))

	return (
		<Antd.Dropdown
			className={`${styles.dv_nav_menu_dropdown} ${props.className || ""}`}
			placement="bottomLeft"
			menu={{
				className: styles.menu,
				items,
			}}
		>
			<Antd.Space>
				{props.children}
				<AntdIcons.DownOutlined />
			</Antd.Space>
		</Antd.Dropdown>
	)
}

export default React.memo(DvNavMenuDropdown)
