import * as React from "react"
import styles from "./DsSpace.module.scss"

interface IProps {
	amountOfPlans: number
	firstSpaceClassName?: string
	className?: string
}

const DsSpace: React.FC<IProps> = ({
	className,
	amountOfPlans,
	firstSpaceClassName,
}) => {
	return (
		<>
			{new Array(amountOfPlans + 1).fill(0).map((_, idx) => {
				const firstItemClassNameMath =
					idx === 0 && firstSpaceClassName ? firstSpaceClassName : ""

				return (
					<div
						className={`${className} ${styles.space} ${firstItemClassNameMath}`}
						key={idx}
					/>
				)
			})}
		</>
	)
}

export default React.memo(DsSpace)
