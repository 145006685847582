import * as React from "react"
import styles from "./UserProfile.module.scss"

import { Outlet, useLocation } from "react-router-dom"
import { AppRoutes } from "@constants/AppRoutes.ts"
import { AppNotification } from "@others/AppNotification.ts"
import { useLoggedUserData } from "@hooks/useLoggedUserData.ts"
import { useMembershipPayment } from "@hooks/useMembershipPayment.tsx"
import { AppMembershipStatus } from "@constants/AppMembershipStatus.ts"
import { AppLocalStorageKeys } from "@constants/AppLocalStorageKeys.ts"
import { useSpaceAllPlacesData } from "@hooks/useSpaceAllPlacesData.ts"

import UserInfo from "@pages/UserProfile/UserInfo/UserInfo.tsx"
import DsFooter from "@components/layout/DsFooter/DsFooter.tsx"
import BookLayout from "@components/layout/BookLayout/BookLayout.tsx"
import DvMessage_1 from "@components/feedback/DvMessage_1/DvMessage_1.tsx"
import DvMessage_2 from "@components/feedback/DvMessage_2/DvMessage_2.tsx"
import UpcomingBookings from "@pages/UserProfile/UpcomingBookings/UpcomingBookings.tsx"
import useResponsiveToolCtx from "@contexts/hooks/useResponsiveToolCtx.tsx"

interface IProps {
	className?: string
}

const UserProfile: React.FC<IProps> = (props) => {
	const { isMobile } = useResponsiveToolCtx()
	const { fetchLoggedUser, loggedUser } = useLoggedUserData()
	const { getPaymentStatus } = useMembershipPayment()
	const { fetchSpaceAllPlaces } = useSpaceAllPlacesData()

	const location = useLocation()
	const isInUserProfile = location.pathname === AppRoutes.userProfile
	const headerHidden = !isInUserProfile && isMobile

	const isExternalMembershipAppliesFlowInProgress =
		localStorage.getItem(
			AppLocalStorageKeys.EXTERNAL_MEMBERSHIP_APPLIES_FLOW_IN_PROGRESS
		) === "true"

	React.useEffect(() => {
		if (isExternalMembershipAppliesFlowInProgress && loggedUser) {
			switch (loggedUser?.membershipStatus) {
				case AppMembershipStatus.PENDING_TO_APPROVE:
					localStorage.removeItem(
						AppLocalStorageKeys.EXTERNAL_MEMBERSHIP_APPLIES_FLOW_IN_PROGRESS
					)
					AppNotification.warning({
						message: <DvMessage_1 />,
						placement: "bottom",
						duration: 600,
					})
					break
				default:
					localStorage.removeItem(
						AppLocalStorageKeys.EXTERNAL_MEMBERSHIP_APPLIES_FLOW_IN_PROGRESS
					)
					AppNotification.warning({
						message: <DvMessage_2 />,
						placement: "bottom",
						duration: 600,
					})
			}
		}
	}, [loggedUser])

	React.useEffect(() => {
		fetchLoggedUser().then(() => {
			fetchSpaceAllPlaces()
			getPaymentStatus()
		})
	}, [])

	return (
		<div className={`${styles.user_profile} ${props.className || ""}`}>
			<div className={styles.user_profile_container}>
				{!headerHidden && (
					<div className={styles.header}>
						<UserInfo
							className={styles.user_info}
							titleCentered={isMobile}
						/>

						<UpcomingBookings />
					</div>
				)}

				{isInUserProfile ? (
					<Outlet />
				) : (
					<BookLayout>
						<Outlet />
					</BookLayout>
				)}
			</div>

			{isInUserProfile && <DsFooter />}
		</div>
	)
}

export default React.memo(UserProfile)
