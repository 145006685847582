import * as React from "react"
import styles from "./StartApplying.module.scss"

import { AppRoutes } from "@constants/AppRoutes.ts"
import { useLocation, useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { AppLocalStorageKeys } from "@constants/AppLocalStorageKeys.ts"
import { useMembershipApplyForm } from "@hooks/useMembershipApplyForm.tsx"
import { AppCorporateSubscriptions } from "@constants/AppCorporateSubscriptions.ts"
import { IExternalMembershipApplies } from "@pages/ExternalMembershipAppliesFlow/ExternalMembershipAppliesFlow.tsx"

import useAuthCtx from "@contexts/hooks/useAuthCtx.tsx"
import MembershipFormFooter from "@components/layout/MembershipFormFooter/MembershipFormFooter.tsx"
import membershipFormDataService from "@services/membershipApplyFormService.ts"

interface IProps {
	className?: string
}

const StartApplying: React.FC<IProps> = (props) => {
	const { loggedUser } = useAuthCtx()
	const { t } = useTranslation()

	const location = useLocation()
	const navigate = useNavigate()

	const { states } = useMembershipApplyForm(undefined)

	// membership external apply flow
	const externalMembershipApplies = location.state
		?.externalMembershipApplies as IExternalMembershipApplies | undefined

	const isACorporateSubscription = AppCorporateSubscriptions.includes(
		states?.subscription || ""
	)

	React.useEffect(() => {
		const updateFormValues = async () => {
			await membershipFormDataService.setKey(
				"plan",
				externalMembershipApplies?.plan
			)

			await membershipFormDataService.setKey(
				"subscription",
				externalMembershipApplies?.subscription
			)

			await membershipFormDataService.setKey(
				"paymentFrequency",
				externalMembershipApplies?.paymentFrequency
			)
		}

		if (externalMembershipApplies) {
			localStorage.removeItem(
				AppLocalStorageKeys.EXTERNAL_MEMBERSHIP_APPLIES_FLOW_IN_PROGRESS
			)

			updateFormValues()
		}
	}, [])

	return (
		<div className={`${styles.start_applying} ${props.className || ""}`}>
			<h1 className={styles.title}>
				{t("hi")}
				{loggedUser?.name && `, ${loggedUser?.name}.`}
			</h1>
			<h3 className={styles.sub_title}>
				{isACorporateSubscription
					? t("message_starting_a_corporate_membership")
					: t("message_starting_a_membership")}{" "}
				<strong>Distrito El Globo.</strong>
			</h3>

			<div className={styles.description}>
				<p className={styles.text}>
					{isACorporateSubscription
						? t(
								"message_starting_a_corporate_membership_description_1"
							)
						: t("message_starting_a_membership_description_1")}
				</p>

				<p className={styles.text}>
					{t("message_starting_a_membership_description_2")}
				</p>
			</div>

			<MembershipFormFooter
				backButtonHidden
				nextButtonTest={t("start_applying")}
				onNextClick={() =>
					navigate(AppRoutes.membershipFormStep1_details, {
						replace: true,
					})
				}
			/>
		</div>
	)
}

export default React.memo(StartApplying)
