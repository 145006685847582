import { IGetMyBookingsResponseCli } from "@interfaces/IGetMyBookingsResponseCli.ts"

type BookingStatus = NonNullable<IGetMyBookingsResponseCli[0]["status"]>

export const AppBookingStatus: Record<BookingStatus, BookingStatus> = {
	EXPIRED: "EXPIRED",
	CANCELED: "CANCELED",
	APPROVED: "APPROVED",
	PENDING: "PENDING",
}
