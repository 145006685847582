import * as React from "react"
import styles from "./Switches.module.scss"

import { usePlanData } from "@hooks/usePlanData.tsx"

import DsSwitch from "@components/dataEntry/DsSwitch/DsSwitch.tsx"
import useResponsiveToolCtx from "@contexts/hooks/useResponsiveToolCtx.tsx"

interface ISwitchesProps {
	className?: string
	defaultSubscription?: string
	defaultBillingCycle?: string
	onSubscriptionChange?: (value: string) => void
	onBillingCycleChange?: (value: string) => void
	desktopPlanButtonWidth?: React.CSSProperties["minWidth"]
	desktopSubscriptionButtonWidth?: React.CSSProperties["minWidth"]
}

const Switches: React.FC<ISwitchesProps> = (props) => {
	const { planData } = usePlanData()
	const { isDesktop } = useResponsiveToolCtx()

	const subscriptionContainerRef = React.useRef<HTMLDivElement>(null)
	const billingCycleContainerRef = React.useRef<HTMLDivElement>(null)

	const [
		subscriptionContainerOverflowing,
		setSubscriptionContainerOverflowing,
	] = React.useState(false)

	const [
		billingCycleContainerOverflowing,
		setBillingCycleContainerOverflowing,
	] = React.useState(false)

	const desktopSubscriptionButtonStyles: React.CSSProperties | undefined =
		isDesktop
			? {
					minWidth: props?.desktopSubscriptionButtonWidth,
					textAlign: "center",
				}
			: undefined

	const desktopPaymentButtonStyles: React.CSSProperties | undefined =
		isDesktop
			? { minWidth: props?.desktopPlanButtonWidth, textAlign: "center" }
			: undefined

	// IMPROVEMENT: improve the shadow effect to appear only in the side in witch the container is overflowing
	React.useEffect(() => {
		if (!subscriptionContainerRef.current) return

		const resizeObserver = new ResizeObserver(() => {
			setSubscriptionContainerOverflowing(
				subscriptionContainerRef.current!.scrollWidth >
					subscriptionContainerRef.current!.clientWidth
			)
		})

		resizeObserver.observe(subscriptionContainerRef.current!)

		return () => {
			resizeObserver.disconnect()
		}
	}, [])

	React.useEffect(() => {
		if (!billingCycleContainerRef.current) return

		const resizeObserver = new ResizeObserver(() => {
			setBillingCycleContainerOverflowing(
				billingCycleContainerRef.current!.scrollWidth >
					billingCycleContainerRef.current!.clientWidth
			)
		})

		resizeObserver.observe(billingCycleContainerRef.current!)

		return () => resizeObserver.disconnect()
	}, [])

	return (
		<div className={`${styles.switches} ${props.className || ""}`}>
			<div
				className={`${styles.sides_shadow_container} ${subscriptionContainerOverflowing ? styles.right_shadow : ""}`}
			>
				<div
					ref={subscriptionContainerRef}
					className={styles.scrollable_container}
				>
					<DsSwitch
						className={styles.switch}
						defaultValue={props.defaultSubscription}
						buttonStyle="solid"
						onChange={props?.onSubscriptionChange}
						options={planData?.plans?.[0]?.subscriptions?.map(
							({ id, name }) => ({
								value: id,
								label: name,
								style: desktopSubscriptionButtonStyles,
							})
						)}
					/>
				</div>
			</div>

			<div
				className={`${styles.sides_shadow_container} ${billingCycleContainerOverflowing ? styles.right_shadow : ""}`}
			>
				<div
					ref={billingCycleContainerRef}
					className={styles.scrollable_container}
				>
					<DsSwitch
						className={styles.switch}
						defaultValue={props?.defaultBillingCycle}
						buttonStyle="outline"
						onChange={props?.onBillingCycleChange}
						options={planData?.plans?.[0]?.subscriptions?.[0]?.billingCycle?.map(
							({ id, name }) => ({
								value: id,
								label: name,
								style: desktopPaymentButtonStyles,
							})
						)}
					/>
				</div>
			</div>
		</div>
	)
}

export default React.memo(Switches)
