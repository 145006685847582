import * as React from "react"
import styles from "./DsPlanPrice.module.scss"

import { useTranslation } from "react-i18next"

import AppText from "@components/general/AppText/AppText.tsx"
import DsButton from "@components/general/DsButton/DsButton.tsx"
import AppNumber from "@components/general/AppNumber/AppNumber.tsx"

interface IProps {
	className?: string
	frequency?: string
	currency?: string
	price?: number
	description?: string
	onClick?: () => void
	selected: boolean
}

const DsPlanPrice: React.FC<IProps> = (props) => {
	const { t } = useTranslation()

	const highLightedClass = props.selected ? styles.highLighted : ""

	return (
		<div className={`${styles.ds_plan_price} ${props.className || ""}`}>
			<div className={`${styles.plan_price_content} ${highLightedClass}`}>
				<AppText tag="h3" className={styles.period}>
					{props.frequency || " "}
				</AppText>

				<div className={styles.price}>
					<AppText tag={"label"}>{props?.currency}</AppText>
					<AppNumber tag={"h1"}>{props?.price}</AppNumber>
				</div>

				{props?.description && (
					<AppText tag="p" className={styles.program}>
						{props.description}
					</AppText>
				)}

				<DsButton
					className={styles.select_plan_button}
					onClick={props.onClick}
				>
					{t("select_plan")}
				</DsButton>
			</div>
		</div>
	)
}

export default React.memo(DsPlanPrice)
