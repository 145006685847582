import * as React from "react"
import styles from "./DownloadApp.module.scss"

import { useTranslation } from "react-i18next"
import { AppLanguages } from "@constants/AppLanguages.ts"
import { ReactComponent as HeadlineEs } from "@assets/svg/membership-headline-es.svg"
import { ReactComponent as HeadlineEn } from "@assets/svg/membership-headline-en.svg"

import AppImage from "@components/others/AppImage/AppImage.tsx"
import appleStore from "@assets/png/apple-store-88x34.png"
import googlePlay from "@assets/png/google-play-88x34.png"
import bgImageDesktop from "@assets/jpg/ad-bg-629.354.jpg"
import circleBgMobile from "@assets/png/circle-bg-247x247.png"
import DsLogoSDistrict from "@components/others/DsLogoSDistrict/DsLogoSDistrict.tsx"
import DsLogoBlancoElGlobo from "@components/others/DsLogoBlancoElGlobo/DsLogoBlancoElGlobo.tsx"
import celPhoneImageDesktop from "@assets/png/google-pixel-137x274.png"
import useResponsiveToolCtx from "@contexts/hooks/useResponsiveToolCtx.tsx"

interface IProps {
	className?: string
}

const DownloadApp: React.FC<IProps> = (props) => {
	const { t, i18n } = useTranslation()
	const { isDesktop, isMobile } = useResponsiveToolCtx()

	const isInEnglish = i18n.language === AppLanguages.ENGLISH

	return (
		<div
			className={`${styles.download_app} ${props.className || ""}`}
			style={{
				backgroundImage: `url(${bgImageDesktop})`,
			}}
		>
			<div className={styles.left_side_container}>
				<div className={styles.header}>
					<div className={styles.logos}>
						<DsLogoSDistrict
							width={isDesktop ? 61 : 91}
							height={isDesktop ? 54 : 80}
						/>

						<DsLogoBlancoElGlobo
							type="vertical"
							width={isDesktop ? 48 : 72}
							height={isDesktop ? 54 : 80}
						/>
					</div>

					{isInEnglish ? (
						<HeadlineEn className={styles.headline} />
					) : (
						<HeadlineEs className={styles.headline} />
					)}
				</div>

				{isMobile && (
					<div className={styles.content}>
						<AppImage
							rootClassName={styles.circle_img}
							src={circleBgMobile}
						/>

						<AppImage
							rootClassName={styles.cel_phone_img}
							src={celPhoneImageDesktop}
						/>
					</div>
				)}

				<div className={styles.bottom}>
					<p className={styles.text}>
						{t("message_mobile_app_promotion")}
					</p>

					<div className={styles.stores}>
						<AppImage src={appleStore} width={88} />
						<AppImage src={googlePlay} width={88} />
					</div>
				</div>
			</div>

			{isDesktop && (
				<div className={styles.right_side_container}>
					<div className={styles.right_side_content}>
						<AppImage
							rootClassName={styles.circle_img}
							src={circleBgMobile}
						/>
						<AppImage
							rootClassName={styles.cel_phone_img}
							src={celPhoneImageDesktop}
						/>
					</div>
				</div>
			)}
		</div>
	)
}

export default React.memo(DownloadApp)
