import * as React from "react"

import bookService from "@services/bookService/bookService.ts"

interface IOutput {
	spaceAvailableDays: string[]
	fetchSpaceAvailableDays: (placeId: string) => void
}

export const useSpaceAvailableDaysData = (): IOutput => {
	const [spaceAvailableDays, setSpaceAvailableDays] = React.useState<
		IOutput["spaceAvailableDays"]
	>([])

	const fetchSpaceAvailableDays = React.useCallback((place_id: string) => {
		bookService
			.getSpaceAvailableDays({ place_id })
			.then((data) => {
				if (data?.availableDays?.length) {
					setSpaceAvailableDays(data.availableDays)
				}
			})
			.catch(({ message }) => {
				console.error(
					message || "Something went wrong fetching available days"
				)
			})
	}, [])

	return {
		spaceAvailableDays,
		fetchSpaceAvailableDays,
	}
}
