import * as React from "react"
import styles from "./MembershipPlans.module.scss"

import { usePlanData } from "@hooks/usePlanData.tsx"
import { useTranslation } from "react-i18next"

import DsPlanTable, {
	IDsPlanTableProps,
} from "@components/dataDisplay/DsPlanTable/DsPlanTable.tsx"

interface IProps {
	className?: string
}

const MembershipPlans: React.FC<IProps> = (props) => {
	const { t } = useTranslation()
	const { planData } = usePlanData({ fetchOnMount: true })

	const columns = React.useMemo(() => {
		if (!planData?.plans?.length) return []

		return planData?.plans.map<IDsPlanTableProps["columns"][0]>(
			({ name }) => ({
				planName: name,
				planType: "PASS",
			})
		)
	}, [planData])

	const rows = React.useMemo(() => {
		if (!planData?.plans?.length) return []

		return planData?.plans.reduce<IDsPlanTableProps["rows"]>(
			(acc, plan) => {
				plan.benefits.forEach(({ name, enabled }, idx) => {
					if (acc[idx]) {
						acc[idx].benefitsEnabled.push(enabled)
					} else {
						acc.push({
							benefitName: name,
							benefitsEnabled: [enabled],
						})
					}
				})

				return acc
			},
			[]
		)
	}, [planData])

	return (
		<div className={`${styles.membership_plans} ${props.className || ""}`}>
			<h1 className={styles.heading}>
				{t("select_your_membership_plan")}:
			</h1>

			{planData && (
				<DsPlanTable columns={columns || []} rows={rows || []} />
			)}
		</div>
	)
}

export default React.memo(MembershipPlans)
