import { AppLanguages } from "@constants/AppLanguages.ts"
import { IApiResponse } from "@interfaces/generic/IApiResponse.ts"
import { AppFunctions } from "@others/AppFunctions.ts"
import { IGetSpaceAvailableHoursResponseCli } from "@interfaces/IGetSpaceAvailableHoursResponseCli.ts"
import { IGetSpaceAvailableHoursResponseApi } from "@interfaces/IGetSpaceAvailableHoursResponseApi.ts"

import I18n from "../locales/i18n.ts"

export const GetSpaceAvailableHoursAdapter = (
	response: IApiResponse<IGetSpaceAvailableHoursResponseApi>
): IGetSpaceAvailableHoursResponseCli => {
	const availableTimeBlocks = response?.data?.available_time_blocks?.reduce<
		NonNullable<IGetSpaceAvailableHoursResponseCli["availableTimeBlocks"]>
	>((acc, item) => {
		const endBlockTime = `${response?.data.date}T${item?.split("-")[1]}`

		if (
			!AppFunctions.checkIfIsInTheFuture({
				date: endBlockTime,
			})
		) {
			return acc
		}

		const separatorCharacter =
			I18n.language === AppLanguages.ENGLISH ? " to " : " a "

		const label = item?.replace("-", separatorCharacter)

		if (item) {
			acc.push({ label, value: item })
		}

		return acc
	}, [])

	return {
		...response?.data,
		placeId: response.data.place_id,
		availableTimeBlocks,
	}
}
