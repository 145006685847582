export const MEMBERSHIP_STEP1_TABS = {
	details: "details",
	address: "address",
	kindOfMembership: "kind-of-membership",
}

export const MEMBERSHIP_STEP2_TABS = {
	personalDetails: "personal-details",
	job: "job",
}

export const MEMBERSHIP_STEP3_TABS = {
	presentation: "presentation",
	digitalProfiles: "digitalProfiles",
	references: "references",
	yourGroup: "your-group",
}

export const MEMBERSHIP_STEP4_TABS = {
	globoRules: "globo-rules",
}

export const MEMBERSHIP_STEP5_TABS = {
	sendMembership: "send-membership",
}
