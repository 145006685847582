import * as React from "react"

import { IGetMembershipPlansDataResponseCli } from "@interfaces/IGetMembershipPlansDataResponseCli.ts"

interface ICtx {
	planData: IGetMembershipPlansDataResponseCli | undefined
	setPlanData: React.Dispatch<
		React.SetStateAction<IGetMembershipPlansDataResponseCli | undefined>
	>
}

const InitialCtxState: ICtx = {
	planData: undefined,
	setPlanData: () => {},
}

export const Context = React.createContext<ICtx>(InitialCtxState)

const CtxProvider: React.FC<{
	children: React.ReactNode
}> = ({ children }) => {
	const [planData, setPlanData] = React.useState<ICtx["planData"]>(
		InitialCtxState.planData
	)

	const contextValue = React.useMemo(
		() => ({
			planData,
			setPlanData,
		}),
		[planData, setPlanData]
	)

	return <Context.Provider value={contextValue}>{children}</Context.Provider>
}

export const PlanDataCtxProvider = CtxProvider
