import { IApiResponse } from "@interfaces/generic/IApiResponse.ts"
import { AppFunctions } from "@others/AppFunctions.ts"
import { IPostCancelBookingResponseApi } from "@interfaces/IPostCancelBookingResponseApi.ts"
import { IPostCancelBookingResponseCli } from "@interfaces/IPostCancelBookingResponseCli.ts"

export const PostCancelBookingAdapterAdapter = (
	response: IApiResponse<IPostCancelBookingResponseApi>
): IPostCancelBookingResponseCli => {
	return {
		booking: {
			...response?.data?.booking,
			status: AppFunctions.getBookingStatusCli(
				response?.data?.booking?.status
			),
			category: AppFunctions.getBookingCategoriesCli(
				response?.data?.booking?.category
			),
		},
	}
}
