import * as React from "react"
import styles from "./BookingCard.module.scss"

import { QrcodeOutlined } from "@ant-design/icons"
import { useTranslation } from "react-i18next"
import { AppLocalStorageKeys } from "@constants/AppLocalStorageKeys.ts"

import DsReservationsStatus, {
	IDsReservationsStatusProps,
} from "@components/dataDisplay/DsReservationsStatus/DsReservationsStatus.tsx"

import DvBookingCategoryIcons, {
	IDvBookingCategoryIconsProps,
} from "@components/others/DvBookingCategoryIcons/DvBookingCategoryIcons.tsx"

import AppText from "@components/general/AppText/AppText.tsx"
import DsButton from "@components/general/DsButton/DsButton.tsx"
import DvCodeAndGuests from "@components/dataDisplay/DvCodeAndGuests/DvCodeAndGuests.tsx"
import DvBookingCardMenu from "@components/navigation/DvBookingCardMenu/DvBookingCardMenu.tsx"
import DvStartEndDateTime from "@components/dataDisplay/DvStartEndDateTime/DvStartEndDateTime.tsx"

interface IData {
	id?: string
	title?: string
	category?: IDvBookingCategoryIconsProps["bookingCategory"]
	code?: string
	guests?: number
	status?: IDsReservationsStatusProps["status"]
	qrCode?: string
	startDate?: string
	endDate?: string
}

export interface IBookingCardProps {
	className?: string
	data: IData
	timeToHighlight?: number
	onQrCodeClick?: (data: IData) => void
	onCancelClick?: (id: IData["id"]) => void
	onModifyClick?: (id: IData["id"]) => void
}

const BookingCard: React.FC<IBookingCardProps> = (props) => {
	const { t } = useTranslation()

	const [isHighlighted, setIsHighlighted] = React.useState(false)

	// const handleModifyClick = React.useCallback(() => {
	// 	props.onModifyClick?.(props.data.id)
	// }, [props.data.id, props.onModifyClick])

	const handleCancelClick = React.useCallback(() => {
		props.onCancelClick?.(props.data.id)
	}, [props.data.id, props.onCancelClick])

	const handleQrCodeClick = React.useCallback(() => {
		if (props.data.qrCode) props.onQrCodeClick?.(props.data)
	}, [props.data.qrCode, props.onQrCodeClick])

	const qrCodeButtonDisabled = React.useMemo(
		() => props.data.status !== "APPROVED" || !props.data.qrCode,
		[props.data.status, props.data.qrCode]
	)

	// const isModifiable = React.useMemo(
	// 	() => props.data.status && ["PENDING"].includes(props.data.status),
	// 	[props.data.status]
	// )

	const isCancelable = React.useMemo(
		() =>
			props.data.status &&
			["APPROVED", "PENDING"].includes(props.data.status),
		[props.data.status]
	)

	React.useEffect(() => {
		let highlightTimeout: NodeJS.Timeout

		if (
			props.data.status === "CANCELED" &&
			localStorage.getItem(AppLocalStorageKeys.CHANGED_BOOKING) ===
				props.data.id
		) {
			localStorage.removeItem(AppLocalStorageKeys.CHANGED_BOOKING)

			setIsHighlighted(true)

			highlightTimeout = setTimeout(() => {
				setIsHighlighted(false)
			}, props.timeToHighlight || 3000)
		}

		return () => {
			clearTimeout(highlightTimeout)
			setIsHighlighted(false)
		}
	}, [props.data.status])

	return (
		<div
			className={`${styles.booking_card} ${props.className || ""}`}
			style={{ backgroundColor: isHighlighted ? "#303030" : undefined }}
		>
			<DvBookingCardMenu
				items={[
					// {
					// 	titleKey: "modify_booking",
					// 	disabled: !isModifiable,
					// 	onClick: handleModifyClick,
					// },
					{
						titleKey: "cancel_booking",
						disabled: !isCancelable,
						onClick: handleCancelClick,
					},
				]}
			/>

			<div className={styles.top}>
				<DvBookingCategoryIcons
					className={styles.booking_icon}
					bookingCategory={props.data.category}
				/>

				<div className={styles.fix_data}>
					<AppText className={styles.header} ellipsis>
						{props?.data?.title}
					</AppText>

					<div className={styles.fix_data_main}>
						<DvStartEndDateTime
							startDate={props?.data?.startDate}
							endDate={props?.data?.endDate}
						/>

						<DvCodeAndGuests
							code={props?.data?.qrCode}
							guests={props?.data?.guests}
						/>
					</div>
				</div>
			</div>

			<div className={styles.footer}>
				<DsReservationsStatus status={props?.data?.status} />

				<DsButton
					className={styles.button}
					onClick={handleQrCodeClick}
					disabled={qrCodeButtonDisabled}
					type="secondary"
					icon={
						<QrcodeOutlined
							style={{ marginRight: "4px", color: "#BF7D2A" }}
						/>
					}
				>
					{t("view_qr")}
				</DsButton>
			</div>
		</div>
	)
}

export default React.memo(BookingCard)
