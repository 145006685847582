import * as React from "react"
import * as Antd from "antd"
import styles from "./DvNavMenu.module.scss"

import { useTranslation } from "react-i18next"
import { AppExternalLinks } from "@constants/AppExternalLinks.ts"

import DvLanguageSelector from "@components/navigation/DvLanguageSelector/DvLanguageSelector.tsx"
import DvDropdown from "@components/navigation/DvNavMenu/DvNavMenuDropdown/DvNavMenuDropdown.tsx"

interface IProps {
	className?: string
}

const DvNavMenu: React.FC<IProps> = (props) => {
	const { t } = useTranslation()
	const externalLinks = AppExternalLinks()

	const items = [
		{
			key: 1,
			label: <a href={externalLinks.aboutUs}>{t("about_us")}</a>,
		},
		{
			key: 2,
			label: (
				<DvDropdown
					items={[
						{
							key: "1",
							label: (
								<a href={externalLinks.workspaces}>
									{t("coworking")}
								</a>
							),
						},
						{
							key: "2",
							label: (
								<a href={externalLinks.hotelBoutique}>
									{t("boutique_hotel")}
								</a>
							),
						},
						{
							key: "3",
							label: (
								<a href={externalLinks.gastronomy}>
									{t("gastronomy")}
								</a>
							),
						},
						{
							key: "4",
							label: <a href={externalLinks.art}>{t("art")}</a>,
						},
						{
							key: "5",
							label: (
								<a href={externalLinks.theProject}>
									{t("s-district")}
								</a>
							),
						},
					]}
				>
					{t("the_project")}
				</DvDropdown>
			),
		},
		{
			key: 3,
			label: <a href={externalLinks.invest}>{t("invest")}</a>,
		},
		{
			key: 4,
			label: <a href={externalLinks.press}>{t("press")}</a>,
		},
		{
			key: 5,
			label: <a href={externalLinks.impact}>{t("impact")}</a>,
		},
		{
			key: 6,
			label: <a href={externalLinks.contact}>{t("contact")}</a>,
		},
		{
			key: 7,
			label: <a href={externalLinks.membership}>{t("membership")}</a>,
		},
		{
			className: styles.language_selector_item,
			key: 9,
			label: <DvLanguageSelector />,
		},
	]

	return (
		<Antd.Menu
			className={`${styles.dv_nav_menu} ${props.className || ""}`}
			rootClassName={styles.popup_menu}
			theme="dark"
			mode="horizontal"
			defaultSelectedKeys={["2"]}
			items={items}
			style={{
				flex: "1 1 auto",
				overflow: "visible",
				minWidth: 0,
			}}
		/>
	)
}

export default React.memo(DvNavMenu)
