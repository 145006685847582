import { IApiResponse } from "@interfaces/generic/IApiResponse.ts"
import { IGetUserLoggedResponseApi } from "@interfaces/IGetUserLoggedResponseApi.ts"
import { IGetUserLoggedResponseCli } from "@interfaces/IGetUserLoggedResponseCli.ts"
import { AppFunctions } from "@others/AppFunctions.ts"

export const GetUserLoggedAdapter = (
	response: IApiResponse<IGetUserLoggedResponseApi>
): IGetUserLoggedResponseCli => {
	return {
		...response.data,
		membershipStatus: AppFunctions.getMembershipStatusCli(
			response.data.membershipStatus
		),
	}
}
