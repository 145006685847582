import { IApiResponse } from "@interfaces/generic/IApiResponse.ts"
import { IPostPayMembershipResponseCli } from "@interfaces/IPostPayMembershipResponseCli.ts"
import { IPostPayMembershipResponseApi } from "@interfaces/IPostPayMembershipResponseApi.ts"

export const PostPayMembershipAdapter = (
	response: IApiResponse<IPostPayMembershipResponseApi>
): IPostPayMembershipResponseCli => {
	const { payment_ref, payment_gateway, checkout_url } = response.data
	return {
		checkoutUrl: checkout_url,
		paymentRef: payment_ref,
		paymentGateway: payment_gateway,
	}
}
