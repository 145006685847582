import * as React from "react"
import styles from "./SuccessBookingSpaceMessage.module.scss"

import { useTranslation } from "react-i18next"

interface IProps {
	className?: string
	goToMyBookings?: () => void
}

const SuccessBookingSpaceMessage: React.FC<IProps> = (props) => {
	const { t } = useTranslation()

	return (
		<div
			className={`${styles.success_booking_space_message} ${props.className || ""}`}
		>
			<p>
				{t("message_reservation_made_successfully")}{" "}
				<strong onClick={props.goToMyBookings}>
					{t("my_Bookings")}
				</strong>
				.
			</p>
		</div>
	)
}

export default React.memo(SuccessBookingSpaceMessage)
