import * as React from "react"

import { useTranslation } from "react-i18next"
import { IGetMembershipPlansDataResponseApi } from "@interfaces/IGetMembershipPlansDataResponseApi.ts"

import publicApi from "@others/public.ts"
import usePlanDataCtx from "@contexts/hooks/usePlanDataCtx.tsx"

// TODO: Refine the info of the texts of the benefits of the plans so there is a mix between spanish and english.
// TODO: Update the text of the info of the plans.
const jsonName = "plan-data-20250219.json"

interface IUsePlanDataArgs {
	fetchOnMount?: boolean
}

export const usePlanData = (args?: IUsePlanDataArgs) => {
	const { i18n } = useTranslation()
	const { planData, setPlanData } = usePlanDataCtx()
	const [loading, setLoading] = React.useState<boolean>(true)

	const fetchPlanData = React.useCallback(() => {
		setLoading(true)

		publicApi
			.get<IGetMembershipPlansDataResponseApi>(
				`json/${i18n.language}/${jsonName}`
			)
			.then((response) => {
				setPlanData(response.data)
			})
			.finally(() => {
				setLoading(false)
			})
	}, [i18n.language])

	React.useEffect(() => {
		if (args?.fetchOnMount) fetchPlanData()
	}, [i18n.language])

	return {
		planData,
		loading,
		fetchPlanData,
	}
}
