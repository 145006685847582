import * as React from "react"
import * as Antd from "antd"

import { AppFunctions } from "@others/AppFunctions.ts"

const fallBackImageUrl =
	AppFunctions.getCurrentDomain() + "/assets/images/fallback-image.svg"

const AppImage: React.FC<Antd.ImageProps> = ({ ...defaultProps }) => {
	return (
		<Antd.Image
			preview={false}
			loading={"lazy"}
			fallback={fallBackImageUrl}
			placeholder={
				<Antd.Image
					src={fallBackImageUrl}
					preview={false}
					{...defaultProps}
				/>
			}
			{...defaultProps}
		/>
	)
}

export default React.memo(AppImage)
