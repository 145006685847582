import * as React from "react"
import styles from "./UserInfo.module.scss"

import AppText from "@components/general/AppText/AppText.tsx"
import useAuthCtx from "@contexts/hooks/useAuthCtx.tsx"
import DvUserAvatar from "@components/dataDisplay/DvUserAvatar/DvUserAvatar.tsx"

interface IProps {
	className?: string
	titleCentered?: boolean
}

const UserInfo: React.FC<IProps> = (props) => {
	const { loggedUser } = useAuthCtx()

	const userName = `${loggedUser?.name ? loggedUser.name : ""} ${loggedUser?.lastName ? loggedUser.lastName : ""}`

	return (
		<div className={`${styles.user_info} ${props.className || ""}`}>
			<DvUserAvatar className={`${styles.avatar} avatar`} size="large" />

			<div className={`${styles.data} data`}>
				{(loggedUser?.name || loggedUser?.lastName) &&
					(!props?.titleCentered ? (
						<AppText className={styles.name} ellipsis>
							{userName}
						</AppText>
					) : (
						<h1 className={styles.name}>{userName}</h1>
					))}

				{(loggedUser?.plan || loggedUser?.subscription) && (
					<div className={`${styles.membership} membership`}>
						<AppText tag="h3" capitalizeFirstChar>
							{loggedUser.plan}
						</AppText>

						<AppText capitalizeFirstChar>
							{loggedUser.subscription}
						</AppText>
					</div>
				)}
			</div>
		</div>
	)
}

export default React.memo(UserInfo)
