import api from "@others/api.ts"

import { Endpoints } from "@constants/Endpoints.tsx"
import { IApiResponse } from "@interfaces/generic/IApiResponse.ts"
import { AppFunctions } from "@others/AppFunctions.ts"
import { PutUserAdapter } from "@adapters/IPutUserAdapter.ts"
import { IPutUserResponseApi } from "@interfaces/IPutUserResponseApi.ts"
import { IPutUserResponseCli } from "@interfaces/IPutUserResponseCli.ts"
import { GetUserQrCodeAdapter } from "@adapters/GetUserQrCodeAdapter.ts"
import { GetUserLoggedAdapter } from "@adapters/GetUserLoggedAdapter.ts"
import { IPutUserResponseBodyApi } from "@interfaces/IPutUserResponseBodyApi.ts"
import { IGetUserLoggedResponseApi } from "@interfaces/IGetUserLoggedResponseApi.ts"
import { IGetUserLoggedResponseCli } from "@interfaces/IGetUserLoggedResponseCli.ts"
import { IGetUserQrCodeResponseApi } from "@interfaces/IGetUserQrCodeResponseApi.ts"
import { IGetUserQrCodeResponseCli } from "@interfaces/IGetUserQrCodeResponseCli.ts"

const getUserLogged = async (): Promise<IGetUserLoggedResponseCli> => {
	return api
		.get<IApiResponse<IGetUserLoggedResponseApi>>(Endpoints.userLogged)
		.then(({ data }) => GetUserLoggedAdapter(data))
		.catch((error) => {
			throw AppFunctions.getErrorApiDetail(error)
		})
}

const updateUserData = async (
	body: IPutUserResponseBodyApi
): Promise<IPutUserResponseCli> => {
	return api
		.put<IApiResponse<IPutUserResponseApi>>(Endpoints.user, body)
		.then(({ data }) => PutUserAdapter(data))
		.catch((error) => {
			throw AppFunctions.getErrorApiDetail(error)
		})
}

const getUserQrCode = async (): Promise<IGetUserQrCodeResponseCli> => {
	return api
		.get<IApiResponse<IGetUserQrCodeResponseApi>>(Endpoints.userQrCode)
		.then(({ data }) => GetUserQrCodeAdapter(data))
		.catch((error) => {
			throw AppFunctions.getErrorApiDetail(error)
		})
}

export default { updateUserData, getUserLogged, getUserQrCode }
