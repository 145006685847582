import axios, { AxiosError } from "axios"
import axiosRetry from "axios-retry"
import i18next from "i18next"
import authService from "@services/authService/authService.ts"

import { AppFunctions } from "@others/AppFunctions.ts"
import { AppConfig } from "@constants/AppConfig.ts"
import { AppLanguages } from "@constants/AppLanguages.ts"
import { AppLocalStorageKeys } from "@constants/AppLocalStorageKeys.ts"
import { Endpoints } from "@constants/Endpoints.tsx"
import { AppConsoleMessages } from "@constants/AppConsoleMessages.ts"

let refreshIntervalTimer: NodeJS.Timeout

const api = axios.create({
	baseURL: AppConfig.API_BASE_URL,
	timeout: 100000,
	headers: {
		"Content-Type": "application/json",
	},
})

const handleRefreshSession = async () => {
	const hasRefreshToken = !!localStorage.getItem(
		AppLocalStorageKeys.REFRESH_TOKEN
	)

	if (hasRefreshToken && AppFunctions.isTokenExpiringSoon()) {
		await authService.refreshToken()
	}
}

api.interceptors.request.use(
	async (config) => {
		const token = localStorage.getItem(AppLocalStorageKeys.TOKEN)
		const lang = i18next.language || AppLanguages.DEFAULT
		const requestUrl = config.url as string

		if (
			requestUrl !== Endpoints.login &&
			requestUrl !== Endpoints.refreshToken
		) {
			await handleRefreshSession()
		}

		if (token) {
			config.headers.Authorization = `Bearer ${token}`
		}

		if (config.params) {
			config.params["lang"] = lang
		} else {
			config.params = { lang }
		}

		return config
	},
	(error) => {
		return Promise.reject(error)
	}
)

api.interceptors.response.use(
	(response) => {
		return response
	},
	(error: AxiosError) => {
		const status = error.response?.status

		if (status === 401 && !AppFunctions.inTestEnvironment()) {
			console.error(AppConsoleMessages.error.error_401)
			AppFunctions.clearLoginDataFromLocalStorage()
			AppFunctions.goToLogin()
		}

		if (status === 500) {
			console.error(AppConsoleMessages.error.error_500)
		}

		return Promise.reject(error)
	}
)

// Retry failed requests up to 3 times
axiosRetry(api, { retries: 3, retryDelay: axiosRetry.exponentialDelay })

// Background token refresh logic ( every 1 minute )
const startTokenRefreshInterval = () => {
	clearInterval(refreshIntervalTimer)
	refreshIntervalTimer = setInterval(handleRefreshSession, 60000)
}

startTokenRefreshInterval()

export default api
