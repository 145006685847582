import * as React from "react"

import { AppIndexedDBKeys } from "@constants/AppIndexedDBKeys.ts"
import { IGetSpaceAllPlacesResponseCli } from "@interfaces/IGetSpaceAllPlacesResponseCli.ts"

import IndexedDB from "@services/indexedDBService.ts"

interface ICtx {
	spaceAllPlaces: IGetSpaceAllPlacesResponseCli
	setSpaceAllPlaces: React.Dispatch<
		React.SetStateAction<IGetSpaceAllPlacesResponseCli>
	>
}

const InitialCtxState: ICtx = {
	spaceAllPlaces: [],
	setSpaceAllPlaces: () => {},
}

let isAllPlacesInitialState = true

export const Context = React.createContext<ICtx>(InitialCtxState)

const CtxProvider: React.FC<{
	children: React.ReactNode
}> = ({ children }) => {
	const [spaceAllPlaces, setSpaceAllPlacesState] = React.useState<
		ICtx["spaceAllPlaces"]
	>(InitialCtxState.spaceAllPlaces)

	React.useEffect(() => {
		if (isAllPlacesInitialState) return
		IndexedDB.updateKey(AppIndexedDBKeys.SPACE_ALL_PLACES, spaceAllPlaces)
	}, [spaceAllPlaces])

	React.useEffect(() => {
		IndexedDB.getKey<ICtx["spaceAllPlaces"]>(
			AppIndexedDBKeys.SPACE_ALL_PLACES
		).then((data) => {
			if (!data) return
			setSpaceAllPlacesState(data)
		})
	}, [])

	const setSpaceAllPlaces: ICtx["setSpaceAllPlaces"] = React.useCallback(
		(spaceAllPlaces) => {
			isAllPlacesInitialState = false
			setSpaceAllPlacesState(spaceAllPlaces)
		},
		[]
	)

	const contextValue = React.useMemo(
		() => ({
			spaceAllPlaces,
			setSpaceAllPlaces,
		}),
		[spaceAllPlaces, setSpaceAllPlaces]
	)

	return <Context.Provider value={contextValue}>{children}</Context.Provider>
}

export const SpaceAllPlacesCtxProvider = CtxProvider
