import * as React from "react"
import styles from "./BookSpacePlace.module.scss"

import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { AppRoutes } from "@constants/AppRoutes.ts"
import { useSpaceAllPlacesData } from "@hooks/useSpaceAllPlacesData.ts"

import DsStackedCard, {
	IDsStackedCardProps,
} from "@components/dataDisplay/DsStackedCard/DsStackedCard.tsx"

interface IProps {
	className?: string
}

const BookSpacePlace: React.FC<IProps> = (props) => {
	const navigate = useNavigate()

	const { t } = useTranslation()
	const { spaceAllPlaces, fetchSpaceAllPlaces } = useSpaceAllPlacesData()

	const cardClickHandler = React.useCallback(
		(spaceData: IDsStackedCardProps["data"], capacity?: number) => {
			navigate(AppRoutes.userProfileBookSpace_schedule, {
				state: { spaceData, capacity },
			})
		},
		[]
	)

	React.useEffect(() => {
		fetchSpaceAllPlaces()
	}, [])

	return (
		<div className={`${styles.book_space_place} ${props.className || ""}`}>
			<h1 className={styles.title}>{t("select_the_space_to_book")}</h1>

			{spaceAllPlaces?.map(({ id, image, capacity, name }) => (
				<DsStackedCard
					key={id}
					onClick={(cardData) => {
						cardClickHandler(cardData, capacity)
					}}
					data={{
						id,
						coverUrl: image,
						title: name,
						subtitle: capacity
							? `${t("capacity")}: ${capacity} ${capacity === 1 ? t("people") : t("peoples")}`
							: undefined,
					}}
				/>
			))}
		</div>
	)
}

export default React.memo(BookSpacePlace)
