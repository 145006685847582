import * as React from "react"
import styles from "./CancelConfirmationModalContent.module.scss"

import AppText from "@components/general/AppText/AppText.tsx"

interface IProps {
	className?: string
}

const CancelConfirmationModalContent: React.FC<IProps> = (props) => {
	return (
		<div
			className={`${styles.cancel_confirmation_modal_content} ${props.className || ""}`}
		>
			<AppText
				className={styles.title}
				textKey="message_booking_data_will_be_deleted"
			/>
			<AppText
				className={styles.subtitle}
				textKey="do_you_want_to_cancel_the_reservation"
			/>
		</div>
	)
}

export default React.memo(CancelConfirmationModalContent)
