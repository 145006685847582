import { IApiResponse } from "@interfaces/generic/IApiResponse.ts"
import { IGetPayMembershipSupportedCountriesResponseApi } from "@interfaces/IGetPayMembershipSupportedCountriesResponseApi.ts"
import { IGetPayMembershipSupportedCountriesResponseCli } from "@interfaces/IGetPayMembershipSupportedCountriesResponseCli.ts"

export const GetPayMembershipSupportedCountriesAdapter = ({
	data,
}: IApiResponse<IGetPayMembershipSupportedCountriesResponseApi>): IGetPayMembershipSupportedCountriesResponseCli => {
	const adaptedData =
		data?.reduce<IGetPayMembershipSupportedCountriesResponseCli>(
			(acc, { code, name }) => {
				if (!code || !name) return acc
				acc.push({ label: name, value: code })
				return acc
			},
			[]
		)

	return adaptedData.sort((a, b) => a.label.localeCompare(b.label))
}
