import * as React from "react"
import * as Antd from "antd"
import styles from "./DvCodeAndGuests.module.scss"

import { AppConfig } from "@constants/AppConfig.ts"
import { ReactComponent as PeopleIcon } from "@assets/svg/people-icon-24x24.svg"

import AppText from "@components/general/AppText/AppText.tsx"

interface IProps {
	className?: string
	code?: string
	guests?: number
}

const DvCodeAndGuests: React.FC<IProps> = (props) => {
	return (
		<div
			className={`${styles.dv_code_and_guests} ${props.className || ""}`}
		>
			<AppText className={styles.code} ellipsis>
				{`Code: ${props?.code || AppConfig.APP_NOT_VALUE}`}
			</AppText>

			<Antd.Divider type="vertical" className={styles.divider} />

			<div className={styles.people}>
				<PeopleIcon />
				<p className={styles.value}>{props?.guests}</p>
			</div>
		</div>
	)
}

export default React.memo(DvCodeAndGuests)
