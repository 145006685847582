import * as React from "react"
import dayjs from "dayjs"

import { useTranslation } from "react-i18next"
import { AppLanguages } from "@constants/AppLanguages.ts"

import {
	IGetUruguayDate,
	IFormatDate,
	ICheckIfIsToday,
} from "./useAppDateTime.d"

/**
 * This is a hook to handle date and time operations in the app
 */

export const useAppDateTime = () => {
	const { i18n } = useTranslation()

	const getUruguayDate = React.useCallback(
		({ date }: IGetUruguayDate) => dayjs(date).tz("America/Montevideo"),
		[]
	)

	const formatDate = React.useCallback(
		({ date, format, dayjsDate, zone }: IFormatDate) => {
			const dayjsDateSelected = dayjsDate ? dayjsDate : dayjs(date)

			const byZoneDate =
				zone === "Local"
					? dayjsDateSelected
					: dayjsDateSelected.tz("America/Montevideo")

			switch (format) {
				case "dddd":
					return byZoneDate.format("dddd")
				case "HH:mm":
					return byZoneDate.format("HH:mm")
				case "D [de] MMMM [de] YYYY":
					return i18n.language === AppLanguages.ENGLISH
						? byZoneDate.format("MMMM D, YYYY")
						: byZoneDate.format("D [de] MMMM [de] YYYY")
				case "DD MMM YYYY":
					if (dayjsDate) {
						return i18n.language === AppLanguages.ENGLISH
							? byZoneDate.format("MMM DD, YYYY")
							: byZoneDate.format("DD MMM YYYY")
					} else {
						return i18n.language === AppLanguages.ENGLISH
							? byZoneDate.format("MMM DD, YYYY")
							: byZoneDate.format("DD MMM YYYY")
					}

				case "DD/MM/YYYY":
					return i18n.language === AppLanguages.ENGLISH
						? byZoneDate.format("MM/DD/YYYY")
						: byZoneDate.format("DD/MM/YYYY")
				case "DD MMM":
					return i18n.language === AppLanguages.ENGLISH
						? byZoneDate.format("MMM DD")
						: byZoneDate.format("DD MMM")
			}
		},
		[i18n.language]
	)

	const checkIfIsToday = React.useCallback(
		({ date, zone }: ICheckIfIsToday) => {
			const byZoneDate =
				zone === "Local" ? dayjs() : dayjs().tz("America/Montevideo")

			return byZoneDate.isSame(date, "day")
		},
		[]
	)

	const inUruguayZone = dayjs.tz.guess() === "America/Montevideo"

	return { formatDate, checkIfIsToday, getUruguayDate, inUruguayZone }
}
