import * as React from "react"
import * as Antd from "antd"
import styles from "./MembershipGloboRulesForm.module.scss"

import { AppRoutes } from "@constants/AppRoutes.ts"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useMembershipApplyForm } from "@hooks/useMembershipApplyForm.tsx"
import { AppCorporateSubscriptions } from "@constants/AppCorporateSubscriptions.ts"
import { MemberShipLayoutConstants } from "@components/layout/MembershipLayout/MembershipLayout.constants.ts"

import DsButton from "@components/general/DsButton/DsButton.tsx"
import DvCheckbox from "@components/dataEntry/DvCheckbox/DvCheckbox.tsx"
import useAuthCtx from "@contexts/hooks/useAuthCtx.tsx"
import DvAntdForm from "@components/others/DvAntdForm/DvAntdForm.tsx"
import DvAntdFormItem from "@components/others/DvAntdFormItem/DvAntdFormItem.tsx"
import validationRules from "@others/validationRules.ts"
import MembershipFormFooter from "@components/layout/MembershipFormFooter/MembershipFormFooter.tsx"

export interface IMembershipGloboRulesFormFieldType {
	globoRulesAccepted?: boolean
	receivePromotions?: boolean
}

interface IProps {
	className?: string
}

const MembershipGloboRulesForm: React.FC<IProps> = (props) => {
	const navigate = useNavigate()
	const [form] = Antd.Form.useForm<IMembershipGloboRulesFormFieldType>()
	const { t } = useTranslation()
	const { loggedUser } = useAuthCtx()

	const initialValues: IMembershipGloboRulesFormFieldType = React.useMemo(
		() => ({
			globoRulesAccepted: false,
			receivePromotions: loggedUser?.receivePromotions ?? false,
		}),
		[loggedUser]
	)

	const { states, onChange } = useMembershipApplyForm(form, initialValues)

	const onFinish = async ({
		globoRulesAccepted,
		receivePromotions,
	}: IMembershipGloboRulesFormFieldType) => {
		await onChange("globoRulesAccepted", globoRulesAccepted)
		await onChange("receivePromotions", receivePromotions)

		navigate(AppRoutes.membershipFormStep5_sendMembership, {
			replace: true,
		})
	}

	const isACorporateSubscription = React.useMemo(
		() => AppCorporateSubscriptions.includes(states?.subscription || ""),
		[states]
	)

	return (
		<>
			<DvAntdForm<IMembershipGloboRulesFormFieldType>
				form={form}
				className={`${styles.membership_globo_rules_form} ${
					props.className || ""
				}`}
				initialValues={initialValues}
				onFinish={onFinish}
			>
				{/*TODO: update this test with the Globo Rules*/}
				<p className={styles.text}>
					Fuga qui facere velit et quia eos non sit. Aliquid
					laboriosam illo autem ut. Dolor vel cum voluptas est
					inventore ut quos modi. Fugit labore maxime ratione. Earum
					nemo provident quia quia quia aut et quaerat totam. A
					tempora exercitationem rerum ut et sed. Hic tempora
					voluptates totam. Voluptatem dolores vero quo quae. Velit
					sit cumque omnis aspernatur. In rerum est vitae consequatur
					magnam. Et eaque repellat et nostrum tempora sed facere.
					Consequatur sunt non aut doloremque suscipit ut aut
					accusamus sit. Natus labore ea rerum excepturi. Rem impedit
					praesentium ratione deserunt nisi quam eum aut aspernatur.
				</p>

				<p className={styles.text}>
					Fuga qui facere velit et quia eos non sit. Aliquid
					laboriosam illo autem ut. Dolor vel cum voluptas est
					inventore ut quos modi. Fugit labore maxime ratione. Earum
					nemo provident quia quia quia aut et quaerat totam. A
					tempora exercitationem rerum ut et sed. Hic tempora
					voluptates totam. Voluptatem dolores vero quo quae. Velit
					sit cumque omnis aspernatur. In rerum est vitae consequatur
					magnam. Et eaque repellat et nostrum tempora sed facere.
					Consequatur sunt non aut doloremque suscipit ut aut
					accusamus sit. Natus labore ea rerum excepturi. Rem impedit
					praesentium ratione deserunt nisi quam eum aut aspernatur.
				</p>

				<DvAntdFormItem<IMembershipGloboRulesFormFieldType>
					name="globoRulesAccepted"
					valuePropName="checked"
					rules={[
						validationRules.checkedRule(
							t("you_have_to_accept_the_rules")
						),
					]}
				>
					<DvCheckbox
						className={styles.checkbox}
						onChange={(e) =>
							onChange("globoRulesAccepted", e.target.checked)
						}
					>
						{t("message_accepting_globo_district_rules")}
					</DvCheckbox>
				</DvAntdFormItem>

				<DvAntdFormItem<IMembershipGloboRulesFormFieldType>
					name="receivePromotions"
					valuePropName="checked"
				>
					<DvCheckbox
						className={styles.checkbox}
						onChange={(e) =>
							onChange("receivePromotions", e.target.checked)
						}
					>
						{t("message_receive_notifications")}
					</DvCheckbox>
				</DvAntdFormItem>

				<DsButton
					id={MemberShipLayoutConstants.SUBMIT_FORM_BUTTON_ID}
					style={{ display: "none" }}
					htmlType="submit"
				/>
			</DvAntdForm>

			<MembershipFormFooter
				onBackClick={() =>
					navigate(
						isACorporateSubscription
							? AppRoutes.membershipFormStep3_your_group
							: AppRoutes.membershipFormStep3_references
					)
				}
				onNextClick={() => form.submit()}
			/>
		</>
	)
}

export default React.memo(MembershipGloboRulesForm)
