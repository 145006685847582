import * as React from "react"
import styles from "./ErrorBookingSpaceMessage.module.scss"

import { AppFunctions } from "@others/AppFunctions.ts"
import { AppBusinessInfo } from "@constants/AppBusinessInfo.ts"
import { useTranslation } from "react-i18next"

interface IProps {
	className?: string
}

const ErrorBookingSpaceMessage: React.FC<IProps> = (props) => {
	const { t } = useTranslation()

	const contactSupportTeam = React.useCallback(() => {
		window.open(
			AppFunctions.openWebSendWhatsapp(
				AppBusinessInfo.companyPhone.countryCode,
				AppBusinessInfo.companyPhone.number,
				t("message_problem_trying_to_book")
			)
		)
	}, [])

	return (
		<div
			className={`${styles.error_booking_space_message} ${props.className || ""}`}
		>
			<p>
				{t("message_we_could_not_process_your_reservation")}{" "}
				<strong onClick={contactSupportTeam}>
					{t("support_team")}
				</strong>
				.
			</p>
		</div>
	)
}

export default React.memo(ErrorBookingSpaceMessage)
