import * as React from "react"
import * as Antd from "antd"
import styles from "./DvConfirmationModal.module.scss"

import { CloseOutlined } from "@ant-design/icons"
import { useTranslation } from "react-i18next"

import DsButton from "@components/general/DsButton/DsButton.tsx"

interface IProps {
	className?: string
	children: React.ReactNode
	open: boolean
	onCancel?: () => void
	width?: number
	loading?: boolean
	onOk?: () => void
	okTextKey?: string
	okDanger?: boolean
	cancelTextKey?: string
	hideFooter?: boolean
}

const DvConfirmationModal: React.FC<IProps> = (props) => {
	const { t } = useTranslation()

	return (
		<Antd.Modal
			classNames={{
				content: `${styles.dv_confirmation_modal} ${props.className || ""}`,
			}}
			open={props.open}
			onCancel={props.onCancel}
			closable={false}
			centered={true}
			footer={null}
			width={props.width}
			destroyOnClose={true}
		>
			<div className={styles.head}>
				<CloseOutlined
					className={styles.close_icon}
					height={53}
					onClick={props.onCancel}
				/>
			</div>

			<div className={styles.content_wrapper}>{props.children}</div>

			{(props.hideFooter === undefined || !props.hideFooter) && (
				<div className={styles.footer}>
					<DsButton
						className={styles.action_buttons}
						type="secondary"
						onClick={props.onCancel}
					>
						{props.cancelTextKey
							? t(props.cancelTextKey)
							: t("cancel")}
					</DsButton>

					<DsButton
						loading={props.loading}
						className={styles.action_buttons}
						onClick={props.onOk}
						danger={props.okDanger}
					>
						{props.okTextKey ? t(props.okTextKey) : t("continue")}
					</DsButton>
				</div>
			)}
		</Antd.Modal>
	)
}

export default React.memo(DvConfirmationModal)
