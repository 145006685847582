import * as React from "react"
import * as Antd from "antd"
import styles from "./MembershipDetailsForm.module.scss"

import { Dayjs } from "dayjs"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { AppRoutes } from "@constants/AppRoutes.ts"
import { useMembershipApplyForm } from "@hooks/useMembershipApplyForm.tsx"

import useAuthCtx from "@contexts/hooks/useAuthCtx.tsx"
import DvInput from "@components/dataEntry/DvInput/DvInput.tsx"
import MembershipFormFooter from "@components/layout/MembershipFormFooter/MembershipFormFooter.tsx"
import DvAntdFormItem from "@components/others/DvAntdFormItem/DvAntdFormItem.tsx"
import DvAntdForm from "@components/others/DvAntdForm/DvAntdForm.tsx"
import validationRules from "@others/validationRules.ts"

export interface MembershipDetailsFormFieldType {
	name?: string
	lastName?: string
	email?: string
	birthdate?: string
	birthdateInDayJs?: Dayjs
}

interface IProps {
	className?: string
}

const MembershipDetailsForm: React.FC<IProps> = (props) => {
	const { t } = useTranslation()
	const [form] = Antd.Form.useForm<MembershipDetailsFormFieldType>()
	const { loggedUser } = useAuthCtx()

	const navigate = useNavigate()

	const initialValues: MembershipDetailsFormFieldType = React.useMemo(
		() => ({
			name: loggedUser?.name,
			lastName: loggedUser?.lastName,
			email: loggedUser?.email,
			birthdate: undefined,
		}),
		[loggedUser]
	)

	const { onChange } = useMembershipApplyForm(form, initialValues)

	const onFinish = async ({
		name,
		lastName,
		email,
		birthdateInDayJs,
	}: MembershipDetailsFormFieldType) => {
		await onChange("name", name)
		await onChange("lastName", lastName)
		await onChange("email", email)
		await onChange("birthdate", birthdateInDayJs?.toISOString())

		navigate(AppRoutes.membershipFormStep1_address, {
			replace: true,
		})
	}

	return (
		<>
			<DvAntdForm<MembershipDetailsFormFieldType>
				form={form}
				className={`${styles.membership_details_form} ${props.className || ""}`}
				initialValues={initialValues}
				onFinish={onFinish}
			>
				<DvAntdFormItem<MembershipDetailsFormFieldType>
					label={t("name")}
					name="name"
					rules={[
						validationRules.requiredFieldRule(
							t("please_enter_your_name")
						),
					]}
				>
					<DvInput
						placeholder={t("insert_your_name")}
						onChange={(e) => onChange("name", e.target.value)}
					/>
				</DvAntdFormItem>

				<DvAntdFormItem<MembershipDetailsFormFieldType>
					label={t("lastName")}
					name="lastName"
					rules={[
						validationRules.requiredFieldRule(
							t("please_enter_your_lastName")
						),
					]}
				>
					<DvInput
						placeholder={t("insert_your_lastName")}
						onChange={(e) => onChange("lastName", e.target.value)}
					/>
				</DvAntdFormItem>

				<DvAntdFormItem<MembershipDetailsFormFieldType>
					label={t("email")}
					name="email"
					validateTrigger="onSubmit"
					validateDebounce={1500}
					rules={[
						validationRules.requiredFieldRule(
							t("please_enter_your_email")
						),
						validationRules.emailRule(t("your_email_is_not_valid")),
					]}
				>
					<DvInput
						placeholder={t("insert_your_email")}
						onChange={(e) => onChange("email", e.target.value)}
					/>
				</DvAntdFormItem>

				<DvAntdFormItem<MembershipDetailsFormFieldType>
					label={t("birthdate")}
					name="birthdateInDayJs"
					rules={[
						validationRules.requiredFieldRule(
							t("please_enter_your_birthdate")
						),
					]}
				>
					<Antd.DatePicker
						style={{ width: "100%" }}
						onChange={(e) =>
							onChange("birthdate", e?.toISOString())
						}
					/>
				</DvAntdFormItem>
			</DvAntdForm>

			<MembershipFormFooter
				onNextClick={() => form.submit()}
				onBackClick={() => navigate(AppRoutes.membershipStartApplying)}
			/>
		</>
	)
}

export default React.memo(MembershipDetailsForm)
