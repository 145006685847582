import * as React from "react"
import * as Antd from "antd"
import styles from "./DropDownHeader.module.scss"

import { KeyOutlined } from "@ant-design/icons"
import { useTranslation } from "react-i18next"

import AppText from "@components/general/AppText/AppText.tsx"
import DsButton from "@components/general/DsButton/DsButton.tsx"
import useAuthCtx from "@contexts/hooks/useAuthCtx.tsx"
import DvUserAvatar from "@components/dataDisplay/DvUserAvatar/DvUserAvatar.tsx"
import DvConfirmationModal from "@components/feedback/DvConfirmationModal/DvConfirmationModal.tsx"
import UserQrModalContent from "@components/navigation/DsProfileDropDown/DropDownHeader/UserQrModalContent/UserQrModalContent.tsx"

interface IProps {
	className?: string
}

const DropDownHeader: React.FC<IProps> = (props) => {
	const { t } = useTranslation()
	const { loggedUser } = useAuthCtx()

	const [qrModal, setQrModal] = React.useState({
		open: false,
		data: {},
	})

	const isAPaidUser = React.useMemo(
		() => loggedUser?.membershipStatus === "PAID",
		[loggedUser]
	)

	return (
		<>
			<div
				className={`${styles.drop_down_header_container} ${props.className || ""}`}
			>
				<DvUserAvatar className={styles.avatar} />

				<div className={styles.name_container}>
					{(loggedUser?.name || loggedUser?.lastName) && (
						<h1>
							{loggedUser.name} {loggedUser.lastName}
						</h1>
					)}

					{(loggedUser?.plan || loggedUser?.subscription) && (
						<div className={styles.membership}>
							<AppText tag="h3" capitalizeFirstChar>
								{loggedUser?.plan}
							</AppText>

							<AppText capitalizeFirstChar>
								{loggedUser.subscription}
							</AppText>
						</div>
					)}
				</div>

				<Antd.Tooltip
					placement="bottomRight"
					title={!isAPaidUser && t("message_access_code_tooltip")}
				>
					<DsButton
						type="secondary"
						icon={<KeyOutlined />}
						textKey="access_code"
						disabled={!isAPaidUser}
						onClick={() => {
							setQrModal({ open: true, data: {} })
						}}
					/>
				</Antd.Tooltip>
			</div>

			<DvConfirmationModal
				open={qrModal.open}
				width={264}
				hideFooter
				onCancel={() => setQrModal({ open: false, data: {} })}
			>
				<UserQrModalContent />
			</DvConfirmationModal>
		</>
	)
}

export default React.memo(DropDownHeader)
