import * as React from "react"
import * as Antd from "antd"
import styles from "./DsButton.module.scss"

import { useTranslation } from "react-i18next"

interface IDsButtonProps extends Omit<Antd.ButtonProps, "type"> {
	id?: string
	className?: string
	children?: React.ReactNode
	type?: "primary" | "secondary" | "text"
	textKey?: string
}

const DsButton: React.FC<IDsButtonProps> = ({
	type,
	className,
	children,
	textKey,
	...defAntdButtonProps
}) => {
	const { t } = useTranslation()

	const typeStyle = type ? styles[type] : styles.primary

	return (
		<Antd.Button
			className={`${styles.ds_button} ${className || ""} ${typeStyle}`}
			{...defAntdButtonProps}
		>
			{textKey ? t(textKey) : children}
		</Antd.Button>
	)
}

export default React.memo(DsButton)
