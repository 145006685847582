import { ThemeConfig } from "antd"

export const antdTheme: ThemeConfig = {
	token: {
		//* Seed Token
		fontFamily: "Helvetica Now Text, sans-serif",
		colorPrimary: "#66CCCC",
		colorLink: "#57ADAD",
		colorInfo: "#9066CC",
		colorSuccess: "#88CC66",
		colorWarning: "#EBA963",
		colorError: "#E06369",
		colorTextBase: "#23163D",
		colorBgBase: "#ffffff",
		colorTextLightSolid: "#ffffff",
		fontSize: 14,
		sizeUnit: 4,
		sizeStep: 4,
		controlHeight: 32,
		sizePopupArrow: 16,
		lineType: "solid",
		lineWidth: 1,
		borderRadius: 2,
		//* Map Token
		colorBgLayout: "#EAEAF1",
		colorWhite: "#ffffff",
		colorBgElevated: "#ffffff",
		colorBgMask: "rgba(35, 22, 61, 0.45)",
		colorBgSpotlight: "rgba(35, 22, 61, 0.75)",
		colorBorder: "#535353", // --bordes-3

		//* Neutral4. this color is the same as `colorSplit`. Solid color is used.
		colorBorderSecondary: "#F2F1F3",
		//* Primary8
		colorLinkActive: "#489191",
		//* Primary6
		colorLinkHover: "#66CCCC",
		//* PRIMARY
		//* Primary7
		colorPrimaryActive: "#57ADAD",
		//* Primary5
		colorPrimaryHover: "#82D5D5",
		//* Primary1
		colorPrimaryBg: "#F0FAFA",
		//* Primary2
		colorPrimaryBgHover: "#DAF3F3",
		//* Primary5
		colorPrimaryBorder: "#82D5D5",
		//* Primary6
		colorPrimaryBorderHover: "#66CCCC",
		//* Primary7
		colorPrimaryText: "#57ADAD",
		//* Primary6
		colorPrimaryTextHover: "#66CCCC",
		//* Primary8
		colorPrimaryTextActive: "#489191",
		//* SUCCESS
		//* Success7
		colorSuccessActive: "#74AD57",
		//* Success5
		colorSuccessHover: "#9DD582",
		//* Success1
		colorSuccessBg: "#F3FAF0",
		//* Success2
		colorSuccessBgHover: "#E2F3DA",
		//* Success5
		colorSuccessBorder: "#9DD582",
		//* Success6
		colorSuccessBorderHover: "#88CC66",
		//* Success5
		colorSuccessText: "#9DD582",
		//* Success6
		colorSuccessTextHover: "#88CC66",
		//* Success7
		colorSuccessTextActive: "#74AD57",
		//* INFO
		//* Info7
		colorInfoActive: "#7A57AD",
		//* Info5
		colorInfoHover: "#9DD582",
		//* Info1
		colorInfoBg: "#F4F0FA",
		//* Info2
		colorInfoBgHover: "#E4DAF3",
		//* Info5
		colorInfoBorder: "#A482D5",
		//* Info6
		colorInfoBorderHover: "#9066CC",
		//* Info5
		colorInfoText: "#A482D5",
		//* Info6
		colorInfoTextHover: "#9066CC",
		//* Info7
		colorInfoTextActive: "#7A57AD",
		//* WARNING
		//* Warning7
		colorWarningActive: "#C89054",
		//* Warning5
		colorWarningHover: "#EFB87F",
		//* Warning1
		colorWarningBg: "#FDF6EF",
		//* Warning2
		colorWarningBgHover: "#FAEADA",
		//* Warning5
		colorWarningBorder: "#EFB87F",
		//* Warning6
		colorWarningBorderHover: "#EBA963",
		//* Warning5
		colorWarningText: "#EFB87F",
		//* Warning6
		colorWarningTextHover: "#EBA963",
		//* Warning7
		colorWarningTextActive: "#C89054",
		//* ERROR
		//* Red7
		colorErrorActive: "#BE5459",
		//* Red5
		colorErrorHover: "#E67F84",
		//* Red1
		colorErrorBg: "#FCEFF0",
		//* Red2
		colorErrorBgHover: "#F8DADB",
		//* Red5
		colorErrorBorder: "#E67F84",
		//* Red6
		colorErrorBorderHover: "#E06369",
		//* Red5
		colorErrorText: "#E67F84",
		//* Red6
		colorErrorTextHover: "#E06369",
		//* Red7
		colorErrorTextActive: "#BE5459",
		colorText: "#DBDBDB",
		colorTextSecondary: "rgba(35, 22, 61, 0.65)",
		colorTextTertiary: "rgba(35, 22, 61, 0.45)",
		colorTextQuaternary: "rgba(35, 22, 61, 0.25)",
		colorFillQuaternary: "rgba(35, 22, 61, 0.02)",
		colorFillTertiary: "rgba(35, 22, 61, 0.04)",
		colorFillSecondary: "rgba(35, 22, 61, 0.06)",
		colorFill: "rgba(35, 22, 61, 0.15)",
		borderRadiusOuter: 4,
		borderRadiusXS: 2,
		borderRadiusSM: 4,
		borderRadiusLG: 8,
		controlHeightLG: 40,
		controlHeightSM: 24,
		controlHeightXS: 16,
		fontSizeHeading1: 38,
		lineHeightHeading1: 1.2105263157894737,
		fontSizeHeading2: 30,
		lineHeightHeading2: 1.2666666666666666,
		fontSizeHeading3: 24,
		lineHeightHeading3: 1.3333333333333333,
		fontSizeHeading4: 20,
		lineHeightHeading4: 1.4,
		fontSizeHeading5: 16,
		lineHeightHeading5: 1.5,
		lineHeight: 1.5714285714285714,
		fontSizeXL: 20,
		fontSizeLG: 16,
		lineHeightLG: 1.5,
		fontSizeSM: 12,
		lineHeightSM: 1.6666666666666667,
		lineWidthBold: 2,
		size: 16,
		sizeXXL: 48,
		sizeXL: 32,
		sizeLG: 24,
		sizeMD: 20,
		sizeMS: 16,
		sizeSM: 12,
		sizeXS: 8,
		sizeXXS: 4,
		motionDurationFast: "0.1s",
		motionDurationMid: "0.2s",
		motionDurationSlow: "0.3s",
		//* Alias Token
		colorBgContainer: "#ffffff",
		boxShadow:
			"0 6px 16px 0 rgba(35, 22, 61, 0.08), 0 3px 6px -4px rgba(35, 22, 61, 0.12), 0 9px 28px 8px rgba(35, 22, 61, 0.05)",
		boxShadowSecondary:
			"0 6px 16px 0 rgba(35, 22, 61, 0.08), 0 3px 6px -4px rgba(35, 22, 61, 0.12), 0 9px 28px 8px rgba(35, 22, 61, 0.05)",
		boxShadowTertiary:
			"0 1px 2px 0 rgba(35, 22, 61, 0.03), 0 1px 6px -1px rgba(35, 22, 61, 0.02), 0 2px 4px 0 rgba(35, 22, 61, 0.02)",
		colorBgContainerDisabled: "rgba(35, 22, 61, 0.04)",
		colorBgTextActive: "rgba(35, 22, 61, 0.15)",
		colorBgTextHover: "rgba(35, 22, 61, 0.06)",
		colorBorderBg: "#ffffff",
		//* Error6 al 6%
		colorErrorOutline: "rgba(224, 99, 105, 0.06)",
		colorFillAlter: "rgba(35, 22, 61, 0.02)",
		colorFillContent: "rgba(35, 22, 61, 0.06)",
		colorFillContentHover: "rgba(35, 22, 61, 0.15)",
		//* Secondary6
		colorHighlight: "#7149CC",
		colorIcon: "rgba(35, 22, 61, 0.45)",
		colorIconHover: "rgba(35, 22, 61, 0.88)",
		colorSplit: "rgba(35, 22, 61, 0.06)",
		colorTextDescription: "rgba(35, 22, 61, 0.45)",
		colorTextDisabled: "rgba(35, 22, 61, 0.25)",
		colorTextHeading: "rgba(35, 22, 61, 0.88)",
		colorTextLabel: "rgba(35, 22, 61, 0.65)",
		colorTextPlaceholder: "#959595",
		//* Orange6
		colorWarningOutline: "rgba(235, 169, 99, 0.1)",
		//* Primary1
		controlItemBgActive: "#F0FAFA",
		controlItemBgHover: "rgba(35, 22, 61, 0.04)",
		//* Primary2
		controlItemBgActiveHover: "#DAF3F3",
		controlItemBgActiveDisabled: "rgba(35, 22, 61, 0.15)",
		controlOutline: "rgba(102, 204, 204, 0.1)",
		controlInteractiveSize: 16,
		controlOutlineWidth: 2,
		controlPaddingHorizontal: 12,
		controlPaddingHorizontalSM: 8,
		fontSizeIcon: 12,
		fontWeightStrong: 600,
		lineWidthFocus: 4,
		margin: 16,
		marginXXL: 48,
		marginXL: 32,
		marginLG: 24,
		marginMD: 20,
		marginSM: 12,
		marginXS: 8,
		marginXXS: 4,
		padding: 16,
		paddingContentHorizontal: 16,
		paddingContentHorizontalLG: 24,
		paddingContentHorizontalSM: 16,
		paddingContentVertical: 12,
		paddingContentVerticalLG: 16,
		paddingContentVerticalSM: 8,
		paddingXL: 32,
		paddingLG: 24,
		paddingMD: 20,
		paddingSM: 12,
		paddingXS: 8,
		paddingXXS: 4,
		opacityImage: 1,
		opacityLoading: 0.65,
		linkDecoration: "none",
		linkFocusDecoration: "none",
		linkHoverDecoration: "none",
	},
	components: {
		Tabs: {
			//primary 7
			itemSelectedColor: "#57ADAD",
			titleFontSizeSM: 12,
			horizontalItemGutter: 24,
		},
		Upload: {
			//primary 7
			colorPrimary: "#57ADAD",
		},
		Collapse: {
			//secondary 2
			colorBorder: "#E0D7F3",
		},
		Form: {
			labelColor: "#FBFBFC",
			labelHeight: "auto",
		},
		Input: {
			colorBgContainer: "#141414", // $bg-1
			hoverBorderColor: "#969696",
			activeBorderColor: "#afafaf",
		},

		InputNumber: {
			colorBgContainer: "#141414", // $bg-1
			hoverBorderColor: "#969696",
			activeBorderColor: "#afafaf",
		},

		Select: {
			colorBgContainer: "#141414", // $bg-1
		},

		Checkbox: {
			colorPrimary: "#BF7D2AFF",
		},
		Divider: {
			colorSplit: "#303030", // $neutros-8
		},
		DatePicker: {
			colorBgContainer: "#141414", // $bg-1
		},
	},
}
