import * as React from "react"
import * as AntdIcons from "@ant-design/icons"
import styles from "./MemberHeader.module.scss"

import { useTranslation } from "react-i18next"

import AppText from "@components/general/AppText/AppText.tsx"
import DsButton from "@components/general/DsButton/DsButton.tsx"

interface IProps {
	className?: string
	memberNumber: number
	memberName?: number
	onRemove?: (name: number) => void
}

const MemberHeader: React.FC<IProps> = ({
	className,
	onRemove,
	memberName,
	memberNumber,
}) => {
	const { t } = useTranslation()

	const memberTitle =
		memberNumber === 1
			? `${t("member")} 1 (${t("you")})`
			: `${t("member")} ${memberNumber}`

	return (
		<div className={`${styles.member_header} ${className || ""}`}>
			<AppText className={styles.header_title}>{memberTitle}</AppText>

			{onRemove && memberName !== undefined && (
				<DsButton
					className={styles.header_button}
					type="secondary"
					icon={<AntdIcons.DeleteOutlined />}
					onClick={() => onRemove(memberName)}
				/>
			)}
		</div>
	)
}

export default React.memo(MemberHeader)
