import * as React from "react"
import styles from "./ApplyMembershipMessage.module.scss"

import { useTranslation } from "react-i18next"

interface IProps {
	className?: string
}

const ApplyMembershipMessage: React.FC<IProps> = (props) => {
	const { t } = useTranslation()

	return (
		<div
			className={`${styles.apply_membership_message} ${props.className || ""}`}
		>
			<h1 className={styles.title}>
				{t("message_to_book_you_must_have_an_approved_membership")}
			</h1>
			<p className={styles.body}>
				{t("message_do_you_want_to_apply_for_a_membership")}
			</p>
		</div>
	)
}

export default React.memo(ApplyMembershipMessage)
