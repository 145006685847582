import * as React from "react"
import styles from "./DvPlanChose.module.scss"

import { AppFunctions } from "@others/AppFunctions.ts"
import { useTranslation } from "react-i18next"

import AppText from "@components/general/AppText/AppText.tsx"
import AppNumber from "@components/general/AppNumber/AppNumber.tsx"

interface IProps {
	className?: string
	planSubscriptionName?: string
	planSubscriptionDescription?: string
	planSubscriptionPromotion?: string
	planBillingCycleName?: string
	planBillingCycleCurrency?: string
	planBillingCyclePrice?: number
	planBillingCyclePriceDescription?: string
}

const DvPlanChose: React.FC<IProps> = (props) => {
	const { t } = useTranslation()

	return (
		<div className={`${styles.dv_plan_chose} ${props.className || ""}`}>
			<div className={styles.info}>
				<AppText className={styles.info_title}>
					{props.planSubscriptionName}
				</AppText>

				<div className={styles.info_details}>
					<AppText className={styles.subtitle}>
						{props?.planBillingCycleName &&
							AppFunctions.capitalizeFirstChar(
								t("message_plan_billing_cycle").replace(
									"(XXXX)",
									props?.planBillingCycleName.toLowerCase()
								)
							)}
					</AppText>

					{props?.planSubscriptionDescription && (
						<AppText className={styles.description}>
							{`(${props?.planSubscriptionDescription})`}
						</AppText>
					)}
				</div>
			</div>

			<div className={styles.plan_price}>
				<div className={styles.plan_price_top}>
					<AppText tag="h3" className={styles.period}>
						{props?.planSubscriptionPromotion || " "}
					</AppText>

					<div className={styles.price}>
						<AppText className={styles.currency}>
							{props?.planBillingCycleCurrency}
						</AppText>

						<AppNumber className={styles.value}>
							{props.planBillingCyclePrice}
						</AppNumber>
					</div>

					<AppText className={styles.program}>
						{props?.planBillingCyclePriceDescription}
					</AppText>
				</div>
			</div>
		</div>
	)
}

export default React.memo(DvPlanChose)
