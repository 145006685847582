import * as React from "react"

import { IGetMyBookingsResponseCli } from "@interfaces/IGetMyBookingsResponseCli.ts"

import dayjs from "dayjs"
import bookingService from "@services/bookingService/bookingService.ts"
import useBookingsCtx from "@contexts/hooks/useBookingsCtx.tsx"

interface IOutput {
	bookings: IGetMyBookingsResponseCli
	upcomingBookings: IGetMyBookingsResponseCli
	myUpcomingBookings: IGetMyBookingsResponseCli
	fetchBookings: () => void
}

export const useBookingsData = (): IOutput => {
	const { bookings, setBookings } = useBookingsCtx()

	const fetchBookings = React.useCallback(() => {
		bookingService
			.getMyBookings()
			.then((data) => {
				setBookings(data)
			})
			.catch(({ message }) => {
				console.error(
					message || "Something went wrong fetching the user data"
				)
			})
	}, [])

	const upcomingBookings = React.useMemo<IGetMyBookingsResponseCli>(
		() =>
			bookings
				?.filter((booking: IGetMyBookingsResponseCli[0]) => {
					return (
						dayjs(booking.endDate).isAfter(dayjs()) &&
						booking.status === "APPROVED"
					)
				})
				.sort(
					(
						a: IGetMyBookingsResponseCli[0],
						b: IGetMyBookingsResponseCli[0]
					) => dayjs(a.startDate).diff(dayjs(b.startDate))
				)
				.slice(0, 3),
		[bookings]
	)

	const myUpcomingBookings = React.useMemo<IGetMyBookingsResponseCli>(
		() =>
			bookings
				?.filter((booking: IGetMyBookingsResponseCli[0]) => {
					return (
						dayjs(booking.endDate).isAfter(dayjs()) &&
						(booking.status === "APPROVED" ||
							booking.status === "CANCELED")
					)
				})
				.sort(
					(
						a: IGetMyBookingsResponseCli[0],
						b: IGetMyBookingsResponseCli[0]
					) => dayjs(a.startDate).diff(dayjs(b.startDate))
				),
		[bookings]
	)

	return {
		bookings,
		fetchBookings,
		upcomingBookings,
		myUpcomingBookings,
	}
}
