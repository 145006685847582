import * as React from "react"
import * as AntdIcons from "@ant-design/icons"
import styles from "./DsPlanTable.module.scss"

import { AppRoutes } from "@constants/AppRoutes.ts"
import { usePlanData } from "@hooks/usePlanData.tsx"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useMembershipApplyForm } from "@hooks/useMembershipApplyForm.tsx"

import DsSpace from "@components/dataDisplay/DsPlanTable/DsSpace/DsSpace.tsx"
import Switches from "@components/dataDisplay/DsPlanTable/Switches/Switches.tsx"
import DsPlanPrice from "@components/dataDisplay/DsPlanTable/DsPlanPrice/DsPlanPrice.tsx"
import useResponsiveToolCtx from "@contexts/hooks/useResponsiveToolCtx.tsx"
import PlanSubscriptionDetails from "@components/dataDisplay/PlanSubscriptionDetails/PlanSubscriptionDetails.tsx"

const Table = "div",
	HeaderCell = "div",
	ItemCell = "div"

interface IColumn {
	planName: string
	planType: string
}

interface IRow {
	benefitName: string
	benefitsEnabled: boolean[]
}

export interface IPlanTableFormFieldType {
	plan?: string
	paymentFrequency?: string
	subscription?: string
}

interface ISubscription {
	id: string
	name: string
}

export interface IDsPlanTableProps {
	className?: string
	columns: IColumn[]
	rows: IRow[]
	subscriptions?: ISubscription[]
}

const DsPlanTable: React.FC<IDsPlanTableProps> = (props) => {
	const navigate = useNavigate()

	const { t } = useTranslation()
	const { planData } = usePlanData()
	const { isDesktop, isMobile } = useResponsiveToolCtx()

	const initialValues = React.useMemo<IPlanTableFormFieldType>(
		() => ({
			plan: planData?.defaultPlan,
			subscription: planData?.defaultSubscription,
			paymentFrequency: planData?.defaultBillingCycle,
		}),
		[planData]
	)

	const { states, onChange } = useMembershipApplyForm(
		undefined,
		initialValues
	)

	const selectedPlan = React.useMemo(
		() => planData?.plans.find(({ id }) => id === states.plan),
		[planData, states]
	)

	const selectedPlanSubscription = React.useMemo(
		() =>
			selectedPlan?.subscriptions.find(
				({ id }) => id === states.subscription
			),
		[planData, states]
	)

	const selectedPlanBillingCycle = React.useMemo(
		() =>
			selectedPlanSubscription?.billingCycle.find(
				({ id }) => id === states.paymentFrequency
			),
		[planData, states]
	)

	return (
		<div
			className={`${styles.ds_plan_table_container} ${props.className || ""}`}
		>
			{isMobile && (
				<Switches
					key={`${states?.subscription} ${states?.paymentFrequency}`}
					className={styles.mobile_switches}
					defaultSubscription={states?.subscription}
					defaultBillingCycle={states?.paymentFrequency}
					onBillingCycleChange={(value) => {
						onChange("paymentFrequency", value)
					}}
					onSubscriptionChange={(value) => {
						onChange("subscription", value)
					}}
				/>
			)}

			<div className={styles.table_scrollable_container}>
				<Table className={styles.ds_plan_table}>
					{isDesktop && (
						<>
							<HeaderCell
								className={`${styles.header_col_1} ${styles.col_1} ${styles.header} ${styles.desktop_switches_row}`}
							/>

							<Switches
								key={`${states?.subscription} ${states?.paymentFrequency}`}
								className={`${styles.desktop_switches} ${styles.desktop_switches_row}`}
								defaultSubscription={states?.subscription}
								defaultBillingCycle={states?.paymentFrequency}
								desktopSubscriptionButtonWidth="150px"
								desktopPlanButtonWidth="106px"
								onBillingCycleChange={(value) => {
									onChange("paymentFrequency", value)
								}}
								onSubscriptionChange={(value) => {
									onChange("subscription", value)
								}}
							/>
						</>
					)}

					<HeaderCell
						className={`${styles.header_col_1} ${styles.col_1} ${styles.header}  ${styles.top_left_cell}`}
					>
						{t("benefit").toUpperCase()}
					</HeaderCell>

					{props.columns.map(({ planName, planType }, idx) => {
						const className = `header_col_${idx + 2}`
						return (
							<HeaderCell
								className={`${styles[className]} ${styles.header}`}
								key={idx}
							>
								<h1>{planName}</h1>
								<h3>{planType}</h3>
							</HeaderCell>
						)
					})}

					{props.rows.map(({ benefitName, benefitsEnabled }, idx) => (
						<React.Fragment key={idx}>
							<ItemCell
								className={`${styles.item_col_1} ${styles.col_1}`}
							>
								{benefitName}
							</ItemCell>

							{benefitsEnabled.map((enabled, idx) => {
								const className = `item_col_${idx + 2}`
								return (
									<ItemCell
										className={styles[className]}
										key={idx}
									>
										{enabled ? (
											<AntdIcons.CheckOutlined
												className={styles.check_icon}
											/>
										) : (
											<AntdIcons.CloseOutlined
												className={styles.close_icon}
											/>
										)}
									</ItemCell>
								)
							})}
						</React.Fragment>
					))}

					<DsSpace
						amountOfPlans={props.columns.length}
						firstSpaceClassName={styles.col_1}
					/>

					<ItemCell
						className={`${styles.col_1} ${styles.footer_col_1}`}
					>
						<PlanSubscriptionDetails
							className={styles.plan_subscription_details}
							subscriptionName={selectedPlanSubscription?.name}
							billingCycleName={selectedPlanBillingCycle?.name}
							subscriptionDescription={
								selectedPlanSubscription?.description
							}
						/>
					</ItemCell>

					{planData?.plans?.map((plan, idx) => {
						const className = `footer_col_${idx + 2}`

						const planSubscription = plan.subscriptions.find(
							({ id }) => id === states.subscription
						)

						const planBillingCycle =
							planSubscription?.billingCycle.find(
								({ id }) => id === states.paymentFrequency
							)

						return (
							<ItemCell className={styles[className]} key={idx}>
								<DsPlanPrice
									selected={states.plan === plan.id}
									frequency={planSubscription?.promotion}
									price={planBillingCycle?.price}
									currency={planBillingCycle?.currency}
									description={
										planBillingCycle?.priceDescription
									}
									onClick={async () => {
										await onChange("plan", plan.id)

										await onChange(
											"subscription",
											states.subscription
										)

										await onChange(
											"paymentFrequency",
											states.paymentFrequency
										)

										navigate(
											AppRoutes.membershipStartApplying,
											{
												replace: true,
											}
										)
									}}
								/>
							</ItemCell>
						)
					})}

					<DsSpace
						className={styles.footer_space}
						firstSpaceClassName={styles.col_1}
						amountOfPlans={props.columns.length}
					/>
				</Table>
			</div>
		</div>
	)
}

export default React.memo(DsPlanTable)
