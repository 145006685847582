import * as React from "react"
import styles from "./PayMembershipMessage.module.scss"

import { useTranslation } from "react-i18next"

interface IProps {
	className?: string
}

const PayMembershipMessage: React.FC<IProps> = (props) => {
	const { t } = useTranslation()

	return (
		<div
			className={`${styles.pay_membership_message} ${props.className || ""}`}
		>
			<h1 className={styles.title}>
				{t("message_must_complete_the_payment")}
			</h1>
			<p className={styles.body}>
				{t("do_you_want_to_make_the_payment_of_your_membership")}
			</p>
		</div>
	)
}

export default React.memo(PayMembershipMessage)
