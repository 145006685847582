import * as React from "react"
import * as Antd from "antd"
import styles from "./MembershipReferencesForm.module.scss"

import { useNavigate } from "react-router-dom"
import { AppRoutes } from "@constants/AppRoutes.ts"
import { useTranslation } from "react-i18next"
import { useMembershipApplyForm } from "@hooks/useMembershipApplyForm.tsx"
import { AppCorporateSubscriptions } from "@constants/AppCorporateSubscriptions.ts"

import DvCheckbox from "@components/dataEntry/DvCheckbox/DvCheckbox.tsx"
import DvInput from "@components/dataEntry/DvInput/DvInput.tsx"
import DvAntdForm from "@components/others/DvAntdForm/DvAntdForm.tsx"
import DvAntdFormItem from "@components/others/DvAntdFormItem/DvAntdFormItem.tsx"
import MembershipFormFooter from "@components/layout/MembershipFormFooter/MembershipFormFooter.tsx"
import validationRules from "@others/validationRules.ts"
import membershipFormDataService from "@services/membershipApplyFormService.ts"

export interface IMembershipReferencesFormFieldType {
	referenceName?: string
	referenceEmail?: string
	doNotKnowAnyone?: boolean
}

interface IProps {
	className?: string
}

const MembershipReferencesForm: React.FC<IProps> = (props) => {
	const navigate = useNavigate()
	const [form] = Antd.Form.useForm<IMembershipReferencesFormFieldType>()
	const { t } = useTranslation()

	const initialValues: IMembershipReferencesFormFieldType = React.useMemo(
		() => ({
			referenceName: undefined,
			referenceEmail: undefined,
			doNotKnowAnyone: false,
		}),
		[]
	)

	const { states, onChange } = useMembershipApplyForm(form, initialValues)

	const onFinish = async ({
		referenceName,
		referenceEmail,
		doNotKnowAnyone,
	}: IMembershipReferencesFormFieldType) => {
		await onChange("referenceName", referenceName)
		await onChange("referenceEmail", referenceEmail)
		await onChange("doNotKnowAnyone", doNotKnowAnyone)

		const isACorporateSubscription = AppCorporateSubscriptions.includes(
			states?.subscription || ""
		)

		navigate(
			isACorporateSubscription
				? AppRoutes.membershipFormStep3_your_group
				: AppRoutes.membershipFormStep4_globoRules,
			{
				replace: true,
			}
		)
	}

	return (
		<>
			<DvAntdForm<IMembershipReferencesFormFieldType>
				form={form}
				className={`${styles.membership_reference_form} ${
					props.className || ""
				}`}
				initialValues={initialValues}
				onFinish={onFinish}
			>
				<header className={styles.header}>
					{t("message_if_you_no_a_member_of")}
					<strong>Distrito El Globo</strong>,{" "}
					{t("message_you_can_add_it_here")}:
				</header>

				<DvAntdFormItem<IMembershipReferencesFormFieldType>
					className={styles.form_item}
					name="doNotKnowAnyone"
					valuePropName="checked"
				>
					<DvCheckbox
						className={styles.checkbox}
						onChange={async (e) => {
							const isChecked = e.target.checked

							await onChange("doNotKnowAnyone", isChecked)

							if (isChecked) {
								await membershipFormDataService.removeKey(
									"referenceName"
								)
								await membershipFormDataService.removeKey(
									"referenceEmail"
								)
							}
						}}
					>
						{t("message_i_dont_know_anyone")}{" "}
						<strong>Distrito El Globo</strong> {t("yet")}.
					</DvCheckbox>
				</DvAntdFormItem>

				<DvAntdFormItem<IMembershipReferencesFormFieldType>
					className={styles.form_item}
					label={t("name")}
					name={!states.checked ? "referenceName" : undefined}
					rules={[
						validationRules.requiredFieldRule(
							t("please_enter_their_name")
						),
					]}
				>
					<DvInput
						placeholder={t("insert_their_name")}
						disabled={states.checked}
						onChange={(e) =>
							onChange("referenceName", e.target.value)
						}
					/>
				</DvAntdFormItem>

				<DvAntdFormItem<IMembershipReferencesFormFieldType>
					className={styles.form_item}
					label={t("email")}
					name={!states.checked ? "referenceEmail" : undefined}
					validateDebounce={1500}
					rules={[
						validationRules.requiredFieldRule(
							t("please_enter_their_email")
						),
						validationRules.emailRule(t("your_email_is_not_valid")),
					]}
				>
					<DvInput
						placeholder={t("insert_their_email")}
						disabled={states.checked}
						onChange={(e) =>
							onChange("referenceEmail", e.target.value)
						}
					/>
				</DvAntdFormItem>
			</DvAntdForm>

			<MembershipFormFooter
				onBackClick={() =>
					navigate(AppRoutes.membershipFormStep3_digital_profiles)
				}
				onNextClick={() => form.submit()}
			/>
		</>
	)
}

export default React.memo(MembershipReferencesForm)
