import api from "@others/api.ts"

import { Endpoints } from "@constants/Endpoints.tsx"
import { AppFunctions } from "@others/AppFunctions.ts"
import { IApiResponse } from "@interfaces/generic/IApiResponse.ts"
import { IGetSpaceAllPlacesResponseApi } from "@interfaces/IGetSpaceAllPlacesResponseApi.ts"
import { IGetSpaceAllPlacesResponseCli } from "@interfaces/IGetSpaceAllPlacesResponseCli.ts"
import { GetSpaceAllPlacesAdapter } from "@adapters/GetSpaceAllPlacesAdapter.ts"
import { IGetSpaceAvailableDaysParamsApi } from "@interfaces/IGetSpaceAvailableDaysParamsApi.ts"
import { IGetSpaceAvailableDaysResponseApi } from "@interfaces/IGetSpaceAvailableDaysResponseApi.ts"
import { GetSpaceAvailableDaysAdapter } from "@adapters/GetSpaceAvailableDaysAdapter.ts"
import { IGetSpaceAvailableDaysResponseCli } from "@interfaces/IGetSpaceAvailableDaysResponseCli.ts"
import { IGetSpaceAvailableHoursResponseCli } from "@interfaces/IGetSpaceAvailableHoursResponseCli.ts"
import { IGetSpaceAvailableHoursResponseApi } from "@interfaces/IGetSpaceAvailableHoursResponseApi.ts"
import { GetSpaceAvailableHoursAdapter } from "@adapters/GetSpaceAvailableHoursAdapter.ts"
import { IGetSpaceAvailableHoursParamsApi } from "@interfaces/IGetSpaceAvailableHoursParamsApi.ts"
import { IPostBookSpaceBodyApi } from "@interfaces/IPostBookSpaceBodyApi.ts"
import { IPostBookSpaceResponseCli } from "@interfaces/IPostBookSpaceResponseCli.ts"
import { IPostBookSpaceResponseApi } from "@interfaces/IPostBookSpaceResponseApi.ts"
import { PostBookSpaceAdapter } from "@adapters/PostBookSpaceAdapter.ts"

const getSpaceAllPlaces = async (): Promise<IGetSpaceAllPlacesResponseCli> => {
	return api
		.get<IApiResponse<IGetSpaceAllPlacesResponseApi>>(
			Endpoints.bookSpaceAllPlaces
		)
		.then(({ data }) => GetSpaceAllPlacesAdapter(data))
		.catch((error) => {
			throw AppFunctions.getErrorApiDetail(error)
		})
}

const getSpaceAvailableDays = async (
	params: IGetSpaceAvailableDaysParamsApi
): Promise<IGetSpaceAvailableDaysResponseCli> => {
	return api
		.get<IApiResponse<IGetSpaceAvailableDaysResponseApi>>(
			Endpoints.bookSpaceAvailableDays,
			{ params }
		)
		.then(({ data }) => GetSpaceAvailableDaysAdapter(data))
		.catch((error) => {
			throw AppFunctions.getErrorApiDetail(error)
		})
}

const getSpaceAvailableHours = async (
	params: IGetSpaceAvailableHoursParamsApi
): Promise<IGetSpaceAvailableHoursResponseCli> => {
	return api
		.get<IApiResponse<IGetSpaceAvailableHoursResponseApi>>(
			Endpoints.bookSpaceAvailableHours,
			{ params }
		)
		.then(({ data }) => GetSpaceAvailableHoursAdapter(data))
		.catch((error) => {
			throw AppFunctions.getErrorApiDetail(error)
		})
}

const bookSpace = async (
	body: IPostBookSpaceBodyApi
): Promise<IPostBookSpaceResponseCli> => {
	return api
		.post<IApiResponse<IPostBookSpaceResponseApi>>(
			Endpoints.bookSpace,
			body
		)
		.then(({ data }) => PostBookSpaceAdapter(data))
		.catch((error) => {
			throw AppFunctions.getErrorApiDetail(error)
		})
}

export default {
	getSpaceAllPlaces,
	getSpaceAvailableDays,
	getSpaceAvailableHours,
	bookSpace,
}
