import * as React from "react"
import * as Antd from "antd"
import styles from "./MembershipJobsForm.module.scss"

import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { MemberShipLayoutConstants } from "@components/layout/MembershipLayout/MembershipLayout.constants.ts"
import { AppRoutes } from "@constants/AppRoutes.ts"
import { useListKindOfJobs } from "@hooks/useListKindOfJobs.tsx"
import { useListJobRoles } from "@hooks/useListJobRoles.tsx"
import { useMembershipApplyForm } from "@hooks/useMembershipApplyForm.tsx"

import DsButton from "@components/general/DsButton/DsButton.tsx"
import DvSelect from "@components/dataEntry/DvSelect/DvSelect.tsx"
import useAuthCtx from "@contexts/hooks/useAuthCtx.tsx"
import DvInput from "@components/dataEntry/DvInput/DvInput.tsx"
import DvAntdForm from "@components/others/DvAntdForm/DvAntdForm.tsx"
import MembershipFormFooter from "@components/layout/MembershipFormFooter/MembershipFormFooter.tsx"
import DvAntdFormItem from "@components/others/DvAntdFormItem/DvAntdFormItem.tsx"
import validationRules from "@others/validationRules.ts"

export interface IMembershipJobsFormFieldType {
	job?: string
	kindOfJob?: string
	role?: string
	workEmail?: string
}

interface IProps {
	className?: string
}

const MembershipJobsForm: React.FC<IProps> = (props) => {
	const navigate = useNavigate()

	const [form] = Antd.Form.useForm<IMembershipJobsFormFieldType>()
	const { loggedUser } = useAuthCtx()
	const { t } = useTranslation()
	const { kindOfJobs } = useListKindOfJobs()
	const { jobRoles } = useListJobRoles()

	const initialValues: IMembershipJobsFormFieldType = React.useMemo(
		() => ({
			job: loggedUser?.company,
			kindOfJob: undefined,
			role: undefined,
			workEmail: undefined,
		}),
		[loggedUser]
	)

	const { onChange } = useMembershipApplyForm(form, initialValues)

	const onFinish = async ({
		job,
		kindOfJob,
		role,
		workEmail,
	}: IMembershipJobsFormFieldType) => {
		await onChange("job", job)
		await onChange("kindOfJob", kindOfJob)
		await onChange("role", role)
		await onChange("workEmail", workEmail)

		navigate(AppRoutes.membershipFormStep3_presentation, { replace: true })
	}

	return (
		<>
			<DvAntdForm<IMembershipJobsFormFieldType>
				form={form}
				className={`${styles.membership_personal_details_form} ${
					props.className || ""
				}`}
				initialValues={initialValues}
				onFinish={onFinish}
			>
				<DvAntdFormItem<IMembershipJobsFormFieldType>
					className={styles.form_item}
					label={t("where_do_you_work")}
					name="job"
					rules={[
						validationRules.requiredFieldRule(
							t("please_enter_your_job")
						),
					]}
				>
					<DvInput
						placeholder={t("insert_your_job")}
						onChange={(e) => onChange("job", e.target.value)}
					/>
				</DvAntdFormItem>

				<DvAntdFormItem<IMembershipJobsFormFieldType>
					className={styles.form_item}
					label={t("kind_of_job")}
					name="kindOfJob"
					rules={[
						validationRules.requiredFieldRule(
							t("please_enter_your_kind_of_job")
						),
					]}
				>
					<DvSelect
						options={kindOfJobs}
						onSelect={(value) => onChange("kindOfJob", value)}
					/>
				</DvAntdFormItem>

				<DvAntdFormItem<IMembershipJobsFormFieldType>
					className={styles.form_item}
					label={t("whats_your_role")}
					name="role"
					rules={[
						validationRules.requiredFieldRule(
							t("please_enter_your_role")
						),
					]}
				>
					<DvSelect
						options={jobRoles}
						onSelect={(value) => onChange("role", value)}
					/>
				</DvAntdFormItem>

				<DvAntdFormItem<IMembershipJobsFormFieldType>
					className={styles.form_item}
					label={t("work_email")}
					name="workEmail"
					validateDebounce={1500}
					rules={[
						validationRules.requiredFieldRule(
							t("please_enter_your_work_email")
						),
						validationRules.emailRule(t("your_email_is_not_valid")),
					]}
				>
					<DvInput
						placeholder={t("insert_your_work_email")}
						onChange={(e) => onChange("workEmail", e.target.value)}
					/>
				</DvAntdFormItem>

				<DsButton
					id={MemberShipLayoutConstants.SUBMIT_FORM_BUTTON_ID}
					style={{ display: "none" }}
					htmlType="submit"
				/>
			</DvAntdForm>

			<MembershipFormFooter
				onBackClick={() =>
					navigate(AppRoutes.membershipFormStep2_personalDetails)
				}
				onNextClick={() => form.submit()}
			/>
		</>
	)
}

export default React.memo(MembershipJobsForm)
