import * as React from "react"
import styles from "./PlanSubscriptionDetails.module.scss"

import { AppFunctions } from "@others/AppFunctions.ts"
import { useTranslation } from "react-i18next"

import AppText from "@components/general/AppText/AppText.tsx"

interface IProps {
	className?: string
	subscriptionName?: string
	subscriptionDescription?: string
	billingCycleName?: string
}

const PlanSubscriptionDetails: React.FC<IProps> = (props) => {
	const { t } = useTranslation()

	return (
		<div
			className={`${styles.plan_subscription_details} ${props.className || ""}`}
		>
			<AppText className={styles.title}>{props.subscriptionName}</AppText>

			<AppText className={styles.subtitle}>
				{props.billingCycleName &&
					AppFunctions.capitalizeFirstChar(
						t("message_plan_billing_cycle").replace(
							"(XXXX)",
							props.billingCycleName.toLowerCase()
						)
					)}
			</AppText>

			{props?.subscriptionDescription && (
				<AppText className={styles.description}>
					{`(${props.subscriptionDescription})`}
				</AppText>
			)}
		</div>
	)
}

export default React.memo(PlanSubscriptionDetails)
