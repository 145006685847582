import * as React from "react"

import { AppIndexedDBKeys } from "@constants/AppIndexedDBKeys.ts"
import { IGetMyBookingsResponseCli } from "@interfaces/IGetMyBookingsResponseCli.ts"

import IndexedDB from "@services/indexedDBService.ts"

interface ICtx {
	bookings: IGetMyBookingsResponseCli
	setBookings: React.Dispatch<React.SetStateAction<IGetMyBookingsResponseCli>>
}

const InitialCtxState: ICtx = {
	bookings: [],
	setBookings: () => {},
}

let isBookingsDataInInitialState = true

export const Context = React.createContext<ICtx>(InitialCtxState)

const CtxProvider: React.FC<{
	children: React.ReactNode
}> = ({ children }) => {
	const [bookings, setBookingsState] = React.useState(
		InitialCtxState.bookings
	)

	React.useEffect(() => {
		if (isBookingsDataInInitialState) return
		IndexedDB.updateKey(AppIndexedDBKeys.BOOKINGS, bookings)
	}, [bookings])

	React.useEffect(() => {
		IndexedDB.getKey<ICtx["bookings"]>(AppIndexedDBKeys.BOOKINGS).then(
			(data) => {
				if (!data) return
				setBookingsState(data)
			}
		)
	}, [])

	const setBookings: ICtx["setBookings"] = React.useCallback((booking) => {
		isBookingsDataInInitialState = false
		setBookingsState(booking)
	}, [])

	const contextValue = React.useMemo<ICtx>(
		() => ({
			bookings,
			setBookings,
		}),
		[bookings, setBookings]
	)

	return <Context.Provider value={contextValue}>{children}</Context.Provider>
}

export const BookingsCtxProvider = CtxProvider
