import * as React from "react"
import * as Antd from "antd"
import * as AntdIcons from "@ant-design/icons"
import styles from "./MembershipYourGroupForm.module.scss"

import { AppRoutes } from "@constants/AppRoutes.ts"
import { usePlanData } from "@hooks/usePlanData.tsx"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useLoggedUserData } from "@hooks/useLoggedUserData.ts"
import { useMembershipApplyForm } from "@hooks/useMembershipApplyForm.tsx"
import { AppCorporateSubscriptions } from "@constants/AppCorporateSubscriptions.ts"

import AppText from "@components/general/AppText/AppText.tsx"
import DvInput from "@components/dataEntry/DvInput/DvInput.tsx"
import DsButton from "@components/general/DsButton/DsButton.tsx"
import DvAntdForm from "@components/others/DvAntdForm/DvAntdForm.tsx"
import MemberHeader from "@components/dataEntry/MembershipYourGroupForm/MemberHeader/MemberHeader.tsx"
import DvAntdFormItem from "@components/others/DvAntdFormItem/DvAntdFormItem.tsx"
import validationRules from "@others/validationRules.ts"
import MembershipFormFooter from "@components/layout/MembershipFormFooter/MembershipFormFooter.tsx"

interface IMember {
	fullName: string
	email: string
}

export interface IMembershipYourGroupFormFieldType {
	members?: IMember[]
}

interface IMembershipYourGroupFormProps {
	className?: string
}

const MembershipYourGroupForm: React.FC<IMembershipYourGroupFormProps> = (
	props
) => {
	const navigate = useNavigate()

	const { t } = useTranslation()
	const { planData } = usePlanData({ fetchOnMount: true })
	const { loggedUser } = useLoggedUserData()

	const [form] = Antd.Form.useForm<IMembershipYourGroupFormFieldType>()

	const [addButtonDisabled, setAddButtonDisabled] = React.useState(false)

	const initialValues: IMembershipYourGroupFormFieldType = React.useMemo(
		() => ({}),
		[]
	)

	const { states, onChange } = useMembershipApplyForm(form, initialValues)

	const updateForm = () => {
		onChange("members", form.getFieldsValue().members)
	}

	const onFinish = async (values: IMembershipYourGroupFormFieldType) => {
		await onChange("members", values.members)

		navigate(AppRoutes.membershipFormStep4_globoRules, {
			replace: true,
		})
	}

	React.useEffect(() => {
		if (states?.subscription && !isACorporateSubscription)
			navigate(AppRoutes.notFound)
	}, [states])

	const isACorporateSubscription = React.useMemo(
		() => AppCorporateSubscriptions.includes(states?.subscription || ""),
		[states]
	)

	const selectedPlan = React.useMemo(
		() => planData?.plans.find(({ id }) => id === states.plan),
		[planData, states]
	)

	const selectedPlanSubscription = React.useMemo(
		() =>
			selectedPlan?.subscriptions.find(
				({ id }) => id === states.subscription
			),
		[planData, states]
	)

	const fullName = React.useMemo(
		() =>
			loggedUser?.name && loggedUser?.lastName
				? `${loggedUser?.name} ${loggedUser?.lastName}`
				: undefined,
		[loggedUser]
	)

	return (
		<>
			<DvAntdForm<IMembershipYourGroupFormFieldType>
				form={form}
				className={`${styles.membership_your_group_form} ${props.className || ""}`}
				initialValues={initialValues}
				onFinish={onFinish}
			>
				<AppText className={styles.header}>
					{t("message_insert_your_members")}
				</AppText>

				<div className={styles.member_container}>
					<MemberHeader memberNumber={1} />

					<DvAntdFormItem<IMembershipYourGroupFormFieldType>
						label={t("name_and_last_name")}
					>
						<DvInput disabled value={fullName} />
					</DvAntdFormItem>

					<DvAntdFormItem<IMembershipYourGroupFormFieldType>
						label={t("email")}
					>
						<DvInput disabled value={loggedUser?.email} />
					</DvAntdFormItem>
				</div>

				<Antd.Form.List name="members">
					{(fields, { add, remove }) => {
						if (
							selectedPlanSubscription?.memberMinAmount &&
							fields.length <
								selectedPlanSubscription?.memberMinAmount - 1 // 1 is you
						) {
							add()
						}

						if (
							selectedPlanSubscription?.memberMaxAmount &&
							fields.length >=
								selectedPlanSubscription?.memberMaxAmount - 1 // 1 is you
						) {
							setAddButtonDisabled(true)
						} else {
							setAddButtonDisabled(false)
						}

						return (
							<>
								{fields.map(({ key, name, ...restField }) => {
									const memberNumber = name + 2 // 1 is you

									const removeDisabled =
										selectedPlanSubscription?.memberMinAmount
											? memberNumber <=
												selectedPlanSubscription?.memberMinAmount
											: false

									return (
										<div
											className={styles.member_container}
											key={key}
										>
											<MemberHeader
												memberNumber={memberNumber}
												memberName={name}
												onRemove={
													!removeDisabled
														? (name) => {
																remove(name)
																updateForm()
															}
														: undefined
												}
											/>

											<DvAntdFormItem
												{...restField}
												label={t("name_and_last_name")}
												name={[name, "fullName"]}
												rules={[
													validationRules.requiredFieldRule(
														t(
															"please_enter_their_full_name"
														)
													),
												]}
											>
												<DvInput
													placeholder={t(
														"insert_their_full_name"
													)}
													onChange={updateForm}
												/>
											</DvAntdFormItem>

											<DvAntdFormItem
												{...restField}
												label={t("email")}
												name={[name, "email"]}
												validateDebounce={1500}
												rules={[
													validationRules.requiredFieldRule(
														t(
															"please_enter_their_email"
														)
													),
													validationRules.emailRule(
														t(
															"your_email_is_not_valid"
														)
													),
												]}
											>
												<DvInput
													placeholder={t(
														"insert_their_email"
													)}
													onChange={updateForm}
												/>
											</DvAntdFormItem>
										</div>
									)
								})}

								<DsButton
									className={styles.add_button}
									icon={<AntdIcons.PlusOutlined />}
									type="secondary"
									textKey="add"
									disabled={addButtonDisabled}
									onClick={() => add()}
								/>
							</>
						)
					}}
				</Antd.Form.List>
			</DvAntdForm>

			<MembershipFormFooter
				onBackClick={() =>
					navigate(AppRoutes.membershipFormStep3_references)
				}
				onNextClick={() => form.submit()}
			/>
		</>
	)
}

export default React.memo(MembershipYourGroupForm)
