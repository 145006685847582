import { IGetMyBookingsResponseCli } from "@interfaces/IGetMyBookingsResponseCli.ts"

type IBookingCategories = NonNullable<IGetMyBookingsResponseCli[0]["category"]>

export const BookingCategories: Record<IBookingCategories, IBookingCategories> =
	{
		EVENTS: "EVENTS",
		RESTAURANTS: "RESTAURANTS",
		SPACES: "SPACES",
	}
