import api from "@others/api"

import { IApiResponse } from "@interfaces/generic/IApiResponse"
import { Endpoints } from "@constants/Endpoints.tsx"
import { AppFunctions } from "@others/AppFunctions.ts"
import { IPostMembershipApplyBodyApi } from "@interfaces/IPostMembershipApplyBodyApi.ts"
import { IPostMembershipApplyResponseApi } from "@interfaces/IPostMembershipApplyResponseApi.ts"
import { IPostPayMembershipBodyApi } from "@interfaces/IPostPayMembershipBodyApi.ts"
import { IPostPayMembershipResponseApi } from "@interfaces/IPostPayMembershipResponseApi.ts"
import { IPostPayMembershipResponseCli } from "@interfaces/IPostPayMembershipResponseCli.ts"
import { PostPayMembershipAdapter } from "@adapters/PostPayMembershipAdapter.ts"
import { IGetPayMembershipSupportedCountriesResponseCli } from "@interfaces/IGetPayMembershipSupportedCountriesResponseCli.ts"
import { IGetPayMembershipSupportedCountriesResponseApi } from "@interfaces/IGetPayMembershipSupportedCountriesResponseApi.ts"
import { GetPayMembershipSupportedCountriesAdapter } from "@adapters/GetPayMembershipSupportedCountriesAdapter.ts"
import { GetPayMembershipStatusAdapter } from "@adapters/GetPayMembershipStatusAdapter.ts"
import { IPostPayMembershipStatusResponseApi } from "@interfaces/IPostPayMembershipStatusResponseApi.ts"
import { IPostPayMembershipStatusResponseCli } from "@interfaces/IPostPayMembershipStatusResponseCli.ts"
import { IPostPayMembershipStatusBodyApi } from "@interfaces/IPostPayMembershipStatusBodyApi.ts"

const apply = async (
	body: IPostMembershipApplyBodyApi
): Promise<IPostMembershipApplyResponseApi> => {
	return api
		.post<IApiResponse<IPostMembershipApplyResponseApi>>(
			Endpoints.applyMembership,
			body
		)
		.then((response) => {
			const { data } = response.data
			return data
		})
		.catch((error) => {
			throw AppFunctions.getErrorApiDetail(error)
		})
}

const pay = async (
	body: IPostPayMembershipBodyApi
): Promise<IPostPayMembershipResponseCli> => {
	return api
		.post<IApiResponse<IPostPayMembershipResponseApi>>(
			Endpoints.payMembership,
			body
		)
		.then(({ data }) => PostPayMembershipAdapter(data))
		.catch((error) => {
			throw AppFunctions.getErrorApiDetail(error)
		})
}

const paySupportedCountries =
	async (): Promise<IGetPayMembershipSupportedCountriesResponseCli> => {
		return api
			.get<IApiResponse<IGetPayMembershipSupportedCountriesResponseApi>>(
				Endpoints.payMembershipSupportedCountries
			)
			.then(({ data }) => GetPayMembershipSupportedCountriesAdapter(data))
			.catch((error) => {
				throw AppFunctions.getErrorApiDetail(error)
			})
	}

const payStatus = async (
	body: IPostPayMembershipStatusBodyApi
): Promise<IPostPayMembershipStatusResponseCli> => {
	return api
		.post<IApiResponse<IPostPayMembershipStatusResponseApi>>(
			Endpoints.payMembershipStatus,
			body
		)
		.then(({ data }) => GetPayMembershipStatusAdapter(data))
		.catch((error) => {
			throw AppFunctions.getErrorApiDetail(error)
		})
}

export default { apply, pay, paySupportedCountries, payStatus }
