import * as React from "react"
import styles from "./AppNumber.module.scss"

import { AppConfig } from "@constants/AppConfig.ts"
import { IAppHtmlTags } from "@interfaces/generic/IAppHtmlTags.ts"

export interface IAppTextProps {
	className?: string
	tag?: IAppHtmlTags
	children?: number
}

const AppNumber: React.FC<IAppTextProps> = (props) => {
	const Wrapper = props.tag || "p"

	return (
		<Wrapper className={`${styles.app_number} ${props.className || ""}`}>
			{props.children || AppConfig.APP_NOT_VALUE}
		</Wrapper>
	)
}

export default React.memo(AppNumber)
