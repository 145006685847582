import * as React from "react"
import styles from "./DvBookingCategoryIcons.module.scss"

import { ReactComponent as MenuIconsRestaurants } from "@assets/svg/menu-icons-restaurants-40x40.svg"
import { ReactComponent as MenuIconsEvents } from "@assets/svg/menu-icons-events-40x40.svg"
import { ReactComponent as MenuIconsSpaces } from "@assets/svg/menu-icons-spaces-40x40.svg"

export interface IDvBookingCategoryIconsProps {
	className?: string
	bookingCategory?: "RESTAURANTS" | "SPACES" | "EVENTS"
}

const DvBookingCategoryIcons: React.FC<IDvBookingCategoryIconsProps> = (
	props
) => {
	const categoryIcon = React.useMemo(() => {
		switch (props.bookingCategory) {
			case "RESTAURANTS":
				return <MenuIconsRestaurants />
			case "SPACES":
				return <MenuIconsSpaces />
			case "EVENTS":
				return <MenuIconsEvents />
			default:
				return <MenuIconsSpaces />
		}
	}, [props.bookingCategory])

	return (
		<div
			className={`${styles.dv_booking_category_icons} ${props.className || ""}`}
		>
			{categoryIcon}
		</div>
	)
}

export default React.memo(DvBookingCategoryIcons)
