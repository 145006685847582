import { Route } from "react-router-dom"
import { AppRoutes } from "@constants/AppRoutes.ts"

import ProtectedRoute from "@components/others/PrivateRoute/PrivateRoute.tsx"
import UserProfile from "@pages/UserProfile/UserProfile.tsx"
import UserProfileInfo from "@pages/UserProfileInfo/UserProfileInfo.tsx"
import BookSpacePlace from "@pages/BookSpacePlace/BookSpacePlace.tsx"
import BookSpaceSchedule from "@pages/BookSpaceSchedule/BookSpaceSchedule.tsx"

const UserProfileRoutes = () => {
	return (
		<Route
			path={AppRoutes.userProfileRoot}
			element={
				<ProtectedRoute>
					<UserProfile />
				</ProtectedRoute>
			}
		>
			<Route path={AppRoutes.userProfile} element={<UserProfileInfo />} />

			<Route
				path={AppRoutes.userProfileBookSpace_place}
				element={<BookSpacePlace />}
			/>

			<Route
				path={AppRoutes.userProfileBookSpace_schedule}
				element={<BookSpaceSchedule />}
			/>
		</Route>
	)
}

export default UserProfileRoutes
