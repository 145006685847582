import * as React from "react"
import styles from "./UpcomingBookings.module.scss"

import { AppRoutes } from "@constants/AppRoutes.ts"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useBookingsData } from "@hooks/useBookingsData.ts"

import DsButton from "@components/general/DsButton/DsButton.tsx"
import DvBookingTable from "@components/dataDisplay/DvBookingTable/DvBookingTable.tsx"

interface IProps {
	className?: string
}

const UpcomingBookings: React.FC<IProps> = (props) => {
	const navigate = useNavigate()

	const { t } = useTranslation()
	const { fetchBookings, upcomingBookings } = useBookingsData()

	const goToMyBookings = React.useCallback(() => {
		navigate(AppRoutes.userMyBookings)
	}, [])

	React.useEffect(() => fetchBookings(), [])

	return (
		<>
			{!!upcomingBookings?.length && (
				<div
					className={`${styles.upcoming_bookings} ${props.className || ""}`}
				>
					<div className={styles.title_container}>
						<h1 className={styles.title}>
							{t("upcoming_bookings")}
						</h1>

						<DsButton
							className={styles.button}
							type="text"
							onClick={goToMyBookings}
						>
							{t("see_more")}
						</DsButton>
					</div>

					<DvBookingTable data={upcomingBookings} />
				</div>
			)}
		</>
	)
}

export default React.memo(UpcomingBookings)
