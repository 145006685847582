import * as React from "react"
import * as Antd from "antd"
import styles from "./DvInfoModal.module.scss"

import useResponsiveToolCtx from "@contexts/hooks/useResponsiveToolCtx.tsx"

interface IProps {
	className?: string
	open: boolean
	onClose?: () => void
	title: string
	children: React.ReactNode
}

const DvInfoModal: React.FC<IProps> = (props) => {
	const { isDesktop } = useResponsiveToolCtx()

	return (
		<Antd.Modal
			rootClassName={`${styles.dv_info_modal} ${props.className || ""}`}
			style={{ top: isDesktop ? 129 : 0 }}
			open={props.open}
			onCancel={props.onClose}
			footer={null}
			width={isDesktop ? 764 : undefined}
		>
			<div className={styles.modal_content_container}>
				<header className={styles.header}>{props.title}</header>
				<main className={styles.main}>{props.children}</main>
			</div>
		</Antd.Modal>
	)
}

export default React.memo(DvInfoModal)
