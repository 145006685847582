import * as React from "react"

import { IGetSpaceAvailableHoursResponseCli } from "@interfaces/IGetSpaceAvailableHoursResponseCli.ts"

import bookService from "@services/bookService/bookService.ts"

interface IOutput {
	spaceAvailableHours: IGetSpaceAvailableHoursResponseCli["availableTimeBlocks"]
	spaceAvailableHoursLoading: boolean
	fetchSpaceAvailableHours: (placeId: string, date: string) => void
}

export const useSpaceAvailableDaysHours = (): IOutput => {
	const [spaceAvailableHours, setSpaceAvailableHours] = React.useState<
		IOutput["spaceAvailableHours"]
	>([])

	const [spaceAvailableHoursLoading, setSpaceAvailableHoursLoading] =
		React.useState(false)

	const fetchSpaceAvailableHours = React.useCallback(
		(placeId: string, date: string) => {
			setSpaceAvailableHoursLoading(true)
			bookService
				.getSpaceAvailableHours({ place_id: placeId, date })
				.then((data) => {
					if (data?.availableTimeBlocks?.length) {
						setSpaceAvailableHours(data.availableTimeBlocks)
					}
				})
				.catch(({ message }) => {
					console.error(
						message ||
							"Something went wrong fetching the available hours"
					)
				})
				.finally(() => {
					setSpaceAvailableHoursLoading(false)
				})
		},
		[]
	)

	return {
		spaceAvailableHours,
		fetchSpaceAvailableHours,
		spaceAvailableHoursLoading,
	}
}
