import * as React from "react"
import styles from "./MyBookings.module.scss"

import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { AppNotification } from "@others/AppNotification.ts"
import { useBookingsData } from "@hooks/useBookingsData.ts"
import { ArrowLeftOutlined } from "@ant-design/icons"
import { IGetErrorApiDetail } from "@interfaces/IGetErrorApiDetail.ts"
import { AppLocalStorageKeys } from "@constants/AppLocalStorageKeys.ts"

import BookingCard, {
	IBookingCardProps,
} from "@components/dataDisplay/BookingCard/BookingCard.tsx"

import QrModalContent, {
	IQrModalContentProps,
} from "@pages/MyBookings/QrModalContent/QrModalContent.tsx"

import DsButton from "@components/general/DsButton/DsButton.tsx"
import DownloadApp from "@pages/UserProfile/DownloadApp/DownloadApp.tsx"
import bookingService from "@services/bookingService/bookingService.ts"
import NoBookingsMessage from "@pages/MyBookings/NoBookingsMessage/NoBookingsMessage.tsx"
import DvConfirmationModal from "@components/feedback/DvConfirmationModal/DvConfirmationModal.tsx"
import useResponsiveToolCtx from "@contexts/hooks/useResponsiveToolCtx.tsx"
import CancelConfirmationModalContent from "@pages/MyBookings/CancelConfirmationModalContent/CancelConfirmationModalContent.tsx"

interface IQrModal {
	open: boolean
	data: IQrModalContentProps["data"]
}

interface ICancelConfirmationModal {
	open: boolean
	bookingId: string
	loading: boolean
}

const cancelConfirmationModalInitialSt = {
	open: false,
	bookingId: "",
	loading: false,
}

const TIME_TO_HIGH_LIGHT = 3000

interface IProps {
	className?: string
}

const MyBookings: React.FC<IProps> = (props) => {
	const navigate = useNavigate()

	const { t } = useTranslation()
	const { isDesktop } = useResponsiveToolCtx()
	const { myUpcomingBookings, fetchBookings } = useBookingsData()

	const [qrModal, setQrModal] = React.useState<IQrModal>({
		open: false,
		data: {},
	})

	const [cancelConfirmationModal, setCancelConfirmationModal] =
		React.useState<ICancelConfirmationModal>(
			cancelConfirmationModalInitialSt
		)

	const handleQrCodePress = React.useCallback(
		(data: IBookingCardProps["data"]) => {
			setQrModal({ open: true, data })
		},
		[]
	)

	const handleCancelBooking = React.useCallback(() => {
		setCancelConfirmationModal((cancelConfirmationModal) => ({
			...cancelConfirmationModal,
			loading: true,
		}))

		bookingService
			.cancelBooking({ id: cancelConfirmationModal.bookingId })
			.then(() => {
				AppNotification.success({
					message: t("message_booking_canceled_successfully"),
				})
				fetchBookings()

				localStorage.setItem(
					AppLocalStorageKeys.CHANGED_BOOKING,
					cancelConfirmationModal.bookingId
				)
			})
			.catch(({ status, message }: IGetErrorApiDetail) => {
				if (status === 409) {
					AppNotification.error({
						message: t("message_booking_already_canceled"),
					})

					fetchBookings()
				} else {
					AppNotification.error({
						message: message || t("something_went_wrong"),
					})
				}
			})
			.finally(() => {
				setCancelConfirmationModal(cancelConfirmationModalInitialSt)
			})
	}, [cancelConfirmationModal.bookingId])

	React.useEffect(() => {
		fetchBookings()
	}, [])

	return (
		<>
			<div className={`${styles.my_bookings} ${props.className || ""}`}>
				<div className={styles.my_bookings_content}>
					<header className={styles.header}>
						<div className={styles.header_content}>
							<DsButton
								className={styles.back_button}
								type="text"
								icon={
									<ArrowLeftOutlined
										className={styles.icon}
									/>
								}
								onClick={() => navigate(-1)}
							/>
							<h1 className={styles.title}>{t("my_Bookings")}</h1>
						</div>

						<div className={styles.bottom_border} />
					</header>

					<main className={styles.main}>
						{myUpcomingBookings?.length ? (
							<div className={styles.booking_card_container}>
								{myUpcomingBookings.map((data) => (
									<BookingCard
										key={data.id}
										timeToHighlight={TIME_TO_HIGH_LIGHT}
										data={data}
										onQrCodeClick={handleQrCodePress}
										onCancelClick={(id) => {
											if (id !== undefined) {
												setCancelConfirmationModal({
													open: true,
													bookingId: id,
													loading: false,
												})
											} else {
												AppNotification.error({
													message: t(
														"something_went_wrong"
													),
												})
												console.error(
													`Error trying to cancel booking with id: ${id}`
												)
											}
										}}
										// onModifyClick={(id) => {
										// 	console.log("modify", id)
										// }}
									/>
								))}
							</div>
						) : (
							<NoBookingsMessage />
						)}

						{isDesktop && (
							<div className={styles.download_app_container}>
								<DownloadApp className={styles.download_app} />
							</div>
						)}
					</main>
				</div>
			</div>

			<DvConfirmationModal
				open={qrModal.open}
				width={264}
				hideFooter
				onCancel={() => setQrModal({ open: false, data: {} })}
			>
				<QrModalContent data={qrModal.data} />
			</DvConfirmationModal>

			<DvConfirmationModal
				open={cancelConfirmationModal.open}
				okDanger
				width={316}
				okTextKey="cancel_booking"
				cancelTextKey="back"
				onOk={handleCancelBooking}
				loading={cancelConfirmationModal.loading}
				onCancel={() =>
					setCancelConfirmationModal(cancelConfirmationModalInitialSt)
				}
			>
				<CancelConfirmationModalContent />
			</DvConfirmationModal>
		</>
	)
}

export default React.memo(MyBookings)
