import * as React from "react"
import styles from "./DsReservationsStatus.module.scss"

import { AppConfig } from "@constants/AppConfig.ts"
import { useTranslation } from "react-i18next"

import {
	CheckCircleFilled,
	ClockCircleFilled,
	ClockCircleOutlined,
	CloseCircleFilled,
} from "@ant-design/icons"

export interface IDsReservationsStatusProps {
	className?: string
	status?: "APPROVED" | "PENDING" | "CANCELED" | "EXPIRED"
}

const DsReservationsStatus: React.FC<IDsReservationsStatusProps> = (props) => {
	const { t } = useTranslation()

	const statusTextKey: string = React.useMemo(() => {
		switch (props.status) {
			case "APPROVED":
				return "approved"
			case "PENDING":
				return "pending"
			case "CANCELED":
				return "canceled"
			case "EXPIRED":
				return "expired"
			default:
				return AppConfig.APP_NOT_VALUE
		}
	}, [props.status])

	const statusColor: string = React.useMemo(() => {
		switch (props.status) {
			case "APPROVED":
				return "#77C783"
			case "PENDING":
				return "#dee3e5"
			case "CANCELED":
				return "#ffdad6"
			case "EXPIRED":
				return "#dee3e5"
			default:
				return "#dee3e5"
		}
	}, [props.status])

	const statusIcon = React.useMemo(() => {
		switch (props.status) {
			case "APPROVED":
				return (
					<CheckCircleFilled
						className={styles.status_icon}
						style={{ color: statusColor }}
					/>
				)
			case "PENDING":
				return (
					<ClockCircleFilled
						className={styles.status_icon}
						style={{ color: statusColor }}
					/>
				)
			case "CANCELED":
				return (
					<CloseCircleFilled
						className={styles.status_icon}
						style={{ color: statusColor }}
					/>
				)
			case "EXPIRED":
				return (
					<ClockCircleOutlined
						className={styles.status_icon}
						style={{ color: statusColor }}
					/>
				)
			default:
				return
		}
	}, [props.status])

	return (
		<div
			className={`${styles.ds_reservations_status} ${props.className || ""}`}
		>
			{statusIcon}

			<p className={styles.text} style={{ color: statusColor }}>
				{t(statusTextKey)}
			</p>
		</div>
	)
}

export default React.memo(DsReservationsStatus)
