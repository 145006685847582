import * as React from "react"
import styles from "./QrModalContent.module.scss"

import { QRCode } from "antd"

import AppText from "@components/general/AppText/AppText.tsx"
import DvCodeAndGuests from "@components/dataDisplay/DvCodeAndGuests/DvCodeAndGuests.tsx"
import DvStartEndDateTime from "@components/dataDisplay/DvStartEndDateTime/DvStartEndDateTime.tsx"

interface IData {
	title?: string
	guests?: number
	qrCode?: string
	startDate?: string
	endDate?: string
}

export interface IQrModalContentProps {
	className?: string
	data: IData
}

const QrModalContent: React.FC<IQrModalContentProps> = (props) => {
	return (
		<div className={`${styles.qr_modal_content} ${props.className || ""}`}>
			<QRCode
				className={styles.qr_code}
				value={props?.data?.qrCode || ""}
				size={200}
			/>

			<div className={styles.fix_data}>
				<AppText className={styles.header} ellipsis>
					{props?.data?.title}
				</AppText>

				<DvStartEndDateTime
					startDate={props?.data?.startDate}
					endDate={props?.data?.endDate}
				/>

				<DvCodeAndGuests
					className={styles.code_and_guests}
					code={props?.data?.qrCode}
					guests={props?.data?.guests}
				/>
			</div>
		</div>
	)
}

export default React.memo(QrModalContent)
