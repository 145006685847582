import * as Sentry from "@sentry/react"
import { BrowserOptions } from "@sentry/react"

export const SentryConfig: BrowserOptions = {
	dsn: "https://65d5b256fd4512802c0fa453b689c079@o4508059641511936.ingest.us.sentry.io/4508127977406464",
	integrations: [
		// https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/breadcrumbs/
		Sentry.breadcrumbsIntegration({
			console: true,
			dom: true,
			fetch: true,
			history: true,
			xhr: true,
		}),

		// https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/browserapierrors/
		Sentry.browserApiErrorsIntegration({
			setTimeout: true,
			setInterval: true,
			requestAnimationFrame: true,
			XMLHttpRequest: true,
			eventTarget: true,
		}),

		// https://docs.sentry.io/platforms/javascript/guides/react/session-replay/
		Sentry.replayIntegration({
			// Additional SDK configuration goes in here, for example:
			maskAllText: true,
			blockAllMedia: true,
		}),

		// The following is all you need to enable canvas recording with Replay
		Sentry.replayCanvasIntegration(), // https://docs.sentry.io/platforms/javascript/guides/react/session-replay/#canvas-recording

		Sentry.captureConsoleIntegration(), // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/captureconsole/
		Sentry.browserTracingIntegration(), // https://docs.sentry.io/platforms/javascript/guides/react/tracing/
		Sentry.contextLinesIntegration(), // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/contextlines/
		Sentry.debugIntegration(), // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/debug/
		Sentry.extraErrorDataIntegration(), // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/extraerrordata/
		Sentry.httpClientIntegration(), // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/httpclient/
		Sentry.moduleMetadataIntegration(), // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/modulemetadata/
		Sentry.reportingObserverIntegration(), // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/reportingobserver/
		Sentry.sessionTimingIntegration(), // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/sessiontiming/
	],
	// Tracing
	tracesSampleRate: 1.0, //  Capture 100% of the transactions - https://docs.sentry.io/platforms/javascript/guides/react/tracing/

	// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled - https://docs.sentry.io/platforms/javascript/guides/react/tracing/
	tracePropagationTargets: [
		"https://membership.elglobodistrict.com",
		"https://membership.qa.tolber.io/",
	],

	// This option is required for capturing headers and cookies. - https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/httpclient/
	sendDefaultPii: true,

	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
}
