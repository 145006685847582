import { IGetUserLoggedResponseCli } from "@interfaces/IGetUserLoggedResponseCli.ts"

type MembershipStatus = NonNullable<
	IGetUserLoggedResponseCli["membershipStatus"]
>

export const AppMembershipStatus: Record<MembershipStatus, MembershipStatus> = {
	NO_MEMBERSHIP: "NO_MEMBERSHIP",
	PENDING_TO_APPROVE: "PENDING_TO_APPROVE",
	APPROVED: "APPROVED",
	PAYMENT_IN_PROGRESS: "PAYMENT_IN_PROGRESS",
	PAID: "PAID",
}

export const FALLBACK_MEMBERSHIP_STATUS: IGetUserLoggedResponseCli["membershipStatus"] =
	"PAID"
