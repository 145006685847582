import * as React from "react"

import { IGetSpaceAllPlacesResponseCli } from "@interfaces/IGetSpaceAllPlacesResponseCli.ts"

import bookService from "@services/bookService/bookService.ts"
import useSpaceAllPlacesCtx from "@contexts/hooks/useSpaceAllPlacesCtx.tsx"

interface IOutput {
	spaceAllPlaces: IGetSpaceAllPlacesResponseCli
	fetchSpaceAllPlaces: () => void
}

export const useSpaceAllPlacesData = (): IOutput => {
	const { spaceAllPlaces, setSpaceAllPlaces } = useSpaceAllPlacesCtx()

	const fetchSpaceAllPlaces = React.useCallback(() => {
		bookService
			.getSpaceAllPlaces()
			.then((data) => {
				setSpaceAllPlaces(data)
			})
			.catch(({ message }) => {
				console.error(
					message || "Something went wrong fetching the user data"
				)
			})
	}, [spaceAllPlaces])

	return {
		spaceAllPlaces,
		fetchSpaceAllPlaces,
	}
}
