import * as React from "react"
import styles from "./DvBookingCardMenu.module.scss"

import { useTranslation } from "react-i18next"
import { MoreOutlined } from "@ant-design/icons"

import DvAppDropdown from "@components/navigation/DvAppDropdown/DvAppDropdown.tsx"

export interface IItem {
	titleKey: string
	onClick: () => void
	disabled?: boolean
	active?: boolean
}

export interface IDvBookingCardMenuProps {
	className?: string
	items: IItem[]
}

const DvBookingCardMenu: React.FC<IDvBookingCardMenuProps> = (props) => {
	const { t } = useTranslation()

	const items = React.useMemo(
		() =>
			props.items.map(({ titleKey, onClick, disabled, active }) => ({
				key: titleKey,
				label: t(titleKey),
				onClick: onClick,
				disabled,
				active,
			})),
		[props.items]
	)

	return (
		<DvAppDropdown
			className={`${styles.dv_booking_card_menu} ${props.className || ""}`}
			menu={{ items }}
		>
			<MoreOutlined className={styles.icon} />
		</DvAppDropdown>
	)
}

export default React.memo(DvBookingCardMenu)
