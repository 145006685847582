import * as React from "react"
import styles from "./DsStackedCard.module.scss"

import AppImage from "@components/others/AppImage/AppImage.tsx"
import useResponsiveToolCtx from "@contexts/hooks/useResponsiveToolCtx.tsx"

interface IData {
	id?: string
	coverUrl?: string
	title?: string
	subtitle?: string
}

export interface IDsStackedCardProps {
	className?: string
	data: IData
	onClick?: (data: IData) => void
	coverHeight?: number
}

const DsStackedCard: React.FC<IDsStackedCardProps> = ({ data, ...props }) => {
	const { isMobile } = useResponsiveToolCtx()

	const cardClickHandler = React.useCallback(() => {
		props.onClick?.(data)
	}, [])

	const imageHeight = isMobile ? 84 : 104

	return (
		<div
			className={`${styles.ds_stacked_card} ${props.className || ""} ${props?.onClick ? styles.clickable : ""}`}
			onClick={cardClickHandler}
		>
			<AppImage
				className={styles.image}
				src={data?.coverUrl}
				width={"100%"}
				height={props?.coverHeight || imageHeight}
			/>

			<div className={styles.content}>
				{data?.title && <h1 className={styles.title}>{data.title}</h1>}

				{data?.subtitle && (
					<h3 className={styles.subtitle}>{data.subtitle}</h3>
				)}
			</div>
		</div>
	)
}

export default React.memo(DsStackedCard)
