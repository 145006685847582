import api from "@others/api.ts"

import { Endpoints } from "@constants/Endpoints.tsx"
import { AppFunctions } from "@others/AppFunctions.ts"
import { IApiResponse } from "@interfaces/generic/IApiResponse.ts"
import { getMyBookingsAdapter } from "@adapters/getMyBookingsAdapter.ts"
import { IGetMyBookingsResponseCli } from "@interfaces/IGetMyBookingsResponseCli.ts"
import { IGetMyBookingsResponseApi } from "@interfaces/IGetMyBookingsResponseApi.ts"
import { IPostCancelBookingBodyApi } from "@interfaces/IPostCancelBookingBodyApi.ts"
import { IPostCancelBookingResponseApi } from "@interfaces/IPostCancelBookingResponseApi.ts"
import { IPostCancelBookingResponseCli } from "@interfaces/IPostCancelBookingResponseCli.ts"
import { PostCancelBookingAdapterAdapter } from "@adapters/PostCancelBookingAdapter.ts"

const getMyBookings = async (): Promise<IGetMyBookingsResponseCli> => {
	return api
		.get<IApiResponse<IGetMyBookingsResponseApi>>(Endpoints.myBookings)
		.then(({ data }) => getMyBookingsAdapter(data))
		.catch((error) => {
			throw AppFunctions.getErrorApiDetail(error)
		})
}

const cancelBooking = async ({
	id,
}: IPostCancelBookingBodyApi): Promise<IPostCancelBookingResponseCli> => {
	return api
		.post<IApiResponse<IPostCancelBookingResponseApi>>(
			Endpoints.cancelBooking,
			{
				id,
			}
		)
		.then(({ data }) => PostCancelBookingAdapterAdapter(data))
		.catch((error) => {
			throw AppFunctions.getErrorApiDetail(error)
		})
}

export default { getMyBookings, cancelBooking }
