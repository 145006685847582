import * as React from "react"
import * as Antd from "antd"
import styles from "./DvDateInfoTooltip.module.scss"

import { useTranslation } from "react-i18next"
import { useAppDateTime } from "@hooks/useAppDateTime.ts"
import { InfoCircleOutlined } from "@ant-design/icons"

import utc from "dayjs/plugin/utc"
import dayjs from "dayjs"
import timezone from "dayjs/plugin/timezone"

dayjs.extend(utc)
dayjs.extend(timezone)

interface IProps {
	className?: string
	startDate?: string
	endDate?: string
}

const DvDateInfoTooltip: React.FC<IProps> = (props) => {
	const { t } = useTranslation()
	const { formatDate, inUruguayZone } = useAppDateTime()

	const dateDetails =
		props.startDate && props.endDate
			? `${formatDate({
					date: props.startDate,
					format: "DD MMM YYYY",
					zone: "Local",
				})} | ${formatDate({ date: props.startDate, format: "HH:mm", zone: "Local" })} - ${formatDate({ date: props.endDate, format: "HH:mm", zone: "Local" })}`
			: `${formatDate({
					date: props.startDate,
					format: "DD MMM YYYY",
					zone: "Local",
				})} - ${formatDate({ date: props.startDate, format: "HH:mm", zone: "Local" })}`

	return (
		<>
			{!inUruguayZone && (
				<Antd.Tooltip
					className={`${styles.dv_date_info_tooltip} ${props.className || ""}`}
					overlayClassName={styles.overlay}
					placement="bottomRight"
					title={t("message_time_info_tooltip").replace(
						"(XXXX)",
						dateDetails
					)}
				>
					<InfoCircleOutlined className={styles.icon} />
				</Antd.Tooltip>
			)}
		</>
	)
}

export default React.memo(DvDateInfoTooltip)
