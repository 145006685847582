import * as React from "react"
import * as AntdIcons from "@ant-design/icons"
import styles from "./MenuItem.module.scss"

import AppText from "@components/general/AppText/AppText.tsx"

interface IProps {
	className?: string
	label: string
	icon?: React.ReactNode
	onClick?: () => void
}

const MenuItem: React.FC<IProps> = (props) => {
	return (
		<>
			<div className={`${styles.menu_item} ${props.className || ""}`}>
				<AppText className={styles.label} ellipsis>
					{props.label}
				</AppText>

				{props.icon || (
					<AntdIcons.RightOutlined
						className={styles.icon}
						onClick={props.onClick}
					/>
				)}
			</div>
			<div className={styles.line} />
		</>
	)
}

export default React.memo(MenuItem)
