export const Endpoints = {
	// Auth
	login: "/auth/login/",
	refreshToken: "/auth/refresh-token/",
	checkEmail: "/auth/check-email/",

	// User
	signUp: "/auth/signup/",
	user: "/user/edit/",
	userLogged: "/user/logged/",
	userQrCode: "/user/qr-code/",

	// Apply Membership
	membershipPlansData: "/membership/plans-data/",
	applyMembership: "/membership/apply/",

	// Pay membership
	payMembership: "/membership/payment/",
	payMembershipSupportedCountries: "/membership/payment/supported-countries/",
	payMembershipStatus: "/membership/payment/status/",

	// Book a space
	bookSpaceAllPlaces: "/book/space/all-places/",
	bookSpaceAvailableDays: "/book/space/available-days/",
	bookSpaceAvailableHours: "/book/space/available-hours/",
	bookSpace: "/book/space/",

	// Bookings
	myBookings: "/booking/my-bookings/",
	cancelBooking: "/booking/cancel/",
}
